import styled, { css } from 'styled-components';

type ICouponValueProps = {
  isEditing: boolean;
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  gap: 6px;
  padding-bottom: 20px;
`;

export const Restaurant = styled.div`
  display: flex;
  align-items: center;

  padding: 4px 8px;
  gap: 8px;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const RestaurantImg = styled.img`
  width: 28px;
  height: 28px;
  object-fit: cover;
  border-radius: 8px;
`;

export const AppliedEmail = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;

  border-radius: 8px;
  background-color: var(--container);
  border: 1px solid #13b49760;
`;

export const EmailContainer = styled.span`
  .email {
    display: flex;
    align-items: center;

    font-size: 12px;
  }
  .date {
    font-size: 10px;
    color: var(--greyTextContainer);
  }
`;

export const CouponContainer = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .orderNumber {
    font-size: 14px;
    font-weight: 600;
  }

  .delete {
    cursor: pointer;
    padding: 4px 10px;
    color: var(--greyTextContainer);

    background-color: transparent;
    border: none;

    &:hover {
      color: var(--danger);
    }
  }
`;

export const CouponValueContainer = styled.div``;

export const CouponValueInput = styled.input<ICouponValueProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  text-align: center;

  width: 60px;
  height: 40px;

  border-radius: 0px 8px 8px 0;

  outline: none;
  border: none;

  background-color: var(--primary20);
  color: var(--primary);

  font-size: 14px;
  font-weight: 600;

  margin: 0;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type='number'] {
    -moz-appearance: textfield;
  }

  ${props =>
    props.isEditing &&
    css`
      background-color: #151515;
      color: var(--alert);
    `}
`;
