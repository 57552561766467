import styled from 'styled-components';

export const Container = styled.div`
  gap: 12px;
  padding: 0px 10px 20px;
`;

export const Panel = styled.div`
  h1 {
    font-size: 18px;
    margin: 16px 0;
  }

  > span {
    font-size: 12px;
    display: block;
    margin-bottom: 10px;
  }

  > div {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
`;
