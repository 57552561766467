import React, { HTMLAttributes, useCallback, useMemo, useState } from 'react';

import { format, getISODay } from 'date-fns';
import { FiClipboard, FiEdit, FiPhoneOff } from 'react-icons/fi';
import * as S from './styles';
import { ToCopy } from '../../../../components/ToCopy';
import { ceos } from '../../../../db/ceos';
import { useAplication } from '../../../../hooks/app';
import { IRestaurant } from '../../../../DTOS/IRestaurant';

import whatsImg from '../../../../assets/whats.png';
import api from '../../../../services/api';

type BannerProps = HTMLAttributes<HTMLDivElement> & {
  restaurant: IRestaurant;
};

const Banner: React.FC<BannerProps> = ({ restaurant, ...rest }) => {
  const { applicatorLogged } = useAplication();
  const [todayApplicationsRelatory, setTodayApplicationsRelatory] =
    useState('');

  const applicationsThisRestaurants = useMemo(() => {
    return applicatorLogged.todayApplications.filter(
      a => a.restaurantId === restaurant.id,
    );
  }, [applicatorLogged.todayApplications, restaurant.id]);

  const progress = useMemo(() => {
    return (applicationsThisRestaurants.length * 100) / restaurant.ordersByDay;
  }, [applicationsThisRestaurants, restaurant.ordersByDay]);

  const ceoImg = ceos.find(c => c.id === restaurant.ceoId)?.img;

  useMemo(() => {
    let txt = '';

    applicationsThisRestaurants.forEach(a => {
      txt += `Pedido: #${a.orderNumber} - cupom: R$ ${a.coupon}\n`;
    });

    setTodayApplicationsRelatory(txt);
  }, [applicationsThisRestaurants]);

  const handleUpdateRestaurant = useCallback(
    async (options: any) => {
      // eslint-disable-next-line no-alert
      const confirm = window.confirm(
        'Tem certeza que deseja atualizar o restaurante?',
      );

      if (!confirm) return;

      try {
        await api.put(`restaurant/${restaurant.id}`, { ...options });

        window.location.reload();
      } catch (err) {
        // eslint-disable-next-line no-alert
        alert('Não foi possivel atualizar a loja');
      }
    },
    [restaurant.id],
  );

  const handleInsertInformation = useCallback(() => {
    const response = prompt('Insira a nova observação');

    handleUpdateRestaurant({
      description: response,
    });
  }, [handleUpdateRestaurant]);

  const handleInsertNewNumber = useCallback(() => {
    const response = prompt('Insira o numero no formato: 5531989551995');

    if (!Number(response)) return;

    handleUpdateRestaurant({
      phone: Number(response),
    });
  }, [handleUpdateRestaurant]);

  const handleInsertNewOrdersByDay = useCallback(() => {
    const response = prompt('Insira a nova quantidade de cupons');

    if (!Number(response)) return;

    handleUpdateRestaurant({
      ordersByDay: Number(response),
    });
  }, [handleUpdateRestaurant]);

  const handleInsertNewOrdersByDayWeek = useCallback(
    (day: string) => {
      const response = prompt('Insira a nova quantidade de cupons');

      if (Number(response) !== 0 && !response) return;

      handleUpdateRestaurant({
        ordersByDayWeek: {
          seg:
            day === 'seg' ? Number(response) : restaurant.ordersByDayWeek.seg,
          ter:
            day === 'ter' ? Number(response) : restaurant.ordersByDayWeek.ter,
          qua:
            day === 'qua' ? Number(response) : restaurant.ordersByDayWeek.qua,
          qui:
            day === 'qui' ? Number(response) : restaurant.ordersByDayWeek.qui,
          sex:
            day === 'sex' ? Number(response) : restaurant.ordersByDayWeek.sex,
          sab:
            day === 'sab' ? Number(response) : restaurant.ordersByDayWeek.sab,
          dom:
            day === 'dom' ? Number(response) : restaurant.ordersByDayWeek.dom,
        },
      });
    },
    [
      handleUpdateRestaurant,
      restaurant.ordersByDayWeek.dom,
      restaurant.ordersByDayWeek.qua,
      restaurant.ordersByDayWeek.qui,
      restaurant.ordersByDayWeek.sab,
      restaurant.ordersByDayWeek.seg,
      restaurant.ordersByDayWeek.sex,
      restaurant.ordersByDayWeek.ter,
    ],
  );

  useMemo(async () => {
    const data = new Date();
    const diaDaSemana = getISODay(data);

    const totalForToday = Object.entries(restaurant.ordersByDayWeek)[
      diaDaSemana - 1
    ][1];

    if (restaurant.ordersByDay !== totalForToday) {
      await api.put(`restaurant/${restaurant.id}`, {
        ordersByDay: totalForToday,
      });

      window.location.reload();
    }
  }, [restaurant.id, restaurant.ordersByDay, restaurant.ordersByDayWeek]);

  const today = useMemo(() => {
    const data = new Date();
    const diaDaSemana = getISODay(data);
    return diaDaSemana;
  }, []);

  return (
    <S.Container {...rest}>
      {ceoImg && <S.CEO src={ceoImg} />}

      <S.Cover src={restaurant.img} />
      <S.Logo src={restaurant.img} />

      <S.InfoWrapper>
        <ToCopy text={restaurant.name}>
          <S.Name>{restaurant.name}</S.Name>
        </ToCopy>

        {restaurant.phone ? (
          <S.WhatsApp
            target="_blank"
            href={`https://api.whatsapp.com/send?phone=${restaurant.phone}`}
          >
            <img alt="img" src={whatsImg} />
          </S.WhatsApp>
        ) : (
          <FiPhoneOff onClick={handleInsertNewNumber} style={{ zIndex: 1 }} />
        )}
      </S.InfoWrapper>

      {restaurant.description ? (
        <S.Description onClick={handleInsertInformation}>
          {restaurant.description}
        </S.Description>
      ) : (
        <S.Description onClick={handleInsertInformation}>
          Inserir observação na loja
          <FiEdit />
        </S.Description>
      )}

      <S.Informations>
        {Object.entries(restaurant.ordersByDayWeek).map(
          ([day, value], index) => (
            <S.Information
              onClick={() => handleInsertNewOrdersByDayWeek(day)}
              key={day}
              active={today === index + 1}
            >
              <S.Title>{day}</S.Title>
              <S.Value>{value}</S.Value>
            </S.Information>
          ),
        )}
        {/* {restaurant.needTalk ? (
          <S.Information
            onClick={() =>
              handleUpdateRestaurant({
                needTalk: false,
              })
            }
          >
            Tem que avisar
          </S.Information>
        ) : (
          <S.Information
            onClick={() =>
              handleUpdateRestaurant({
                needTalk: true,
              })
            }
          >
            Só enviar
          </S.Information>
        )}

        {restaurant.needDisableCampaign ? (
          <S.Information
            onClick={() =>
              handleUpdateRestaurant({
                needDisableCampaign: false,
              })
            }
          >
            Precisa desativar campanha
          </S.Information>
        ) : (
          <S.Information
            onClick={() =>
              handleUpdateRestaurant({
                needDisableCampaign: true,
              })
            }
          >
            Não precisa desativar campanha
          </S.Information>
        )}

        {restaurant.promotion ? (
          <S.Information
            onClick={() =>
              handleUpdateRestaurant({
                promotion: false,
              })
            }
          >
            Tem promoção
          </S.Information>
        ) : (
          <S.Information
            onClick={() =>
              handleUpdateRestaurant({
                promotion: true,
              })
            }
          >
            Sem promoção
          </S.Information>
        )}

        {restaurant.scheduling ? (
          <S.Information
            onClick={() =>
              handleUpdateRestaurant({
                scheduling: false,
              })
            }
          >
            Agendamento
          </S.Information>
        ) : (
          <S.Information
            onClick={() =>
              handleUpdateRestaurant({
                scheduling: true,
              })
            }
          >
            Sem agendamento
          </S.Information>
        )}
      </S.Informations>

      <S.Informations>
        <S.Information
          status={restaurant.status}
          active={restaurant.status === 'Disponível'}
          onClick={() =>
            handleUpdateRestaurant({
              status: 'Disponível',
            })
          }
        >
          Disponível
        </S.Information>

        <S.Information
          status={restaurant.status}
          active={restaurant.status === 'Devendo'}
          onClick={() =>
            handleUpdateRestaurant({
              status: 'Devendo',
            })
          }
        >
          Devendo
        </S.Information>

        <S.Information
          status={restaurant.status}
          active={restaurant.status === 'Problema'}
          onClick={() =>
            handleUpdateRestaurant({
              status: 'Problema',
            })
          }
        >
          Problema
        </S.Information>

        <S.Information
          status={restaurant.status}
          active={restaurant.status === 'Pausada pelo cliente'}
          onClick={() =>
            handleUpdateRestaurant({
              status: 'Pausada pelo cliente',
            })
          }
        >
          Pausada pelo cliente
        </S.Information> */}
      </S.Informations>

      <ToCopy text={restaurant.link}>
        <S.Relatory>Link da loja</S.Relatory>
      </ToCopy>

      <ToCopy
        text={`*${restaurant.name}*

Relatório ${format(new Date().setHours(3), 'dd/MM')}

*${applicationsThisRestaurants.length}* cupons.

${todayApplicationsRelatory}

Agora você pode acompanhar todas aplicações no
*link exclusivo do seu restaurante*:

https://incentivoss.netlify.app/store/${restaurant.id}
        `}
      >
        <S.Relatory>
          <FiClipboard />
          <span>Relatório</span>
        </S.Relatory>
      </ToCopy>

      <S.ProgressContainer>
        <S.ProgressBar>
          <S.Progress progress={progress}>
            {progress >= 10 && (
              <S.PercentText progress={progress}>
                {`${progress.toFixed()}%`}
              </S.PercentText>
            )}
          </S.Progress>
        </S.ProgressBar>
      </S.ProgressContainer>

      <S.ApplicationsInfo onClick={handleInsertNewOrdersByDay}>
        <S.Total progress={progress}>
          {`${applicationsThisRestaurants.length}/${restaurant.ordersByDay}`}
        </S.Total>
      </S.ApplicationsInfo>
    </S.Container>
  );
};

export default Banner;
