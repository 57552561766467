import React, { HTMLAttributes, useCallback, useMemo, useState } from 'react';

import { FiClock, FiDollarSign, FiInbox } from 'react-icons/fi';
import copy from 'copy-to-clipboard';
import * as S from './styles';
import InformationBlock from '../../../components/InformationBlock';
import api from '../../../services/api';
import { useAplication } from '../../../hooks/app';
import { useToast } from '../../../hooks/toast';
import Period from '../../../components/Period';
import { toMoney } from '../../../utils/toMoney';
import { IEvaluator } from '../../../DTOS/IEvaluator';
import { evaluators } from '../../../db/evaluators';

type ApplicatorsProps = HTMLAttributes<HTMLDivElement>;

export type IGetEvaluatorCalcs = {
  total: number;
  restaurantPaid: number;
  evaluationPaid: number;
};

export type IEvaluatorCalcs = IEvaluator & IGetEvaluatorCalcs;

const Evaluators: React.FC<ApplicatorsProps> = ({ ...rest }) => {
  const { startDate, endDate } = useAplication();
  const { addToast } = useToast();
  const [evaluatorsCalcs, setEvaluatorsCalcs] = useState<IEvaluatorCalcs[]>([]);
  const [payments, setPayments] = useState('');

  useMemo(async () => {
    if (!startDate || !endDate) return;

    const data = await Promise.all(
      evaluators.map(async evaluator => {
        const calcs = await api.get<IGetEvaluatorCalcs>(
          `rating/financial/${evaluator.id}/${startDate}/${endDate}`,
        );

        return {
          ...evaluator,
          ...calcs.data,
        };
      }),
    );

    let paymentString = `Faça os pix:
    `;
    data.forEach(a => {
      if (a.restaurantPaid - a.evaluationPaid > 0) {
        paymentString = `${paymentString}
Valor: ${toMoney(a.restaurantPaid - a.evaluationPaid)} para a chave pix: ${
          a.pix
        }`;
      }
    });

    setPayments(paymentString);
    setEvaluatorsCalcs(data);
  }, [endDate, startDate]);

  const handleEvaluatorReceived = useCallback(
    async (evaluatorId: string) => {
      const msg =
        'Tem certeza que deseja confirmar o pagamento de todas as avaliações do intervalo selecionado?';

      // eslint-disable-next-line no-alert
      const confirm = window.confirm(msg);

      if (confirm) {
        try {
          await api.put(`rating/paid/${evaluatorId}/${startDate}/${endDate}`);

          setEvaluatorsCalcs(old =>
            old.map(evaluator =>
              evaluator.id === evaluatorId
                ? {
                    ...evaluator,
                    evaluationPaid: evaluator.restaurantPaid,
                  }
                : { ...evaluator },
            ),
          );
        } catch (err) {
          addToast({
            title: 'Não foi possivel atualizar as aplicações',
          });
        }
      }
    },
    [addToast, endDate, startDate],
  );

  return (
    <S.Container {...rest}>
      <Period />

      {payments.length > 20 && (
        <S.Button onClick={() => copy(payments)}>
          Copiar texto de pagamento
        </S.Button>
      )}

      {evaluatorsCalcs.map(evaluator => (
        <S.ApplicatorContainer key={evaluator.id}>
          <InformationBlock
            uptitle={evaluator.name}
            img={evaluator.img}
            value={toMoney(evaluator.total)}
          />

          <InformationBlock
            // onClick={() => handleApplicatorReceived(evaluator, false)}
            Icon={FiInbox}
            uptitle="Recebido"
            value={toMoney(evaluator.evaluationPaid)}
          />

          <InformationBlock
            Icon={FiClock}
            status="warning"
            uptitle="A receber"
            value={toMoney(evaluator.total - evaluator.evaluationPaid)}
          />

          {evaluator.restaurantPaid - evaluator.evaluationPaid > 0 && (
            <InformationBlock
              onClick={() => handleEvaluatorReceived(evaluator.id)}
              Icon={FiDollarSign}
              uptitle="Liberado para pagamento"
              value={toMoney(
                evaluator.restaurantPaid - evaluator.evaluationPaid,
              )}
              status={evaluator.restaurantPaid > 0 ? 'success' : 'default'}
            />
          )}
        </S.ApplicatorContainer>
      ))}
    </S.Container>
  );
};

export default Evaluators;
