import styled, { css } from 'styled-components';

type ICouponProps = {
  copied: boolean;
};

type IReceiveCoupons = {
  available: boolean;
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 20px;
`;

export const CouponsQuantity = styled.div`
  text-align: center;

  margin: 20px 0;
`;

export const PanelOfCoupons = styled.div`
  border: 1px solid #ffffff30;
  background-color: #15151520;

  display: flex;
  align-items: center;
  justify-content: space-around;

  border-radius: 8px;

  padding: 20px 0;
`;

export const Input = styled.input`
  height: 40px;
  padding: 4px 12px;

  border-radius: 8px;

  background-color: transparent;
  color: #fff;

  width: 100%;
  outline: none;

  border: 1px solid #fff;

  text-align: center;
`;

export const MoneyInTheTable = styled.div`
  font-size: 12px;
  text-align: center;
  width: 100%;
  color: #ffffff80;
`;

export const PanelInformation = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 6px;

  .title {
    font-size: 10px;
    font-weight: 600;

    color: #ffffff60;
    text-transform: uppercase;
  }

  .value {
    font-size: 14px;
  }
`;

export const ButtonGetCoupon = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 10px 0;
`;

export const GetCouponButton = styled.button`
  background-color: transparent;
  border: none;
  outline: none;

  width: 100%;

  font-size: 14px;

  border-radius: 100px;
  padding: 10px 15px;

  color: #ffffff60;
  border: 1px solid #ffffff60;

  transition: 0.2s ease;
  color: var(--primary);
  border: 1px solid var(--primary);

  &:hover {
    background-color: var(--primary20);
    color: var(--primary);
  }
`;

export const ReceiveCouponButton = styled.button<IReceiveCoupons>`
  background-color: transparent;
  border: none;
  outline: none;

  font-size: 12px;

  border-radius: 100px;
  padding: 4px 0;

  color: #ffffff60;
  border: 1px solid #ffffff60;

  ${props =>
    props.available &&
    css`
      border: 1px solid #0a7360;
      color: #0a7360;
    `}
`;

export const CouponContainer = styled.div<ICouponProps>`
  display: flex;
  flex-direction: column;
  gap: 16px;

  background-color: var(--container);

  padding: 16px 20px;
  border-radius: 12px;

  > div:first-of-type {
    display: flex;
    align-items: center;
    justify-content: space-between;

    flex: 1;
  }
  > div:last-of-type {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  ${props =>
    props.copied &&
    css`
      border: 1px solid var(--primary);
      background-color: #13b49720;
    `}
`;

export const Email = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  span {
    word-break: break-all;
    display: block;

    width: 100%;

    cursor: pointer;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    font-size: 14px;
    transition: 0.2s ease;

    &:hover {
      color: var(--primary);
    }
  }
`;

export const CookieButtonLogin = styled.div`
  padding: 4px 12px;

  background-color: var(--primary20);
  border: 1px solid var(--primary);

  color: var(--primary);

  border-radius: 8px;

  font-size: 14px;

  cursor: pointer;

  transition: 0.2 ease;

  &:hover {
    background-color: var(--primary);
    color: #fff;
  }
`;

export const FixedIconButton = styled.button``;

export const Pack = styled.div`
  display: flex;
  align-items: center;
  padding: 2px 12px;

  background-color: var(--primary20);

  border-radius: 100px;

  font-size: 12px;
`;

export const Hour = styled.div`
  font-size: 12px;
  color: #ffffff60;
`;

export const OrderNumberInput = styled.input`
  outline: none;

  background-color: #2d3640;
  border: 1px solid #2d3640;
  color: #ffffff;

  text-align: center;

  border-radius: 6px;

  font-weight: 700;

  width: 70px;

  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const ValueContainer = styled.div`
  display: flex;

  button {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 30px;
    width: 30px;

    border: none;
    outline: none;
    background-color: var(--primary);
    border: 1px solid var(--primary);
    color: white;
    font-size: 12px;
    border-radius: 6px;

    transition: 0.2s ease;
    font-size: 18px;

    margin-left: 10px;
  }

  .applied {
    &:hover {
      background-color: #eaac0e;
      border: 1px solid #eaac0e;
      color: #151515;
    }
  }
`;

export const CouponValueInput = styled.input`
  outline: none;

  background-color: var(--primary20);
  border: 1px solid var(--primary20);
  color: var(--primary);

  text-align: center;

  border-radius: 6px;

  font-weight: 700;

  width: 50px;

  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  button {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 30px;
    width: 30px;

    border: none;
    outline: none;
    background-color: transparent;
    border: 1px solid var(--primary20);
    color: var(--primary);
    font-size: 12px;
    border-radius: 6px;

    transition: 0.2s ease;
    font-size: 18px;

    &.giveback {
      &:hover {
        background-color: #8d98a4;
        border: 1px solid #8d98a4;
        color: white;
      }
    }

    &.verify {
      &:hover {
        background-color: #292d31;
        border: 1px solid #292d31;
        color: white;
      }
    }

    &.unblock {
      &:hover {
        background-color: #292d31;
        border: 1px solid #292d31;
        color: white;
      }
    }

    &.without {
      &:hover {
        background-color: #a61c1c;
        border: 1px solid #a61c1c;
        color: white;
      }
    }
  }
`;

export const Img = styled.img`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  object-fit: cover;

  border-radius: 4px;
`;

export const LoginType = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 30px;
  padding: 0 10px;

  border: none;
  outline: none;

  border-radius: 6px;

  font-size: 14px;

  &.outlook {
    background-color: #00adb830;
    border: 1px solid #00adb8;
    color: #ffffff;
  }

  &.maxlife {
    background-color: #b8003730;
    border: 1px solid #b80037;
    color: #ffffff;
  }

  &.np {
    background-color: #8e21a930;
    border: 1px solid #8e21a9;
    color: #ffffff;
  }
`;
