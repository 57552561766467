import React, { useCallback, useMemo, useState } from 'react';
import { FiLayers, FiPlus, FiTrash, FiX } from 'react-icons/fi';
import { uuid } from 'uuidv4';
import * as S from './styles';

import { useToast } from '../../../hooks/toast';
import { IRestaurant } from '../../../DTOS/IRestaurant';
import { useAplication } from '../../../hooks/app';
import { applicators } from '../../../db/applicators';
import { IApplicator } from '../../../DTOS/IApplicator';
import api from '../../../services/api';
import { IOwner } from '../../../DTOS/IOwner';

type IRestaurantWithOwnerAndApplicator = IRestaurant & {
  owner: IOwner | undefined;
  applicator: IApplicator | undefined;
};

const Restaurants: React.FC = () => {
  const { restaurants, owners } = useAplication();
  const { addToast } = useToast();

  const [activeStatus, setActiveStatus] = useState(0);
  const [showRestaurants, setShowRestaurants] = useState(false);

  const [restaurantSelected, setRestaurantSelected] =
    useState<IRestaurantWithOwnerAndApplicator>();

  const [ordenedRestaurants, setOrdenedRestaurants] = useState<
    IRestaurantWithOwnerAndApplicator[]
  >([]);

  const [registerOpen, setRegisterOpen] = useState(false);

  const [name, setName] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [storeLink, setStoreLink] = useState('');
  const [keyword, setKeyword] = useState('');
  const [address, setAddress] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [monday, setMonday] = useState('');
  const [tuesday, setTuesday] = useState('');
  const [wednesday, setWednesday] = useState('');
  const [thursday, setThursday] = useState('');
  const [friday, setFriday] = useState('');
  const [saturday, setSaturday] = useState('');
  const [sunday, setSunday] = useState('');

  useMemo(() => {
    const restaurantsWithOwnersAndApplicator = restaurants.map(r => {
      const newRestaurants = {
        ...r,
        owner: owners.find(o => o.id === r.ownerId),
        applicator: applicators.find(a => a.id === r.applicatorIdCheckin),
      };
      return newRestaurants;
    });

    restaurantsWithOwnersAndApplicator.sort((a, b) =>
      a.ownerId.localeCompare(b.ownerId),
    );

    if (activeStatus === 0) {
      setOrdenedRestaurants(
        restaurantsWithOwnersAndApplicator.filter(r => r.status === 'Online'),
      );
    }
    if (activeStatus === 1) {
      setOrdenedRestaurants(
        restaurantsWithOwnersAndApplicator.filter(r => r.status === 'Caiu'),
      );
    }
    if (activeStatus === 2) {
      setOrdenedRestaurants(
        restaurantsWithOwnersAndApplicator.filter(r => r.status === 'Desistiu'),
      );
    }
    if (activeStatus === 3) {
      setOrdenedRestaurants(
        restaurantsWithOwnersAndApplicator.filter(r => r.status === 'Erro'),
      );
    }
    if (activeStatus === 4) {
      setOrdenedRestaurants(
        restaurantsWithOwnersAndApplicator.filter(r => r.status === 'Devendo'),
      );
    }
    if (activeStatus === 5) {
      setOrdenedRestaurants(
        restaurantsWithOwnersAndApplicator.filter(r => r.status === 'Atacar'),
      );
    }
    if (activeStatus === 6) {
      setOrdenedRestaurants(restaurantsWithOwnersAndApplicator);
    }
  }, [activeStatus, owners, restaurants]);

  const handleChangeStatus = useCallback(
    async (restaurantId: string, status: string) => {
      try {
        await api.put(`restaurant/${restaurantId}`, {
          status,
        });

        setOrdenedRestaurants(oldState =>
          oldState.map(r =>
            r.id === restaurantId
              ? {
                  ...r,
                  status,
                }
              : r,
          ),
        );
      } catch (err) {
        addToast({ title: 'Não foi possivel atualizar status do restaurante' });
      }
    },
    [addToast],
  );

  const handleDeleteNote = useCallback(
    async (restaurantId: string) => {
      const confirm = window.confirm('Tem certeza que deseja remover a nota?');

      if (!confirm) {
        return;
      }

      try {
        await api.put(`restaurant/${restaurantId}`, {
          notes: null,
        });

        setOrdenedRestaurants(oldState =>
          oldState.map(r =>
            r.id === restaurantId
              ? {
                  ...r,
                  notes: undefined,
                }
              : r,
          ),
        );
      } catch (err) {
        addToast({ title: 'Não foi possivel atualizar notas do restaurante' });
      }
    },
    [addToast],
  );

  const handleChangeNote = useCallback(
    async (restaurantId: string) => {
      const notes = window.prompt('Insira a note no restaurante');

      if (!notes) {
        return;
      }

      try {
        await api.put(`restaurant/${restaurantId}`, {
          notes,
        });

        setOrdenedRestaurants(oldState =>
          oldState.map(r =>
            r.id === restaurantId
              ? {
                  ...r,
                  notes,
                }
              : r,
          ),
        );
      } catch (err) {
        addToast({ title: 'Não foi possivel atualizar notas do restaurante' });
      }
    },
    [addToast],
  );

  const statusCount = useMemo(() => {
    return {
      online: restaurants.filter(r => r.status === 'Online').length,
      caiu: restaurants.filter(r => r.status === 'Caiu').length,
      desistiu: restaurants.filter(r => r.status === 'Desistiu').length,
      pagamento: restaurants.filter(r => r.status === 'Erro').length,
      devendo: restaurants.filter(r => r.status === 'Devendo').length,
      atacar: restaurants.filter(r => r.status === 'Atacar').length,
    };
  }, [restaurants]);

  const handleChangePanelStatus = useCallback((statusNumber: number) => {
    setActiveStatus(statusNumber);
    setShowRestaurants(true);
  }, []);

  const handleSubmit = useCallback(
    async event => {
      event.preventDefault();

      if (
        !name ||
        !imageUrl ||
        !storeLink ||
        !keyword ||
        !address ||
        !phoneNumber ||
        !monday ||
        !tuesday ||
        !wednesday ||
        !thursday ||
        !friday ||
        !saturday ||
        !sunday
      ) {
        alert('Por favor, preencha todos os campos.');
        return;
      }

      await api.post('restaurant', {
        id: uuid(),
        name,
        img: imageUrl,
        link: storeLink,
        ceoId: 'b024304a-633d-456c-855e-a527b4941dss',
        applicatorIdCheckin: '',
        ordersByDay: 0,
        active: true,
        description: '',
        ownerId: phoneNumber,
        scheduling: false,
        promotion: false,
        key: keyword,
        addresses: [],
        wannaEvaluation: true,
        address,
        phone: Number(phoneNumber),
        needTalk: true,
        needDisableCampaign: true,
        status: 'Online',
        ordersByDayWeek: {
          seg: Number(monday),
          ter: Number(tuesday),
          qua: Number(wednesday),
          qui: Number(thursday),
          sex: Number(friday),
          sab: Number(saturday),
          dom: Number(sunday),
        },
      });

      addToast({
        title: 'Restaurante cadastrado com sucesso',
        type: 'success',
      });

      setRegisterOpen(false);
    },
    [
      addToast,
      address,
      friday,
      imageUrl,
      keyword,
      monday,
      name,
      phoneNumber,
      saturday,
      storeLink,
      sunday,
      thursday,
      tuesday,
      wednesday,
    ],
  );

  const handleRemoveApplicator = useCallback(async (restaurantId: string) => {
    const confirm = window.confirm(
      'Tem certeza que deseja desvincular o aplicador do restaurante?',
    );

    if (!confirm) return;

    await api.put(`restaurant/${restaurantId}`, {
      applicatorIdCheckin: '',
    });

    setOrdenedRestaurants(oldState =>
      oldState.map(r =>
        r.id === restaurantId
          ? { ...r, applicatorId: null, applicator: {} as IApplicator }
          : r,
      ),
    );
  }, []);

  return (
    <S.Container>
      <S.ContentWrapper>
        <S.Header>
          <h1>Restaurantes</h1>

          <S.OptionsWrapper>
            <S.ButtonOption
              onClick={() => setRegisterOpen(true)}
              styleButton="alert"
            >
              <FiPlus size={20} />
            </S.ButtonOption>

            <S.ButtonOption
              onClick={() => setShowRestaurants(oldState => !oldState)}
              online={showRestaurants}
              styleButton="success"
            >
              <FiLayers size={20} />
            </S.ButtonOption>
          </S.OptionsWrapper>
        </S.Header>

        <S.InformationsWrapper>
          <S.InfoBlock
            active={activeStatus === 0}
            onClick={() => handleChangePanelStatus(0)}
            className="success"
          >
            <span className="title">Online</span>
            <span className="value">{statusCount.online}</span>
          </S.InfoBlock>

          <S.InfoBlock
            active={activeStatus === 1}
            onClick={() => handleChangePanelStatus(1)}
          >
            <span className="title">Caiu</span>
            <span className="value">{statusCount.caiu}</span>
          </S.InfoBlock>

          <S.InfoBlock
            active={activeStatus === 2}
            onClick={() => handleChangePanelStatus(2)}
          >
            <span className="title">Desistiu</span>
            <span className="value">{statusCount.desistiu}</span>
          </S.InfoBlock>

          <S.InfoBlock
            active={activeStatus === 3}
            onClick={() => handleChangePanelStatus(3)}
          >
            <span className="title">Erro</span>
            <span className="value">{statusCount.pagamento}</span>
          </S.InfoBlock>

          <S.InfoBlock
            active={activeStatus === 4}
            onClick={() => handleChangePanelStatus(4)}
          >
            <span className="title">Devendo</span>
            <span className="value">{statusCount.devendo}</span>
          </S.InfoBlock>

          <S.InfoBlock
            active={activeStatus === 5}
            onClick={() => handleChangePanelStatus(5)}
            className="danger"
          >
            <span className="title">Atacar</span>
            <span className="value">{statusCount.atacar}</span>
          </S.InfoBlock>

          <S.InfoBlock
            active={activeStatus === 6}
            onClick={() => handleChangePanelStatus(6)}
          >
            <span className="title">Todos</span>
            <span className="value">{restaurants.length}</span>
          </S.InfoBlock>
        </S.InformationsWrapper>

        {showRestaurants && (
          <S.RestaurantsContainer>
            {ordenedRestaurants.map(r => (
              <S.Restaurant key={r.id}>
                <div>
                  <S.RestaurantImg
                    className="restaurantImg"
                    src={r.img}
                    alt=""
                  />
                  <S.RestaurantInfo className="content">
                    <a rel="noopener noreferrer" href={r.link} target="_blank">
                      {r.name}
                    </a>

                    <div>
                      <S.Status
                        onClick={() =>
                          setRestaurantSelected(oldState =>
                            oldState && oldState.id === r.id ? undefined : r,
                          )
                        }
                        status={r.status}
                      >
                        {r.status}
                      </S.Status>

                      {r.notes ? (
                        <S.NotesDescription>
                          <span>{r.notes}</span>
                          <FiTrash
                            onClick={() => handleDeleteNote(r.id)}
                            style={{ cursor: 'pointer' }}
                            size={10}
                          />
                        </S.NotesDescription>
                      ) : (
                        <S.NoteIcon onClick={() => handleChangeNote(r.id)} />
                      )}

                      <span style={{ fontSize: 10 }}>{r.owner?.name}</span>
                    </div>
                  </S.RestaurantInfo>
                </div>

                {r.applicator?.img && (
                  <S.ApplicatorImg
                    onClick={() => handleRemoveApplicator(r.id)}
                    className="applicatorImg"
                    src={r.applicator.img}
                    alt=""
                  />
                )}
              </S.Restaurant>
            ))}
          </S.RestaurantsContainer>
        )}

        {restaurantSelected && (
          <S.RestaurantSelectedOptions>
            <S.RestaurantImg
              style={{ marginRight: 10 }}
              src={restaurantSelected.img}
            />
            <S.StatusOption
              onClick={() =>
                handleChangeStatus(restaurantSelected.id, 'Online')
              }
              status="Online"
            >
              Online
            </S.StatusOption>
            <S.StatusOption
              onClick={() => handleChangeStatus(restaurantSelected.id, 'Caiu')}
              className="danger"
              status="Caiu"
            >
              Caiu
            </S.StatusOption>
            <S.StatusOption
              onClick={() =>
                handleChangeStatus(restaurantSelected.id, 'Desistiu')
              }
              status="Desistiu"
            >
              Desistiu
            </S.StatusOption>

            <S.StatusOption
              onClick={() => handleChangeStatus(restaurantSelected.id, 'Erro')}
              status="Erro"
            >
              Erro
            </S.StatusOption>

            <S.StatusOption
              onClick={() =>
                handleChangeStatus(restaurantSelected.id, 'Devendo')
              }
              status="Devendo"
            >
              Devendo
            </S.StatusOption>

            <S.StatusOption
              onClick={() =>
                handleChangeStatus(restaurantSelected.id, 'Atacar')
              }
              status="Atacar"
            >
              Atacar
            </S.StatusOption>

            <S.CloseButton onClick={() => setRestaurantSelected(undefined)}>
              <FiX style={{ cursor: 'pointer' }} />
            </S.CloseButton>
          </S.RestaurantSelectedOptions>
        )}
      </S.ContentWrapper>

      {registerOpen && (
        <S.RestaurantRegisterAside onSubmit={handleSubmit}>
          <S.Title>
            Novo Restaurante
            <S.IconClose onClick={() => setRegisterOpen(false)} />
          </S.Title>

          <S.Input onChange={e => setName(e.target.value)} placeholder="Nome" />
          <S.Input
            onChange={e => setImageUrl(e.target.value)}
            placeholder="URL da imagem"
          />
          <S.Input
            onChange={e => setStoreLink(e.target.value)}
            placeholder="Link da loja"
          />
          <S.Input
            onChange={e => setKeyword(e.target.value)}
            placeholder="Palavra chave"
          />
          <S.Input
            onChange={e => setAddress(e.target.value)}
            placeholder="Endereço"
          />
          <S.Input
            onChange={e => setPhoneNumber(e.target.value)}
            placeholder="5531989551995"
          />

          <S.Title>Relatório</S.Title>

          <S.Input
            onChange={e => setMonday(e.target.value)}
            placeholder="Segunda"
          />
          <S.Input
            onChange={e => setTuesday(e.target.value)}
            placeholder="Terça"
          />
          <S.Input
            onChange={e => setWednesday(e.target.value)}
            placeholder="Quarta"
          />
          <S.Input
            onChange={e => setThursday(e.target.value)}
            placeholder="Quinta"
          />
          <S.Input
            onChange={e => setFriday(e.target.value)}
            placeholder="Sexta"
          />
          <S.Input
            onChange={e => setSaturday(e.target.value)}
            placeholder="Sábado"
          />
          <S.Input
            onChange={e => setSunday(e.target.value)}
            placeholder="Domingo"
          />

          <S.ButtonAdd type="submit">Enviar</S.ButtonAdd>
        </S.RestaurantRegisterAside>
      )}
    </S.Container>
  );
};

export default Restaurants;
