import styled, { css } from 'styled-components';

type PowerProps = {
  online?: boolean;
  styleButton: 'success' | 'alert' | 'danger';
};

export const Container = styled.div``;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  height: max-content;

  gap: 18px;

  padding: 14px 20px;
  padding-bottom: 22px;

  background-color: #141414;
  border-radius: 20px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  h1 {
    font-size: 18px;
  }
`;

export const OptionsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  gap: 10px;
`;

export const ButtonOption = styled.button<PowerProps>`
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  border: none;
  outline: none;
  background-color: transparent;

  color: #ffffff30;
  filter: drop-shadow(0px 0px 5px #ffffff30);

  transition: 0.2s ease;

  ${props =>
    props.online &&
    props.styleButton === 'success' &&
    css`
      color: #30d996;
      filter: drop-shadow(0px 0px 5px #30d996);
    `}

  ${props =>
    props.styleButton === 'alert' &&
    css`
      &:hover {
        color: #d9cb30;
        filter: drop-shadow(0px 0px 2px #d9cb30);
      }
    `}

    ${props =>
    props.styleButton === 'danger' &&
    css`
      &:hover {
        color: #d93030;
        filter: drop-shadow(0px 0px 2px #d93030);
      }
    `}
`;

export const GraphRating = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  padding-bottom: 120px;

  margin-top: 28px;

  height: 300px;

  gap: 8px;
`;

export const RatingColumn = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-end;

  width: 100%;
  position: relative;

  background: #ffffff10;

  border-radius: 8px;
  transition: 2s ease;

  position: relative;

  height: 100%;

  .rated {
    height: 50%;
    background: linear-gradient(145deg, var(--primary), #96e4b5);

    border-top-right-radius: 8px;
    border-top-left-radius: 8px;

    text-align: center;

    z-index: 1;
  }

  .pendings {
    height: 50%;
    background: linear-gradient(145deg, #d9cb30, #e4dd96);
  }

  .name {
    font-size: 10px;
    position: absolute;
    text-align: center;
    width: 100%;
    top: -20px;
    color: #ffffff80;

    border-radius: 4px;
  }

  .informations {
    display: flex;
    flex-direction: column;
    gap: 4px;
    position: absolute;
    width: 100%;
    bottom: -110px;

    span {
      width: 100%;
      text-align: center;
      font-size: 12px;
    }

    .total {
      border-radius: 4px;
    }

    .ratedNumber {
      background-color: var(--primary20);
      color: var(--primary);

      border-radius: 4px;
    }

    .pendingsNumber {
      background-color: #d9cb3020;
      color: #d9cb30;

      border-radius: 4px;
    }

    .miss {
      background-color: #ffffff10;
      color: #ffffff80;
      border-radius: 4px;
    }
  }

  min-height: 1px;
`;
