import React, { HTMLAttributes, useMemo, useState } from 'react';

import {
  FiCheckCircle,
  FiClock,
  FiDollarSign,
  FiStar,
  FiUsers,
} from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import * as S from './styles';
import InformationBlock from '../../components/InformationBlock';
import { useToast } from '../../hooks/toast';
import api from '../../services/api';

import logo from '../../assets/logo.png';

import igorImg from '../../assets/igor.png';
import estherImg from '../../assets/esther.png';
import { useAplication } from '../../hooks/app';
import { toMoney } from '../../utils/toMoney';
import Period from '../../components/Period';

type FinancialProps = HTMLAttributes<HTMLDivElement>;

export type IPeriodProps = 'today' | 'lastweek' | 'currentweek';

export type FinancialCalcsProps = {
  enterprise: number;
  minered: number;
  applicator: number;
  evaluator: number;
  received: number;
  profit: number;
  applicatorPaid: number;
  evaluationPaid: number;
  restaurantProfit: number;
};

const Financial: React.FC<FinancialProps> = ({ ...rest }) => {
  const { addToast } = useToast();
  const { push } = useHistory();
  const { startDate, endDate } = useAplication();

  const [hasEsther, setHasEsther] = useState(false);
  const [calcs, setCalcs] = useState<FinancialCalcsProps>();

  useMemo(() => {
    const imperioIgor = localStorage.getItem(
      `a76e8fff-d247-4e7b-8143-2f61a9bf4e7d`,
    );
    const imperioEsther = localStorage.getItem(
      `7557eb6f-8366-4203-8917-075fbf5f001f`,
    );

    if (imperioEsther) {
      setHasEsther(true);
      return;
    }

    if (!imperioIgor) {
      push('/signin');
    }
  }, [push]);

  useMemo(async () => {
    if (!startDate && !endDate) return;

    try {
      const response = await api.get<FinancialCalcsProps>(
        `applied/financial/${startDate}/${endDate}`,
      );

      if (!response.data) return;

      setCalcs(response.data);
    } catch (err) {
      addToast({
        title: 'Não foi possivel buscar as aplicações',
      });
    }
  }, [addToast, endDate, startDate]);

  const ceos = useMemo(() => {
    if (!calcs) return [0, 0];
    const igor = hasEsther
      ? (calcs.profit * 15) / 100
      : (calcs.profit * 85) / 100 + calcs.minered;
    const esther = (calcs.profit * 15) / 100;

    return [toMoney(igor), toMoney(esther)];
  }, [calcs, hasEsther]);

  return calcs ? (
    <S.Container {...rest}>
      <Period />

      {!hasEsther && (
        <S.GroupContainer>
          <S.Divider>
            <S.GroupContainer>
              <InformationBlock
                uptitle="Cupons"
                Icon={FiCheckCircle}
                value={String(calcs.minered)}
              />
            </S.GroupContainer>
          </S.Divider>
          <InformationBlock
            uptitle="Incentivo"
            img={logo}
            value={`${toMoney(calcs.enterprise)}`}
          />

          {calcs.received > 0 && (
            <InformationBlock
              uptitle="Recebido"
              status="success"
              Icon={FiDollarSign}
              value={toMoney(calcs.received)}
            />
          )}

          {calcs.enterprise - calcs.received > 0 && (
            <InformationBlock
              uptitle="Falta"
              status="warning"
              Icon={FiClock}
              value={toMoney(calcs.enterprise - calcs.received)}
            />
          )}
        </S.GroupContainer>
      )}

      <S.GroupContainer>
        <InformationBlock
          uptitle="Aplicadores"
          Icon={FiUsers}
          value={`${toMoney(calcs.applicator)}`}
        />
        {!hasEsther && (
          <>
            {calcs.applicatorPaid > 0 && (
              <InformationBlock
                uptitle="Pago"
                status="success"
                Icon={FiDollarSign}
                value={toMoney(calcs.applicatorPaid)}
              />
            )}

            {calcs.applicator - calcs.applicatorPaid > 0 && (
              <InformationBlock
                uptitle="Falta"
                status="warning"
                Icon={FiClock}
                value={toMoney(calcs.applicator - calcs.applicatorPaid)}
              />
            )}
          </>
        )}
      </S.GroupContainer>

      <S.GroupContainer>
        <InformationBlock
          uptitle="Avaliadores"
          Icon={FiStar}
          value={`${toMoney(calcs.evaluator)}`}
        />
        {!hasEsther && (
          <>
            {calcs.evaluationPaid > 0 && (
              <InformationBlock
                uptitle="Pago"
                status="success"
                Icon={FiDollarSign}
                value={toMoney(calcs.evaluationPaid)}
              />
            )}
            {calcs.evaluator - calcs.evaluationPaid > 0 && (
              <InformationBlock
                uptitle="Falta"
                status="warning"
                Icon={FiClock}
                value={toMoney(calcs.evaluator - calcs.evaluationPaid)}
              />
            )}
          </>
        )}
      </S.GroupContainer>

      <S.GroupContainer>
        <InformationBlock uptitle="Igor" img={igorImg} value={`${ceos[0]}`} />

        <InformationBlock
          uptitle="Esther"
          img={estherImg}
          value={`${ceos[1]}`}
        />
      </S.GroupContainer>
    </S.Container>
  ) : (
    <S.Container />
  );
};

export default Financial;
