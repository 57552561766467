import styled, { css } from 'styled-components';

type IButtonProps = {
  active: boolean;
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  gap: 6px;
`;

export const SearchLastWeekButton = styled.button<IButtonProps>`
  background-color: transparent;
  color: #ffffff40;
  padding: 0 12px;

  border-radius: 10px;

  width: 100%;
  height: 40px;

  border: 1px solid #ffffff20;

  transition: 0.2s ease;

  &:hover {
    background-color: var(--primary20);
    color: white;
  }

  ${props =>
    props.active &&
    css`
      border: 1px solid var(--primary);
      color: var(--primary);
    `}
`;

export const Divider = styled.div`
  display: flex;
  align-items: center;
  flex: 1;

  width: 100%;

  gap: 10px;
`;
