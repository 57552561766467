import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 6px;
  padding: 0 10px 20px;
  margin-top: 20px;
`;

export const Button = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;

  max-width: 300px;
  cursor: pointer;

  background-color: transparent;
  color: #26a742;
  border: none;
  outline: none;
  border-radius: 8px;

  text-decoration: none;

  border: 1px solid #26a742;

  transition: 0.2s ease;

  &:hover {
    background-color: #26a742;
    color: #fff;
    transform: scale(1.1);
  }
`;
