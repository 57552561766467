import React from 'react';
import * as S from './styles';
import Header from './Header';
import Menu from './Menu';

const MenuiFood: React.FC = () => {
  return (
    <S.Container>
      <S.Mock>
        <S.ContentContainer>
          <S.PhoneContent>
            <S.CoverContent>
              <S.Cover src="https://static.ifood-static.com.br/image/upload//capa/16fccabd-8bd9-4b5d-9384-b7d0d716dc38/202308030026_A5aE_i@2x.jpg" />
            </S.CoverContent>
            <S.Section>
              <Header />

              <Menu />
            </S.Section>
          </S.PhoneContent>
        </S.ContentContainer>
      </S.Mock>
    </S.Container>
  );
};

export default MenuiFood;
