import styled from 'styled-components';

export const Container = styled.div`
  padding: 0 10px 20px;
`;

export const StartSearchPack = styled.button`
  height: 45px;
  border-radius: 100px;

  width: 100%;

  background-color: var(--primary20);
  color: var(--primary);
  border: 1px solid var(--primary);

  outline: none;

  margin-bottom: 10px;
`;

export const PackHeader = styled.div`
  width: 100%;

  height: 50px;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #d2b000;

  border-radius: 10px;

  font-size: 18px;
  font-weight: 800;

  margin: 20px 0;
`;

export const Graphic = styled.div`
  z-index: 1;
`;

export const PackImg = styled.img`
  width: 50px;
  height: 50px;
`;

export const Title = styled.div``;

export const Packs = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  flex-wrap: wrap;
  gap: 10px;
`;

export const PacksNp = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
`;

export const Pack = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;

  width: 100%;

  padding: 10px;
  background-color: var(--container);
  border-radius: 10px;
  border: 2px solid transparent;
  transition: 0.2s ease;

  cursor: pointer;

  &:hover {
    border: 2px solid var(--primary);
    transform: scale(1.1);
  }
`;
export const ApplicatorImg = styled.img`
  width: 30px;
  height: 30px;

  object-fit: cover;
  border-radius: 50%;
`;
export const Number = styled.span``;
