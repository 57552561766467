import React from 'react';
import {
  Route as ReactDOMRoute,
  RouteProps as ReactDOMRouteProps,
} from 'react-router-dom';
import Header from '../components/Header';

interface RouteProps extends ReactDOMRouteProps {
  hasHeader?: boolean;
  component: React.ComponentType;
}

const Route: React.FC<RouteProps> = ({
  hasHeader = true,
  component: Component,
  ...rest
}) => {
  return (
    <ReactDOMRoute
      {...rest}
      render={() => {
        return hasHeader ? (
          <Header>
            <Component />
          </Header>
        ) : (
          <>
            <Component />
          </>
        );
      }}
    />
  );
};

export default Route;
