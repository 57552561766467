import styled, { css } from 'styled-components';

type IAppliedProps = {
  received?: boolean;
};

type IButtonProps = {
  active: boolean;
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  padding: 20px 20px 40px;
`;

export const Header = styled.header``;

export const StoreImg = styled.img``;

export const Informations = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const Applied = styled.div<IAppliedProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  padding: 8px;
  gap: 8px;

  background-color: var(--container);

  border-radius: 6px;

  ${props =>
    props.received &&
    css`
      > div {
        background-color: #0a736020;
      }
    `}
`;

export const Divider = styled.div`
  display: flex;
  align-items: center;
  flex: 1;

  gap: 10px;
`;

export const DateRange = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  text-align: center;
  gap: 10px;
  width: 100%;

  margin: 20px 0;

  span {
    font-size: 14px;
    text-align: center;

    color: #ffffff90;
  }
`;

export const ButtonLastWeek = styled.button<IButtonProps>`
  width: 100%;
  height: 50px;

  border-radius: 10px;

  background-color: transparent;

  border: 1px solid #ffffff30;

  color: #ffffff30;

  ${props =>
    props.active &&
    css`
      background-color: #eb5555;
      color: #fff;
    `}
`;

export const ButtonCurrentWeek = styled.button<IButtonProps>`
  width: 100%;
  height: 50px;

  border-radius: 10px;

  background-color: transparent;

  border: 1px solid #ffffff30;

  color: #ffffff30;

  ${props =>
    props.active &&
    css`
      color: #dcdcdc;
      border: 1px solid #dcdcdc;
    `}
`;

export const ReceivedButton = styled.button`
  height: 45px;

  background-color: transparent;
  color: var(--primary);
  padding: 0 12px;

  border-radius: 10px;

  border: 1px solid var(--primary);

  transition: 0.2s ease;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  flex: 1;

  gap: 4px;

  min-width: max-content;
  background-color: #151515;
  font-weight: 600;

  padding: 4px 8px;

  border-radius: 6px;

  img {
    width: 20px;
    height: 20px;
  }
`;

export const CheckImg = styled.img`
  width: 20px;
  height: 20px;

  margin: 0 10px;

  cursor: pointer;
`;

export const UncheckIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 20px;
  height: 20px;

  margin: 0 10px;

  cursor: pointer;
`;

export const UpTitle = styled.div`
  font-size: 8px;
  color: #ffffff80;
`;

export const Value = styled.div`
  font-size: 10px;
`;

export const Comment = styled.div`
  font-size: 10px;
  max-width: 50px; /* Ajusta a largura máxima para o tamanho do contêiner pai */
  overflow: hidden; /* Esconde o conteúdo que ultrapassa o limite da div */
  text-overflow: ellipsis; /* Adiciona "..." no final do texto que não cabe */
  white-space: nowrap; /* Evita que o texto quebre para a próxima linha */
`;
