import React, { HTMLAttributes } from 'react';

import * as S from './styles';

type SectionProps = HTMLAttributes<HTMLDivElement>;

const Section: React.FC<SectionProps> = ({ children, ...rest }) => {
  return <S.Container {...rest}>{children}</S.Container>;
};

export default Section;
