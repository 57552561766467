import React, { useCallback, useState } from 'react';
import copy from 'copy-to-clipboard';
import Tooltip from '../Tooltip';
import * as S from './styles';

interface IToCopyProps {
  text: string | number;
}

export const ToCopy: React.FC<IToCopyProps> = ({ text, children }) => {
  const [copyStatus, setCopyStatus] = useState(false);

  const handleCopy = useCallback(() => {
    copy(String(text));
    setCopyStatus(true);

    setTimeout(() => {
      setCopyStatus(false);
    }, 1500);
  }, [text]);

  return (
    <S.Container onClick={() => handleCopy()}>
      <Tooltip upside title={copyStatus ? 'Copiado!' : 'Copiar'}>
        {children}
      </Tooltip>
    </S.Container>
  );
};
