import styled, { css } from 'styled-components';

type IShowRestaurantsProps = {
  active: boolean;
};

type IStatus = {
  status?: string;
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  gap: 10px;
  padding: 0 10px 20px;
`;

export const Divider = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;

  width: 100%;

  gap: 10px;
`;

export const ApplicatorImg = styled.img`
  width: 50px;
  height: 50px;

  border-radius: 10px;

  margin-right: 10px;
`;

export const GroupContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  gap: 10px;
  border-radius: 10px;

  width: 100%;
  padding: 10px;

  background-color: #ffffff20;

  > button {
    background-color: transparent;
    display: flex;
    align-items: flex-start;
    justify-content: start;
    outline: none;
    border: none;

    color: #ffffff;
    text-align: start;
  }
`;

export const ButtonAdd = styled.button`
  display: flex;
  align-self: center;
  justify-content: center;

  background-color: transparent;
  border: none;
  color: #fff;
  cursor: pointer;
`;

export const OwnerHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  background-color: transparent;

  width: 100%;
  padding: 0 6px;

  > div:first-of-type {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  > div:last-of-type {
    display: flex;
    gap: 10px;
  }
`;

export const Name = styled.div`
  font-size: 14px;
`;

export const Description = styled.div`
  font-size: 12px;
  background-color: #ffffff20;
  padding: 4px 10px;
  border-radius: 6px;
`;

export const WhatsApp = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  img {
    cursor: pointer;

    transition: 0.2s ease;

    &:hover {
      transform: scale(1.1);
    }

    width: 20px;
    height: 20px;
  }
`;

export const ButtonCopyMessage = styled.button`
  outline: none;
  background-color: transparent;

  border-radius: 100px;

  padding: 2px 12px;

  transition: 0.2s ease;

  background-color: #c33f3f;
  color: #fff;
`;

export const ButtonShowRestaurants = styled.div<IShowRestaurantsProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;

  cursor: pointer;
  color: #ffffff80;

  border-radius: 100px;
  flex: 1;

  padding: 4px;
`;

export const RestaurantSelectedOptions = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  z-index: 10;

  padding: 20px;
  bottom: 0;

  background-color: #151515;

  width: 100%;
  left: 0;
`;

export const StatusOption = styled.div<IStatus>`
  padding: 2px 6px;

  color: #fff;

  border-radius: 10px;

  background-color: #ffffff30;
  color: #fff;

  cursor: pointer;

  ${props =>
    props.status === 'Online' &&
    css`
      color: var(--primary);
      border: 1px solid var(--primary);
      border-radius: 100px;
      background-color: transparent;
    `}

  ${props =>
    props.status === 'Caiu' &&
    css`
      color: var(--primary20);
      border: 1px solid var(--primary20);
      border-radius: 100px;
      background-color: transparent;
    `}

    ${props =>
    props.status === 'Desistiu' &&
    css`
      border-radius: 100px;
      border: 1px solid #fff;

      background-color: transparent;
    `}

    ${props =>
    props.status === 'Erro' &&
    css`
      border-radius: 100px;
      border: 1px solid #fff;

      background-color: transparent;
    `}

    ${props =>
    props.status === 'Devendo' &&
    css`
      border-radius: 100px;
      border: 1px solid #ffb800;
      color: #ffb800;

      background-color: transparent;
    `}

    ${props =>
    props.status === 'Atacar' &&
    css`
      border-radius: 100px;
      border: 1px solid var(--danger);
      color: var(--danger);

      background-color: transparent;
    `}
`;

export const RestaurantImg = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 6px;
`;

export const CloseButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 40px;
  height: 40px;
  cursor: pointer;
`;
