import styled from 'styled-components';

export const Container = styled.div`
  width: 278px;
  border-radius: 17px;

  top: -20px;

  position: absolute;

  background-color: #fff;
  border: 1px solid #ebebeb;

  display: flex;

  padding-bottom: 8px;
`;

export const PhotoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: absolute;
  margin-top: -25px;
`;

export const Photo = styled.img`
  width: 50px;
  height: 50px;

  border-radius: 50%;

  border: 1px solid #fff;
`;

export const HeaderInfo = styled.div`
  padding: 30px 12px 0;
`;

export const Name = styled.div`
  color: #141414;
  text-align: start;
  font-size: 14px;
  line-height: 120%;
  font-weight: 500;
  padding-right: 10px;
`;

export const SubName = styled.div`
  color: #666666;
  text-align: start;
  font-size: 10px;
  line-height: 200%;
`;

export const Divider = styled.div`
  height: 1px;
  width: 100%;
  margin: 7px 0;

  background-color: #ebebeb;
`;

export const LineDescription = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
  color: #000;
  font-size: 10px;

  span {
    font-size: 10px;
    margin-top: 1px;

    &.grey {
      color: #666666;
    }

    &.green {
      color: #007a3f;
    }
  }
`;

export const SuperStarIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 3px;
  border-radius: 50%;

  background-color: #a3a3a3;
  font-size: 5px;
`;
