import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;

  cursor: pointer;

  background-color: var(--container);

  padding: 6px;
  border-radius: 50px;

  border: 1px solid transparent;
  transition: 0.2s ease;

  &:hover {
    border: 1px solid var(--primary);
  }
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: var(--content);

  width: 35px;
  height: 35px;
  border-radius: 50%;
`;

export const TextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

export const Text = styled.span`
  font-size: 14px;
  margin-right: 8px;
  color: #ddd;
`;
