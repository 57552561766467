import styled, { css } from 'styled-components';

type StatusProps = {
  status: 'info' | 'success' | 'error' | 'default';
  color?: string;
};

type ProgressProps = {
  percent: number;
  color?: string;
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;

  gap: 10px;
`;

export const Bar = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;

  background-color: #ffffff10;

  border-radius: 10px;
  position: relative;

  span {
    position: absolute;
    color: #fff;
    right: 20px;
    font-size: 18px;
  }
`;

export const VerifiedToTotalLabel = styled.div`
  color: #ffffff80;
  text-align: end;
  font-size: 12px;
  margin-bottom: 10px;
  margin-right: 10px;
`;

export const Progress = styled.div<ProgressProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;

  height: 70px;

  width: 0%;

  background: linear-gradient(145deg, var(--primary), #96e4b5);

  transition: 2s ease;

  ${props =>
    props.percent > 0 &&
    css`
      width: ${props.percent}%;
    `}

  ${props =>
    props.color &&
    css`
      background-color: ${props.color};
    `}
`;

export const InformationsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: center;
  justify-content: center;
  gap: 10px;

  @media (max-width: 1150px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (max-width: 720px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const InfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 6px 10px;

  text-align: center;

  border-radius: 8px;

  background-color: #00000060;

  .title {
    font-size: 14px;
    color: #ffffff80;
  }

  .value {
    font-size: 18px;
  }
`;

// export const StatusBoxPanel = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   flex-wrap: wrap;
//   gap: 10px;
// `;

// export const StatusBox = styled.div<StatusProps>`
//   font-size: 12px;
//   display: flex;
//   text-align: center;

//   min-height: 54px;

//   background-color: var(--content);
//   padding: 4px 8px;

//   border-radius: 8px;

//   flex: 1;

//   flex-direction: column;

//   span.title {
//     font-size: 10px;
//     font-weight: 700;
//     margin: 4px 0 2px;

//     opacity: 0.8;
//   }

//   ${props =>
//     props.status === 'info' &&
//     css`
//       span {
//         color: #ffb800;
//       }
//     `}

//   ${props =>
//     props.status === 'success' &&
//     css`
//       span {
//         color: #48d009;
//       }
//     `}

//   ${props =>
//     props.status === 'error' &&
//     css`
//       span {
//         color: #fb0a0a;
//       }
//     `}

//     ${props =>
//     props.color &&
//     css`
//       background-color: ${props.color}20;

//       span {
//         color: ${props.color};
//       }
//     `}
// `;
