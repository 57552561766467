import styled from 'styled-components';

export const Container = styled.div``;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  height: max-content;

  gap: 18px;

  padding: 14px 20px;
  padding-bottom: 22px;

  background-color: #141414;
  border-radius: 20px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  h1 {
    font-size: 18px;
  }
`;

export const OptionsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  gap: 10px;
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
`;

export const ApplicatorContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  img {
    width: 30px;
    height: 30px;
    border-radius: 8px;
  }
`;

export const NumberInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;

  border: 1px solid #ffffff30;
  font-size: 12px;

  height: 30px;
  border-radius: 8px;
  padding: 0px 10px;

  background-color: var(--container);
`;
