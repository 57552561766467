import styled, { css } from 'styled-components';

interface IToltipProps {
  upside?: boolean;
}

export const Container = styled.div<IToltipProps>`
  position: relative;
  z-index: 1;

  > span.title {
    width: max-content;
    background: #1f1f1f;
    padding: 8px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    opacity: 0;
    transition: opacity 0.4s;
    visibility: hidden;

    position: absolute;
    bottom: calc(-100%);
    left: 50%;
    transform: translateX(-50%);

    color: #fff;

    ${props =>
      props.upside &&
      css`
        bottom: calc(110%);
      `}

    &::before {
      content: '';
      border-style: solid;
      border-color: #1f1f1f transparent;
      border-width: 6px 6px 0 6px;
      bottom: 100%;
      position: absolute;
      left: 50%;
      transform: translateX(-50%) rotate(180deg);

      ${props =>
        props.upside &&
        css`
          transform: translateX(-50%) rotate(0deg);
          bottom: -18%;
        `}
    }
  }

  &:hover span.title {
    opacity: 1;
    visibility: visible;
  }
`;
