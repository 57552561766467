import React from 'react';
import * as S from './styles';
import { useAplication } from '../../hooks/app';

const Period: React.FC = () => {
  const { periodType, setPeriodType } = useAplication();

  return (
    <S.Container>
      <S.Divider>
        <S.SearchLastWeekButton
          active={periodType === 'today'}
          onClick={() => setPeriodType('today')}
        >
          Hoje
        </S.SearchLastWeekButton>

        <S.SearchLastWeekButton
          active={periodType === 'pendings'}
          onClick={() => setPeriodType('pendings')}
        >
          Pendentes
        </S.SearchLastWeekButton>
      </S.Divider>

      <S.Divider>
        <S.SearchLastWeekButton
          active={periodType === 'currentweek'}
          onClick={() => setPeriodType('currentweek')}
        >
          Essa semana
        </S.SearchLastWeekButton>
        <S.SearchLastWeekButton
          active={periodType === 'lastweek'}
          onClick={() => setPeriodType('lastweek')}
        >
          Última semana
        </S.SearchLastWeekButton>
      </S.Divider>
    </S.Container>
  );
};

export default Period;
