import styled from 'styled-components';

type IShowRestaurantsProps = {
  active: boolean;
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  gap: 10px;
  padding: 20px;
`;

export const Divider = styled.div`
  display: flex;
  align-items: center;
  flex: 1;

  width: 100%;

  gap: 10px;
`;

export const DateRange = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  margin: 20px 0;

  span {
    font-size: 14px;

    color: #ffffff90;
  }
`;

export const GroupContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  gap: 10px;
  border-radius: 10px;

  width: 100%;
  padding: 10px;

  background-color: #ffffff20;
`;

export const OwnerName = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  padding: 0 6px;
`;

export const ButtonViewApplieds = styled.button`
  outline: none;
  background-color: transparent;

  border-radius: 100px;

  padding: 2px 12px;

  transition: 0.2s ease;

  font-size: 14px;

  border: 1px solid #13b497;
  color: #13b497;

  &:hover {
    background-color: #13b49750;
    color: #fff;
  }
`;

export const ButtonShowRestaurants = styled.div<IShowRestaurantsProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;

  cursor: pointer;
  color: #ffffff80;

  border-radius: 100px;
  flex: 1;

  padding: 4px;
`;
