import React, {
  HTMLAttributes,
  useCallback,
  useState,
  useRef,
  KeyboardEvent,
} from 'react';

import { addHours, format } from 'date-fns';
import { FiTrash } from 'react-icons/fi';
import * as S from './styles';
import api from '../../services/api';
import { useToast } from '../../hooks/toast';
import { IApplied } from '../../DTOS/IApplied';
import { useAplication } from '../../hooks/app';
import Tooltip from '../Tooltip';

type AppliedProps = HTMLAttributes<HTMLDivElement> & {
  applied: IApplied;
};

const Applied: React.FC<AppliedProps> = ({ applied }) => {
  const { addToast } = useToast();
  const { restaurants } = useAplication();
  const restaurant = restaurants.find(r => r.id === applied.restaurantId);

  const [couponValue, setCouponValue] = useState(String(applied.coupon));
  const [isEditing, setIsEditing] = useState(false);

  const couponInputRef = useRef<HTMLInputElement>(null);

  const handleDelete = useCallback(async () => {
    try {
      await api.delete(`applied/${applied.id}`);

      addToast({
        title: 'Aplicação deletada!',
      });
    } catch (err) {
      addToast({
        title: 'Nao foi possivel deletar aplicação',
      });
    }
  }, [addToast, applied.id]);

  const handleEditCouponValue = useCallback((newValue: string) => {
    setIsEditing(true);
    setCouponValue(newValue);
  }, []);

  const handleUpdateCouponValue = useCallback(
    async (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter' || event.keyCode === 13) {
        if (couponInputRef) couponInputRef.current?.blur();
        setIsEditing(false);

        try {
          await api.put<IApplied>(`applied/${applied.id}`, {
            coupon: Number(couponValue),
            evaluationPaid: applied.evaluationPaid,
          });

          addToast({
            type: 'success',
            title: 'Valor do cupom atualizado!',
          });
        } catch (err) {
          addToast({
            type: 'error',
            title: 'Não foi possivel atualizar valor do cupom',
          });
        }
      }
    },
    [addToast, applied.evaluationPaid, applied.id, couponValue],
  );

  return (
    <S.AppliedEmail key={applied.id}>
      <S.Restaurant>
        <Tooltip upside title={restaurant?.name || ''}>
          <S.RestaurantImg src={restaurant?.img} />
        </Tooltip>
        <S.EmailContainer>
          <div className="email">
            {applied.email && applied.email.split('@outlook.com')}
          </div>
          <div className="date">
            {format(addHours(new Date(applied.date), 3), 'dd/MM/yyyy HH:mm')}
          </div>
        </S.EmailContainer>
      </S.Restaurant>

      <S.CouponContainer>
        <span className="orderNumber">{`#${applied.orderNumber}`}</span>
        <button type="button" onClick={() => handleDelete()} className="delete">
          <FiTrash size={14} />
        </button>
        <S.CouponValueContainer>
          <S.CouponValueInput
            isEditing={isEditing}
            ref={couponInputRef}
            value={couponValue}
            onKeyUp={e => handleUpdateCouponValue(e)}
            type="number"
            onChange={e => handleEditCouponValue(e.target.value)}
          />
        </S.CouponValueContainer>
      </S.CouponContainer>
    </S.AppliedEmail>
  );
};

export default Applied;
