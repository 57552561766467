import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
`;

export const CouponsQuantity = styled.div`
  text-align: center;

  margin: 20px 0;
`;

export const EmailsContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Email = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  background-color: var(--content);

  padding: 8px 12px;

  border-radius: 8px;
`;

export const ButtonUsed = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: transparent;
  border: none;
  outline: none;

  color: #fff;

  &:hover {
    color: var(--primary);
  }
`;

export const ButtonCopy = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: transparent;
  border: none;
  outline: none;

  color: #fff;

  &:hover {
    color: var(--primary);
  }
`;
