import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  gap: 6px;

  padding-top: 160px;

  width: 100%;
  height: 200px;
`;
