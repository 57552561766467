import axios from 'axios';

const config = {
  dev: 'http://localhost:3333',
  prod: 'https://imperioback-719b59389142.herokuapp.com',
};

const api = axios.create({
  baseURL: config.prod,
});

export default api;
