import React, {
  HTMLAttributes,
  useCallback,
  // useEffect,
  useMemo,
  useState,
} from 'react';
import { addDays, format } from 'date-fns';
import { useHistory } from 'react-router-dom';
import { FiArchive, FiCheck, FiClock } from 'react-icons/fi';
import copy from 'copy-to-clipboard';
import * as S from './styles';

import api from '../../services/api';
import { IEmail } from '../../DTOS/IEmail';
// import SendEmailTo from '../../components/SendCouponTo';
import { IApplied } from '../../DTOS/IApplied';
import { IApplicator } from '../../DTOS/IApplicator';
import { toMoney } from '../../utils/toMoney';
import { useAplication } from '../../hooks/app';
import { useToast } from '../../hooks/toast';

type CouponProps = HTMLAttributes<HTMLDivElement>;

// type EmailProps = IEmail & {
//   selected?: boolean;
// };

type ICountProps = {
  total: number;
  toReceive: string;
};

type ApplicatorCouponAndApplied = {
  coupons: IEmail[];
  applicator: IApplicator & {
    count: number;
  };
  applieds: IApplied[];
  cookies?: ICountProps;
  offers: ICountProps;
};

const Coupon: React.FC<CouponProps> = ({ ...rest }) => {
  const { push } = useHistory();
  // const [emails, setEmails] = useState<EmailProps[]>([]);
  const [applicatorsWithCoupons, setApplicatorsWithCoupons] = useState<
    ApplicatorCouponAndApplied[]
  >([]);

  const { addToast } = useToast();
  // const [isSelectAll, setIsSelectAll] = useState(false);

  const { applicators, restaurants } = useAplication();
  const [couponsQuantity, setCouponsQuantity] = useState(0);

  useMemo(() => {
    const imperioIgor = localStorage.getItem(
      `a76e8fff-d247-4e7b-8143-2f61a9bf4e7d`,
    );
    const imperioEsther = localStorage.getItem(
      `7557eb6f-8366-4203-8917-075fbf5f001f`,
    );

    if (!imperioIgor && !imperioEsther) {
      push('/signin');
    }
  }, [push]);

  const getCouponsQuantity = useCallback(async () => {
    try {
      const response = await api.get(`email/coupons/quantity`);

      if (response.data) setCouponsQuantity(response.data.quantity);
    } catch (err) {
      addToast({
        title: 'Não foi possivel buscar quantidade de cupons na bag',
      });
    }
  }, [addToast]);

  // useEffect(() => {
  //   const hasUnselected = emails.find(e => !e.selected);

  //   setIsSelectAll(!hasUnselected);
  // }, [emails]);

  const getApplicatorsCoupons = useCallback(async () => {
    const startDate = new Date(new Date().setHours(3));
    const endDate = new Date(new Date().setHours(3));

    const response = await api.post<ApplicatorCouponAndApplied[]>(
      `email/coupons/applicators/${format(startDate, 'yyyy-MM-dd')}/${format(
        addDays(endDate, 1),
        'yyyy-MM-dd',
      )}`,
      { applicatorsId: applicators.map(a => a.id) },
    );

    const applicationsWithTotalApplications = response.data.map(a => ({
      ...a,
      couponsByDay: restaurants
        .filter(r => r.applicatorIdCheckin === a.applicator.id)
        .reduce((acc, item) => acc + item.ordersByDay, 0),
    }));

    const applicatorsWithCouponsSorted = applicationsWithTotalApplications.sort(
      (a, b) => {
        return a.couponsByDay - b.couponsByDay;
      },
    );

    const applicatorsWithCountCookies = await Promise.all(
      applicatorsWithCouponsSorted.map(async a => {
        const result = await api.get<ICountProps>(
          `cookie/count/${a.applicator.id}`,
        );

        const resultOffer = await api.get<ICountProps>(
          `offer/get/count/${a.applicator.id}`,
        );

        return {
          ...a,
          cookies: result.data,
          offers: resultOffer.data,
        };
      }),
    );

    setApplicatorsWithCoupons(applicatorsWithCountCookies);
  }, [applicators, restaurants]);

  // const handleSelectEmail = useCallback(
  //   (emailId: string) => {
  //     const newEmails = emails.map(email =>
  //       email.id === emailId ? { ...email, selected: !email.selected } : email,
  //     );

  //     setEmails(newEmails);
  //   },
  //   [emails],
  // );

  // const emailsSelected = useMemo(() => {
  //   return emails.filter(e => e.selected);
  // }, [emails]);

  // const getCoupons = useCallback(async () => {
  //   const response = await api.get<EmailProps[]>('email/coupons');
  //   setEmails(
  //     response.data.map(e => ({
  //       selected: false,
  //       ...e,
  //     })),
  //   );
  // }, []);

  useMemo(() => {
    // if (!refresh) return;
    getCouponsQuantity();
    // if (emailsSelected.length !== 0) return;
    // getCoupons();
    getApplicatorsCoupons();
  }, [getApplicatorsCoupons, getCouponsQuantity]);

  // const removeSelectedFromEmailList = useCallback(() => {
  //   const newEmails = emails.filter(email => !email.selected);
  //   setEmails(newEmails);
  // // }, [emails]);

  // const handleSelectAll = useCallback(() => {
  //   if (isSelectAll === false) {
  //     setIsSelectAll(true);
  //     setEmails(mails => mails.map(e => ({ ...e, selected: true })));
  //   }

  //   if (isSelectAll === true) {
  //     setIsSelectAll(false);
  //     setEmails(mails => mails.map(e => ({ ...e, selected: false })));
  //   }
  // }, [isSelectAll]);

  // const handleSelectMoreFive = useCallback(() => {
  //   const newEmails = emails.filter(e => !e.selected);
  //   setEmails(oldState =>
  //     oldState.map(email => {
  //       const emailFound = newEmails.find(
  //         (newEmail, index) => index < 5 && newEmail.id === email.id,
  //       );

  //       if (emailFound) {
  //         return { ...email, selected: true };
  //       }
  //       return email;
  //     }),
  //   );
  // }, [emails]);

  const totalAvailable = useMemo(() => {
    return applicatorsWithCoupons.reduce(
      (acc, item) => acc + item.coupons.length || 0,
      0,
    );
  }, [applicatorsWithCoupons]);

  const totalApplieds = useMemo(() => {
    return applicatorsWithCoupons.reduce(
      (acc, item) => acc + item.applieds.length || 0,
      0,
    );
  }, [applicatorsWithCoupons]);

  // useEffect(() => {
  //   getCoupons();
  //   // getApplicatorsCoupons();
  //   // setInterval(() => {
  //   //   setRefresh(true);
  //   //   setRefresh(false);
  //   // }, 10000);
  // }, [getApplicatorsCoupons, getCoupons]);

  const handleNavigateToApplicatorPage = useCallback(
    (applicator: string) => {
      localStorage.clear();
      localStorage.setItem(`71c66b70-3f7d-4236-ad00-76510ba0edb7`, applicator);
      push(`/`);
      window.location.reload();
    },
    [push],
  );

  const handleCopyAndRemoveOffersCount = useCallback(
    async (toReceive: string, pix: string, id: string) => {
      const confirm = window.confirm(
        'Tem certeza que deseja zerar e copiar o pix para pagamento?',
      );

      if (!confirm) return;
      copy(`Enviar ${toReceive} para ${pix}`);
      await api.put(`offer/remove/offer/${id}`);
    },
    [],
  );

  return (
    <S.Container {...rest}>
      <S.CouponsQuantity refresh={false}>{couponsQuantity}</S.CouponsQuantity>

      <S.Applicators>
        <S.Received>
          <div>
            <FiArchive size={14} />
            <span>{totalApplieds + totalAvailable}</span>
          </div>
          <div>
            <FiClock size={14} />
            <span>{totalAvailable}</span>
          </div>
          <div>
            <FiCheck size={14} />
            <span>{totalApplieds}</span>
          </div>
        </S.Received>
        <S.ApplicatorContent>
          {applicatorsWithCoupons.map(applicator => (
            <S.PanelOfCoupons key={applicator.applicator.id}>
              <img src={applicator.applicator.img} alt="" />

              {applicator.offers && (
                <S.CouponsTotal
                  onClick={() =>
                    handleCopyAndRemoveOffersCount(
                      applicator.offers.toReceive,
                      applicator.applicator.pix,
                      applicator.applicator.id,
                    )
                  }
                >
                  {applicator.offers?.toReceive}
                </S.CouponsTotal>
              )}

              <S.Received
                onClick={() =>
                  handleNavigateToApplicatorPage(applicator.applicator.id)
                }
              >
                <S.TotalApplications>
                  {restaurants
                    .filter(
                      r => r.applicatorIdCheckin === applicator.applicator.id,
                    )
                    .reduce((acc, item) => acc + item.ordersByDay, 0)
                    .toFixed(0)}
                </S.TotalApplications>
                {applicator.applieds.length > 0 && (
                  <S.Money>
                    {toMoney(
                      applicator.applieds?.reduce(
                        (acc, item) => acc + item.calcs.enterprise.aplicador,
                        0,
                      ) || 0,
                    )}
                  </S.Money>
                )}
                <div>
                  <FiArchive size={14} />
                  <span>
                    {applicator.coupons.length + applicator.applieds?.length}
                  </span>
                </div>
                <div>
                  <FiClock size={14} />
                  <span>{applicator.coupons.length}</span>
                </div>
                <div>
                  <FiCheck size={14} />
                  <span>{applicator.applieds.length || 0}</span>
                </div>
              </S.Received>
            </S.PanelOfCoupons>
          ))}
        </S.ApplicatorContent>
      </S.Applicators>

      {/* <S.SelectionButtons>
        <button type="button" onClick={handleSelectAll}>
          {isSelectAll ? 'Desmarcar tudo' : 'Marcar tudo'}
        </button>
        <button type="button" onClick={handleSelectMoreFive}>
          +5
        </button>
      </S.SelectionButtons> */}

      {/* {emails.map(e => (
        <S.EmailContainer
          onClick={() => handleSelectEmail(e.id)}
          selected={e.selected}
          key={e.id}
        >
          <S.Checkbox selected={e.selected}>
            {e.selected && <FiCheck color="#fff" size={8} />}
          </S.Checkbox>
          <S.Status>
            <S.MoneyValue>{`R$ ${e.coupon}`}</S.MoneyValue>
          </S.Status>

          <div>
            <S.Email>{e.email.split('@outlook.com')}</S.Email>

            {e.lastVerified && (
              <S.Hour>
                {format(new Date(e.lastVerified), 'dd/MM - HH:mm')}
              </S.Hour>
            )}
          </div>
          <S.Index>{e.loginType}</S.Index>
        </S.EmailContainer>
      ))} */}
      {/* <SendEmailTo
        removeSelectedFromEmailList={removeSelectedFromEmailList}
        emailsSelected={emailsSelected}
      /> */}
    </S.Container>
  );
};

export default Coupon;
