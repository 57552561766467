import React, { useMemo, useState } from 'react';
import * as S from './styles';

import api from '../../services/api';
import { useToast } from '../../hooks/toast';
import { IApplied } from '../../DTOS/IApplied';
import { useAplication } from '../../hooks/app';
import Applied from '../../components/Applied';
import Period from '../../components/Period';

const Applieds: React.FC = () => {
  const { addToast } = useToast();
  const { applicatorLogged, startDate, endDate } = useAplication();

  const [applieds, setApplieds] = useState<IApplied[]>([]);

  useMemo(async () => {
    if (!startDate && !endDate) return;
    try {
      const response = await api.get<IApplied[]>(
        `applied/pendings/${applicatorLogged.applicator?.id}/${startDate}/${endDate}`,
      );

      if (!response.data) return;

      setApplieds(response.data);
    } catch (err) {
      addToast({ title: 'Não foi possivel buscar aplicacoes' });
    }
  }, [addToast, applicatorLogged.applicator, endDate, startDate]);

  return (
    <S.Container>
      <Period />

      {applieds.length > 0 &&
        applieds.map(applied => <Applied key={applied.id} applied={applied} />)}
    </S.Container>
  );
};

export default Applieds;
