import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { format } from 'date-fns';
import * as S from './styles';
import Period from '../../../components/Period';
import { useAplication } from '../../../hooks/app';
import InformationBlock from '../../../components/InformationBlock';
import { IRestaurant } from '../../../DTOS/IRestaurant';
import api from '../../../services/api';
import { FinancialCalcsProps } from '..';
import { useToast } from '../../../hooks/toast';
import { toMoney } from '../../../utils/toMoney';
import { IOwner } from '../../../DTOS/IOwner';

type IRestaurantWithCalcs = IRestaurant & {
  calcs: FinancialCalcsProps;
};

type ParamsProps = {
  ownerId: string;
};

const Restaurants: React.FC = () => {
  const { ownerId } = useParams<ParamsProps>();
  const { push } = useHistory();
  const { addToast } = useToast();
  const { restaurants, startDate, endDate, owners } = useAplication();
  const [ownerRestaurants, setOwnerRestaurants] = useState<
    IRestaurantWithCalcs[]
  >([]);

  const [owner, setOwner] = useState<IOwner>({} as IOwner);

  useEffect(() => {
    const ownerFounded = owners.find(o => o.id === ownerId);

    if (!ownerFounded) return;

    setOwner(ownerFounded);
  }, [ownerId, owners]);

  useMemo(async () => {
    if (!startDate && !endDate) return;

    if (!ownerId) return;

    try {
      const restaurantsThisOwner = restaurants.filter(
        r => ownerId === r.ownerId,
      );

      const restaurantsWithCalcs = await Promise.all(
        restaurantsThisOwner.map(async r => {
          const calcsData = await api.get<FinancialCalcsProps>(
            `applied/financial/${r.id}/${startDate}/${endDate}`,
          );

          return {
            ...r,
            calcs: calcsData.data,
          };
        }),
      );

      setOwnerRestaurants(restaurantsWithCalcs);
    } catch (err) {
      addToast({
        title: 'Não foi possivel buscar as aplicações',
      });
    }
  }, [addToast, endDate, ownerId, restaurants, startDate]);

  const total = useMemo(() => {
    return ownerRestaurants.reduce(
      (acc, item) => acc + item.calcs.enterprise,
      0,
    );
  }, [ownerRestaurants]);

  const received = useMemo(() => {
    return ownerRestaurants.reduce((acc, item) => acc + item.calcs.received, 0);
  }, [ownerRestaurants]);

  const restaurantProfit = useMemo(() => {
    return ownerRestaurants.reduce(
      (acc, item) => acc + item.calcs.restaurantProfit,
      0,
    );
  }, [ownerRestaurants]);

  return (
    <S.Container>
      <Period />

      {startDate && (
        <S.DateRange>
          <span>
            {`${format(new Date(startDate), 'dd/MM')} ao dia ${format(
              new Date(endDate),
              'dd/MM',
            )}`}
          </span>
        </S.DateRange>
      )}

      <S.GroupContainer>
        <S.OwnerName>
          <div>{owner.name}</div>
        </S.OwnerName>
        <S.Divider>
          <InformationBlock
            uptitle="Lucro liquído do(s) restaurante(s)"
            value={toMoney(restaurantProfit)}
          />
          <InformationBlock
            uptitle="A pagar"
            status="warning"
            value={toMoney(total - received)}
          />
        </S.Divider>
        {ownerRestaurants.map(r => {
          return (
            <InformationBlock
              onClick={() => push(`/store/${r.id}`)}
              key={r.id}
              status={
                r.calcs.enterprise === r.calcs.received ? 'success' : 'warning'
              }
              img={r.img}
              uptitle={r.name}
              value={toMoney(r.calcs.enterprise - r.calcs.received)}
            />
          );
        })}
      </S.GroupContainer>
    </S.Container>
  );
};

export default Restaurants;
