import React from 'react';
import { FiChevronRight, FiStar } from 'react-icons/fi';
import * as S from './styles';

const Header: React.FC = () => {
  return (
    <S.Container>
      <S.PhotoContainer>
        <S.Photo src="https://static.ifood-static.com.br/image/upload/t_thumbnail/logosgde/16fccabd-8bd9-4b5d-9384-b7d0d716dc38/202308030027_5g5v_i.jpg" />
      </S.PhotoContainer>
      <S.HeaderInfo>
        <S.Name>Tchatcha Burguer - Hambúrguer & Almoço</S.Name>
        <S.SubName>1,7km • Min R$ 19,00</S.SubName>

        <S.Divider />

        <S.LineDescription>
          <FiStar color="#000000" fill="" />
          <span>4,7 </span>
          <span className="grey">(518 avaliações)</span>
          <span className="grey">•</span>

          <S.SuperStarIcon>
            <FiStar color="#fff" fill="#fff" />
          </S.SuperStarIcon>
          <span>Nível 1 </span>
          <span className="grey">de 5</span>
        </S.LineDescription>

        <S.Divider />

        <S.LineDescription style={{ fontWeight: 500 }}>
          <span>Entrega</span>
          <FiChevronRight style={{ margin: '0 12px' }} />
          <span>Hoje, 25-40 min • </span>
          <span className="green">Grátis</span>
        </S.LineDescription>
      </S.HeaderInfo>
    </S.Container>
  );
};

export default Header;
