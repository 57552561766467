/* eslint-disable no-underscore-dangle */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FiRotateCcw, FiTrash, FiZap } from 'react-icons/fi';
import * as S from './styles';
import api from '../../../services/api';
import { useToast } from '../../../hooks/toast';

type CouponsCountProps = {
  totalToSearch: number;
  totalVerified: {
    total: number;
    hasCoupons: number;
    withoutCoupons: number;
    percentComplete: number;
  };
  totalCouponsQuantity: {
    _id: number;
    count: number;
    percent: number;
  }[];
  availables: number;
  applieds: number;
};

const CouponsRobot: React.FC = () => {
  const { addToast } = useToast();

  const [couponsCount, setCouponsCount] = useState<CouponsCountProps>();
  const [minCouponValueToAvailable, setMinCouponValueToAvailable] =
    useState(100);
  const [robotCouponsStatus, setRobotCouponsStatus] = useState(false);

  const fetchCouponsCount = useCallback(async () => {
    try {
      const response = await api.get<CouponsCountProps>(
        'email/scanner/coupons/count',
      );
      const responseWithPercentByCoupon = {
        ...response.data,
        totalCouponsQuantity: response.data.totalCouponsQuantity.map(item => ({
          ...item,
          percent: (item.count * 100) / response.data.totalVerified.hasCoupons,
        })),
      };
      setCouponsCount(responseWithPercentByCoupon);
    } catch (error) {
      addToast({ title: 'Erro ao buscar contagem de cupons', type: 'error' });
    }
  }, [addToast]);

  const handleRefreshAccounts = useCallback(async () => {
    if (
      window.confirm(
        'Deseja reiniciar a procura? (essa opção mantém os cupons já encontrados).',
      )
    ) {
      await api.put('email/turnon/scanner');
    }
  }, []);

  const handleClearCoupons = useCallback(async () => {
    if (window.confirm('Tem certeza que deseja resetar os cupons?')) {
      await api.put('email/clear/coupons');

      if (
        window.confirm(
          'Você também gostaria de remover os cupons nos perfis dos aplicadores?',
        )
      ) {
        await api.put('email/clear/bag');
      }
    }
  }, []);

  const handleChangeMinCouponValueToAvailable = useCallback(
    async (min: number) => {
      try {
        await api.put('robot/coupons', { minCouponValueToAvailable: min });
        setMinCouponValueToAvailable(min);
      } catch (error) {
        addToast({
          title: 'Erro ao atualizar valor mínimo de cupom',
          type: 'error',
        });
      }
    },
    [addToast],
  );

  const maxPercent = useMemo(() => {
    if (!couponsCount) return 0;
    const maxPercentValue = couponsCount.totalCouponsQuantity.reduce(
      (max, item) => (item.percent > max ? item.percent : max),
      0,
    );
    return maxPercentValue + 100;
  }, [couponsCount]);

  const fetchStatusRobots = useCallback(async () => {
    try {
      const [robotCoupons] = await Promise.all([api.get('/robot/coupons')]);

      setMinCouponValueToAvailable(robotCoupons.data.minCouponValueToAvailable);
      setRobotCouponsStatus(robotCoupons.data.status);
    } catch (error) {
      addToast({ title: 'Erro ao buscar status dos robôs', type: 'error' });
    }
  }, [addToast]);

  const handleToggleStatusRobot = useCallback(
    async (name: string) => {
      try {
        const newStatus = await api.put(`/robot/${name}/turn`);
        setRobotCouponsStatus(newStatus.data.newStatus);
      } catch (error) {
        addToast({
          title: 'Não foi possível ligar/desligar robô.',
          type: 'error',
        });
      }
    },
    [addToast],
  );

  useEffect(() => {
    fetchStatusRobots();
  }, [fetchStatusRobots]);

  useEffect(() => {
    fetchCouponsCount();

    const interval = setInterval(() => {
      fetchCouponsCount();
      fetchStatusRobots();
    }, 5000);
    return () => clearInterval(interval);
  }, [fetchCouponsCount, fetchStatusRobots]);

  return (
    <S.Container>
      {couponsCount && (
        <S.ContentWrapper>
          <S.Header>
            <h1>Cupons</h1>

            <S.OptionsWrapper>
              <S.ButtonOption styleButton="danger" onClick={handleClearCoupons}>
                <FiTrash size={20} />
              </S.ButtonOption>
              <S.ButtonOption
                styleButton="alert"
                onClick={handleRefreshAccounts}
              >
                <FiRotateCcw size={20} />
              </S.ButtonOption>
              <S.ButtonOption
                styleButton="success"
                onClick={() => handleToggleStatusRobot('coupons')}
                online={robotCouponsStatus}
              >
                <FiZap size={20} />
              </S.ButtonOption>
            </S.OptionsWrapper>
          </S.Header>
          <S.Bar>
            <S.Progress percent={couponsCount.totalVerified.percentComplete} />

            <span className="value">
              {`${
                couponsCount.totalVerified.percentComplete > 100
                  ? 100
                  : couponsCount.totalVerified.percentComplete
              }%`}
            </span>
          </S.Bar>

          <S.VerifiedToTotalLabel>
            {`${couponsCount.totalVerified.total}/${couponsCount.totalToSearch}`}
          </S.VerifiedToTotalLabel>

          {maxPercent > 30 && (
            <S.ColumnsGraph style={{ height: `${maxPercent.toFixed(0)}px` }}>
              {couponsCount.totalCouponsQuantity.length > 3 &&
                couponsCount.totalCouponsQuantity.map(item => (
                  <S.Column key={item._id} percent={item.percent * 3}>
                    <span className="percent">
                      {`${item.percent.toFixed(0)}%`}
                    </span>
                    <span className="coupon">{`$${item._id}`}</span>
                    <span className="quantity">{`${item.count}`}</span>
                  </S.Column>
                ))}
            </S.ColumnsGraph>
          )}

          <S.LineOfCouponsWrapper>
            {couponsCount.totalCouponsQuantity.map(item => (
              <S.CouponPoint
                key={item._id}
                onClick={() => handleChangeMinCouponValueToAvailable(item._id)}
                active={item._id >= minCouponValueToAvailable}
              >
                <div className="object-wrapper">
                  <div className="line" />
                  <div className="point" />
                </div>

                <div className="value">{`$${item._id}`}</div>
              </S.CouponPoint>
            ))}
          </S.LineOfCouponsWrapper>

          <S.InformationsWrapper>
            <S.InfoBlock>
              <span className="title">Disponíveis</span>
              <span className="value">{couponsCount.availables}</span>
            </S.InfoBlock>

            <S.InfoBlock>
              <span className="title">Total</span>
              <span className="value">
                {couponsCount.totalVerified.hasCoupons}
              </span>
            </S.InfoBlock>

            <S.InfoBlock>
              <span className="title">Aplicados</span>
              <span className="value">{couponsCount.applieds}</span>
            </S.InfoBlock>
          </S.InformationsWrapper>
        </S.ContentWrapper>
      )}
    </S.Container>
  );
};

export default CouponsRobot;
