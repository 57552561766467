import React, {
  HTMLAttributes,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useParams } from 'react-router-dom';
import { addDays, addHours, format } from 'date-fns';
import {
  FiCheckCircle,
  FiDollarSign,
  FiEdit,
  FiHome,
  FiPieChart,
  FiStar,
} from 'react-icons/fi';
import { Helmet } from 'react-helmet';
import * as S from './styles';
import { useAplication } from '../../../hooks/app';
import api from '../../../services/api';
import { IApplied } from '../../../DTOS/IApplied';
import { useToast } from '../../../hooks/toast';
import InformationBlock from '../../../components/InformationBlock';

import logo from '../../../assets/logo.png';
import Tooltip from '../../../components/Tooltip';

type StoreProps = HTMLAttributes<HTMLDivElement>;

type ParamsProps = {
  restaurantId: string;
};

const Store: React.FC<StoreProps> = ({ ...rest }) => {
  const { addToast } = useToast();
  const { restaurants, startDate, endDate, periodType, setPeriodType } =
    useAplication();
  const { restaurantId } = useParams<ParamsProps>();

  const restaurant = useMemo(() => {
    return restaurants.find(r => r.id === restaurantId);
  }, [restaurantId, restaurants]);

  const [applieds, setApplieds] = useState<IApplied[]>([]);

  useEffect(() => {
    setPeriodType('lastweek');
  }, [setPeriodType]);

  useMemo(async () => {
    try {
      if (!restaurant) return;
      if (!startDate && !endDate) return;

      setApplieds([]);

      const response = await api.get<IApplied[]>(
        `applied/restaurant/${restaurant.id}/pending/between/${startDate}/${endDate}`,
      );

      if (!response.data) return;

      setApplieds(response.data);
    } catch (err) {
      addToast({
        title: 'Não foi possivel buscar as aplicações',
      });
    }
  }, [addToast, endDate, restaurant, startDate]);

  const calcs = useMemo(() => {
    const total = applieds.reduce(
      (acc, item) =>
        acc + item.calcs.enterprise.total + item.calcs.restaurant.total,
      0,
    );
    const enterprise = applieds.reduce(
      (acc, item) => acc + item.calcs.enterprise.total,
      0,
    );
    const res = applieds.reduce(
      (acc, item) => acc + item.calcs.restaurant.total,
      0,
    );
    const applicators = applieds.reduce(
      (acc, item) => acc + item.calcs.enterprise.aplicador,
      0,
    );
    const evaluators = applieds.reduce(
      (acc, item) => acc + item.calcs.enterprise.avaliacao,
      0,
    );

    const fees = applieds.reduce(
      (acc, item) => acc + item.calcs.restaurant.taxa,
      0,
    );

    const lucro = applieds.reduce(
      (acc, item) => acc + item.calcs.enterprise.lucro,
      0,
    );

    const restaurantsProfit = applieds.reduce(
      (acc, item) => acc + item.calcs.restaurant.lucro,
      0,
    );

    const received = applieds
      .filter(a => a.received)
      .reduce((acc, item) => acc + item.calcs.enterprise.total, 0);

    const igor = (lucro * 30) / 100;
    const esther = (lucro * 30) / 100;

    return {
      total,
      enterprise,
      res,
      restaurantsProfit,
      applicators,
      evaluators,
      fees,
      received,
      igor,
      esther,
    };
  }, [applieds]);

  const handleEditComment = useCallback(
    async (appliedId: string) => {
      try {
        const comment = window.prompt('Digite o comentário para este pedido:');

        if (comment === 'remover') {
          await api.put(`applied/insert/comment/${appliedId}`, {
            comment: null,
          });

          setApplieds(oldApplieds =>
            oldApplieds.map(a =>
              a.id === appliedId ? { ...a, comment: undefined } : a,
            ),
          );

          addToast({
            title: 'Comentário removido!',
          });
          return;
        }

        if (comment) {
          await api.put(`applied/insert/comment/${appliedId}`, {
            comment,
          });

          setApplieds(oldApplieds =>
            oldApplieds.map(a => (a.id === appliedId ? { ...a, comment } : a)),
          );

          addToast({
            title: 'Comentário inserido!',
            type: 'success',
          });
        }
      } catch (err) {
        console.log(err);
        addToast({
          title: 'Não foi possivel inserir comentário.',
          type: 'error',
        });
      }
    },
    [addToast],
  );

  return (
    <S.Container {...rest}>
      {restaurant && (
        <>
          <Helmet>
            <meta charSet="utf-8" />
            <title>{restaurant.name}</title>
            <meta property="og:title" content="Incentivo" />
            <meta property="og:description" content={restaurant.name} />
            <meta property="og:image" content={restaurant.img} />
            <meta
              property="og:url"
              content={`https://incentivoss.netlify.app/store/${restaurant.id}`}
            />
            <meta property="og:type" content="website" />
          </Helmet>

          <InformationBlock
            img={restaurant.img}
            uptitle="Restaurante"
            value={restaurant.name}
          />
          <S.Divider>
            <InformationBlock
              uptitle="Cupons"
              Icon={FiCheckCircle}
              value={String(applieds.length)}
            />

            <InformationBlock
              uptitle="Valor a pagar"
              img={logo}
              value={`R$ ${calcs.enterprise.toFixed(2)}`}
            />
          </S.Divider>

          <InformationBlock
            uptitle="Valor do restaurante"
            Icon={FiHome}
            value={`R$ ${calcs.res.toFixed(2)}`}
          />

          <S.Divider>
            <InformationBlock
              uptitle="Taxas"
              Icon={FiPieChart}
              value={`R$ ${calcs.fees.toFixed(2)}`}
            />

            <InformationBlock
              uptitle="Lucro Líquido"
              Icon={FiDollarSign}
              value={`R$ ${calcs.restaurantsProfit.toFixed(2)}`}
            />
          </S.Divider>

          <S.Divider>
            {startDate && (
              <S.DateRange>
                <span>
                  {`${format(
                    addDays(new Date(startDate), 1),
                    'dd/MM',
                  )} ao dia ${format(new Date(endDate), 'dd/MM')}`}
                </span>
              </S.DateRange>
            )}
          </S.Divider>

          <S.Divider>
            <S.ButtonCurrentWeek
              type="button"
              onClick={() => setPeriodType('today')}
              active={periodType === 'today'}
            >
              Hoje
            </S.ButtonCurrentWeek>

            <S.ButtonLastWeek
              type="button"
              onClick={() => setPeriodType('pendings')}
              active={periodType === 'pendings'}
            >
              Pendentes
            </S.ButtonLastWeek>
          </S.Divider>

          <S.Divider>
            <S.ButtonCurrentWeek
              type="button"
              onClick={() => setPeriodType('currentweek')}
              active={periodType === 'currentweek'}
            >
              Semana atual
            </S.ButtonCurrentWeek>

            <S.ButtonLastWeek
              type="button"
              onClick={() => setPeriodType('lastweek')}
              active={periodType === 'lastweek'}
            >
              Semana passada
            </S.ButtonLastWeek>
          </S.Divider>

          {applieds.map(applied => (
            <S.Applied received={applied.received} key={applied.id}>
              <S.Wrapper>
                <S.UpTitle>Pedido</S.UpTitle>
                <S.Value>{`#${applied.orderNumber}`}</S.Value>
              </S.Wrapper>

              <S.Wrapper>
                <S.UpTitle>Cupom</S.UpTitle>
                <S.Value>{`R$ ${applied.coupon}`}</S.Value>
              </S.Wrapper>

              <S.Wrapper>
                <S.UpTitle>Restaurante</S.UpTitle>
                <S.Value>{`R$ ${applied.calcs.restaurant.total}`}</S.Value>
              </S.Wrapper>
              <S.Wrapper>
                <S.UpTitle>Data</S.UpTitle>
                <S.Value>
                  {`${format(new Date(applied.date), 'dd/MM')}`}
                </S.Value>
              </S.Wrapper>

              <S.Wrapper>
                <S.UpTitle>Avaliado</S.UpTitle>
                <S.Value>
                  {applied.rated ? (
                    <S.Value
                      style={{ display: 'flex', alignItems: 'center', gap: 8 }}
                    >
                      <FiStar color="#FFB800" fill="#FFB800" />
                      {format(addHours(new Date(applied.date), 3), 'dd/MM')}
                    </S.Value>
                  ) : (
                    <FiStar />
                  )}
                </S.Value>
              </S.Wrapper>

              {!applied.rated && (
                <S.Wrapper
                  style={{
                    cursor: 'pointer',
                  }}
                  onClick={() => handleEditComment(applied.id)}
                >
                  <S.UpTitle
                    style={{ color: applied.comment ? '#FFB800' : '' }}
                  >
                    Comentário
                  </S.UpTitle>
                  {applied.comment ? (
                    <Tooltip title={applied.comment} upside>
                      <S.Comment
                        style={{ color: applied.comment ? '#FFB800' : '' }}
                      >
                        {applied.comment}
                      </S.Comment>
                    </Tooltip>
                  ) : (
                    <S.Comment>
                      <FiEdit />
                    </S.Comment>
                  )}
                </S.Wrapper>
              )}
            </S.Applied>
          ))}
        </>
      )}
    </S.Container>
  );
};

export default Store;
