import React, { useMemo } from 'react';
import * as S from './styles';

import Restaurant from './components/Restaurant';
import { useAplication } from '../../hooks/app';

const Checkin: React.FC = () => {
  const { restaurants } = useAplication();

  const restaurantsToRender = useMemo(() => {
    const applicatorId = localStorage.getItem(
      `71c66b70-3f7d-4236-ad00-76510ba0edb7`,
    );

    const imperioIgor = localStorage.getItem(
      `a76e8fff-d247-4e7b-8143-2f61a9bf4e7d`,
    );
    const imperioEsther = localStorage.getItem(
      `7557eb6f-8366-4203-8917-075fbf5f001f`,
    );

    if (imperioIgor || imperioEsther) {
      const checkin = restaurants.filter(r => r.applicatorIdCheckin);
      const checkout = restaurants.filter(r => !r.applicatorIdCheckin);

      return [...checkout, ...checkin];
    }

    const checkin = restaurants.filter(
      r => r.applicatorIdCheckin === applicatorId,
    );

    const checkout = restaurants.filter(r => !r.applicatorIdCheckin);

    return [...checkin, ...checkout];
  }, [restaurants]);

  return (
    <S.Container>
      <S.Panel>
        <h1>Atacar</h1>
        <span>
          O valor registrado será pago ao aplicador da loja e a quem atacou (o
          mesmo valor pra cada).
        </span>

        <div>
          {restaurantsToRender
            .filter(r => r.status === 'Atacar')
            .map(r => (
              <Restaurant restaurant={r} key={r.id} />
            ))}
        </div>
      </S.Panel>

      <S.Panel>
        <h1>Devendo</h1>
        <div>
          {restaurantsToRender
            .filter(r => r.status === 'Devendo')
            .map(r => (
              <Restaurant restaurant={r} key={r.id} />
            ))}
        </div>
      </S.Panel>

      <S.Panel>
        <h1>Aplicando</h1>

        <div>
          {restaurantsToRender
            .filter(r => r.status === 'Online' && r.applicatorIdCheckin)
            .map(r => (
              <Restaurant restaurant={r} key={r.id} />
            ))}
        </div>
      </S.Panel>

      <S.Panel>
        <h1>Cadastradas</h1>

        <div>
          {restaurantsToRender
            .filter(r => r.status === 'Online' && !r.applicatorIdCheckin)
            .map(r => (
              <Restaurant restaurant={r} key={r.id} />
            ))}
        </div>
      </S.Panel>
    </S.Container>
  );
};

export default Checkin;
