import styled, { css } from 'styled-components';

type IActiveProps = {
  active: boolean;
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  gap: 6px;
  padding: 0 10px 20px;
`;

export const InputSearchStore = styled.input`
  border-radius: 10px;
  padding: 6px 12px;
  width: 100%;
`;

export const TypeSearchContainer = styled.div``;

export const TypeSearch = styled.div<{ selected: boolean }>`
  font-size: 12px;
  cursor: pointer;

  background-color: #ffffff20;
  padding: 6px 12px;

  border-radius: 100px;

  ${props =>
    props.selected &&
    css`
      background-color: var(--primary40);
    `}
`;

export const StoresFoundContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  flex-direction: column;
`;

export const StoreFound = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;

  background-color: #ffffff40;
  padding: 8px;

  border-radius: 10px;

  font-size: 12px;

  span {
    font-size: 12px;
  }

  a {
    color: #fff;
  }

  div {
    display: flex;
    flex-direction: column;
    font-size: 12px;
  }

  img {
    width: 50px;
    height: 50px;
    border-radius: 10px;
  }
`;

export const DesbugButton = styled.button`
  padding: 4px 10px;
  background-color: transparent;
  color: #ffff;
`;

export const ButtonTurn = styled.div<IActiveProps>`
  border: 1px solid #ffffff30;

  color: #ffffff30;

  cursor: pointer;

  padding: 6px 18px;

  border-radius: 100px;

  ${props =>
    props.active &&
    css`
      color: var(--primary);
      border: 1px solid var(--primary);
    `}
`;

export const Offer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  gap: 20px;

  padding: 20px;
  border-radius: 6px;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
`;

export const InputFormatNumber = styled.input`
  height: 40px;
  padding: 4px 12px;

  border-radius: 8px;

  background-color: transparent;
  color: #fff;

  width: 100%;
  outline: none;

  border: 1px solid #fff;

  text-align: center;
`;
export const ButtonCreateWhatsLink = styled.button`
  width: 200px;
  height: 40px;
  border-radius: 10px;
  border: none;
`;
export const WhatsLink = styled.a`
  text-align: center;
  color: var(--primary);
`;

export const OfferLinkWrapper = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const OfferImg = styled.img`
  width: 100px;
  height: 100px;

  object-fit: cover;

  border-radius: 6px;
`;

export const OfferName = styled.span`
  font-weight: 600;
  font-size: 20px;
`;

export const offerEvaluations = styled.span`
  font-size: 14px;
`;

export const OfferLinkWppWrapper = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;

  gap: 16px;

  border-radius: 6px;
  border: 1px solid var(--alert);
  height: 40px;

  padding: 0 20px;

  text-decoration: none;

  color: var(--alert);

  transition: 0.2s ease;

  &:hover {
    background-color: var(--alert20);
  }
`;

export const WithoutZap = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  gap: 16px;

  border-radius: 6px;
  border: 1px solid var(--danger);
  height: 40px;

  padding: 0 20px;

  text-decoration: none;
  background-color: transparent;

  color: var(--danger);

  transition: 0.2s ease;

  &:hover {
    background-color: var(--danger20);
  }
`;

export const MessageSent = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  gap: 16px;

  border-radius: 6px;
  border: 1px solid var(--primary);
  height: 40px;

  background-color: transparent;
  padding: 0 20px;

  text-decoration: none;

  color: var(--primary);

  transition: 0.2s ease;

  &:hover {
    background-color: var(--primary20);
  }
`;
