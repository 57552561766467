import React, { useCallback, useEffect, useState } from 'react';
import { FiZap } from 'react-icons/fi';
import { format } from 'date-fns';
import * as S from './styles';
import api from '../../../services/api';
import { useToast } from '../../../hooks/toast';

type ICookieCountProps = {
  updated: number;
  notUpdated: number;
  oldestCookieUpdatedDate: string;
  percent: number;
};

const CookiesRobot: React.FC = () => {
  const [cookieCount, setCookieCount] = useState<ICookieCountProps>();
  const [robotUpdateCookiesStatus, setRobotUpdateCookiesStatus] =
    useState(false);
  const { addToast } = useToast();

  const handleToggleStatusRobot = useCallback(
    async (name: string) => {
      try {
        const newStatus = await api.put(`/robot/${name}/turn`);
        switch (name) {
          case 'updatecookies':
            setRobotUpdateCookiesStatus(newStatus.data.newStatus);
            break;
          default:
            break;
        }
      } catch (error) {
        addToast({
          title: 'Não foi possível ligar/desligar robô.',
          type: 'error',
        });
      }
    },
    [addToast],
  );

  const fetchStatusRobots = useCallback(async () => {
    try {
      const [robotUpdateCookies] = await Promise.all([
        api.get('/robot/updatecookies'),
      ]);

      setRobotUpdateCookiesStatus(robotUpdateCookies.data.status);
    } catch (error) {
      addToast({ title: 'Erro ao buscar status dos robôs', type: 'error' });
    }
  }, [addToast]);

  const fetchCookiesCount = useCallback(async () => {
    try {
      const response = await api.get<ICookieCountProps>('email/cookie/count');
      setCookieCount(response.data);
    } catch (error) {
      addToast({ title: 'Erro ao buscar contagem de cookies', type: 'error' });
    }
  }, [addToast]);

  useEffect(() => {
    fetchStatusRobots();
  }, [fetchStatusRobots]);

  useEffect(() => {
    fetchCookiesCount();

    const interval = setInterval(() => {
      fetchCookiesCount();
      fetchStatusRobots();
    }, 5000);
    return () => clearInterval(interval);
  }, [fetchCookiesCount, fetchStatusRobots]);

  return (
    <S.Container>
      {cookieCount && (
        <S.ContentWrapper>
          <S.Header>
            <h1>Cookies</h1>

            <S.OptionsWrapper>
              <S.ButtonOption
                styleButton="success"
                onClick={() => handleToggleStatusRobot('updatecookies')}
                online={robotUpdateCookiesStatus}
              >
                <FiZap size={20} />
              </S.ButtonOption>
            </S.OptionsWrapper>
          </S.Header>

          <S.Bar>
            <S.Progress percent={cookieCount.percent} />

            <span className="value">
              {`${cookieCount.percent > 100 ? 100 : cookieCount.percent}%`}
            </span>
          </S.Bar>

          <S.VerifiedToTotalLabel>
            {`${cookieCount.updated}/${cookieCount.notUpdated}`}
          </S.VerifiedToTotalLabel>

          <S.VerifiedToTotalLabel>
            {`Cookie mais antigo atualizado: ${format(
              new Date(cookieCount.oldestCookieUpdatedDate),
              'dd/MM/yyyy',
            )}`}
          </S.VerifiedToTotalLabel>
        </S.ContentWrapper>
      )}
    </S.Container>
  );
};

export default CookiesRobot;
