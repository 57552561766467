import React from 'react';
import * as S from './styles';

type ValuePercent = {
  value: number;
  percent: number;
};

export type CountProps = {
  total: number;
  cookies: ValuePercent;
  notVerified: ValuePercent;
  toVerify: ValuePercent;
  passwordWrong: ValuePercent;
  cookiesToCapture: ValuePercent;
  needNumber: ValuePercent;
  ifoodBan: ValuePercent;
  emailLost: ValuePercent;
  totalVerifiedPercent: number;
  newAccount: ValuePercent;
  newAccountsUsed: ValuePercent;
};

type ILogsPanelProps = {
  count: CountProps;
};

const EmailCountPanel: React.FC<ILogsPanelProps> = ({ count }) => {
  return (
    <S.Container>
      {count && (
        <>
          <S.Bar>
            <S.Progress percent={count.cookies.percent} />

            <span className="value">
              {`
                ${count.cookies.percent}%
                `}
            </span>
          </S.Bar>

          <S.VerifiedToTotalLabel>
            {`${count.cookies.value}/${count.total}`}
          </S.VerifiedToTotalLabel>
          <S.InformationsWrapper>
            <S.InfoBlock>
              <span className="title">
                Contas
                <br />
                novas
              </span>
              <span className="value">{count.newAccount.value}</span>
            </S.InfoBlock>
            <S.InfoBlock>
              <span className="title">
                Novas
                <br />
                usadas
              </span>
              <span className="value">{count.newAccountsUsed.value}</span>
            </S.InfoBlock>
            <S.InfoBlock>
              <span className="title">
                Não
                <br />
                verificados
              </span>
              <span className="value">{count.notVerified.value}</span>
            </S.InfoBlock>
            <S.InfoBlock>
              <span className="title">
                Email
                <br />
                bloqueado
              </span>
              <span className="value">{count.toVerify.value}</span>
            </S.InfoBlock>
            <S.InfoBlock>
              <span className="title">
                Pediu
                <br />
                número
              </span>
              <span className="value">{count.needNumber.value}</span>
            </S.InfoBlock>

            <S.InfoBlock>
              <span className="title">
                Senha
                <br />
                errada
              </span>
              <span className="value">{count.passwordWrong.value}</span>
            </S.InfoBlock>

            <S.InfoBlock>
              <span className="title">
                iFood
                <br />
                banido
              </span>
              <span className="value">{count.ifoodBan.value}</span>
            </S.InfoBlock>

            <S.InfoBlock>
              <span className="title">
                Email
                <br />
                perdido
              </span>
              <span className="value">{count.emailLost.value}</span>
            </S.InfoBlock>
          </S.InformationsWrapper>
        </>
      )}
    </S.Container>
  );
};

export default EmailCountPanel;
