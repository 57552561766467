import React, { useCallback, useEffect, useState } from 'react';
// import { useHistory } from 'react-router-dom';
import * as S from './styles';
import api from '../../services/api';
import { IOffer } from '../../DTOS/IOffer';
import { useAplication } from '../../hooks/app';
import { useToast } from '../../hooks/toast';
import { toMoney } from '../../utils/toMoney';

type IOfferApi = {
  offer: IOffer;
  count: number;
  countAll: number;
};

const message = [
  'Ei,',
  'Eai,',
  'Opa,',
  'Tudo bom?',
  'Oi,',
  'Olá,',
  'Como vai?',
  'Fala comigo,',
  'Beleza?',
  'Eii,',
  'Oii,',
  'Tudo bem?',
  'Tudo joia?',
];

const Marketing: React.FC = () => {
  const [offer, setOffer] = useState<IOffer>();
  const [messagesSentCount, setMessagesSentCount] = useState(0);
  const { addToast } = useToast();
  const [showButtons, setShowButtons] = useState(false);
  const { applicatorLogged } = useAplication();
  const [countAll, setCountAll] = useState(0);
  const [whatsLink, setWhatsLink] = useState('');
  const [alreadySentToOwner, setAlreadySentToOwner] = useState(false);
  const [storesFound, setStoresFound] = useState<IOffer[]>([]);
  const [typeSearch, setTypeSearch] = useState(0);
  // const { push } = useHistory();

  const formatPhoneNumber = useCallback((phone: string) => {
    // Remove todos os caracteres que não sejam dígitos
    const digits = phone.replace(/\D/g, '');

    // Verifica se o número de dígitos está correto (11 para DDD+Número)
    if (digits.length !== 11) {
      console.log('Número de telefone inválido');
    }

    // Adiciona o código do país (55 para o Brasil)
    const formattedPhone = `55${digits}`;

    return formattedPhone;
  }, []);

  // useEffect(() => {
  //   if (
  //     applicatorLogged.applicator &&
  //     applicatorLogged.applicator.id === '43f1147f-5b68-4d7a-bd03-cf22ec8a5030'
  //     //  ||
  //     // applicatorLogged.applicator.id ===
  //     //   '30c5ee52-705f-471f-99d3-8aa1a266305c'
  //   ) {
  //     push('/');
  //   }
  // }, [applicatorLogged.applicator, push]);

  const createWhatsAppLink = useCallback(
    (phone, msg, storeName, stars, reviews) => {
      // Formata o número de telefone
      const formattedPhone = formatPhoneNumber(phone);

      // Codifica a mensagem para o formato de URL
      const encodedMessage = encodeURIComponent(
        `${msg} Você é o proprietário da loja ${storeName} - ${stars} estrelas em ${reviews} avaliações? Da uma olhada na imagem que te enviei, oportunidade pra você fazer dinheiro e aumentar suas avaliações 5 estrelas.`,
      );

      // Cria o link do WhatsApp
      const whatsappLink = `https://api.whatsapp.com/send?phone=${formattedPhone}&text=${encodedMessage}`;

      setWhatsLink(whatsappLink);
    },
    [formatPhoneNumber],
  );

  const getOffer = useCallback(
    async (applicatorId: string) => {
      try {
        const response = await api.get<IOfferApi>(
          `offer/not/send/message/${applicatorId}`,
        );

        setOffer(response.data.offer);
        setCountAll(response.data.countAll);
        setMessagesSentCount(response.data.count);

        if (response.data.offer.cnpjPhone) {
          createWhatsAppLink(
            response.data.offer.cnpjPhone,
            message[Math.floor(Math.random() * message.length)],
            response.data.offer.name,
            response.data.offer.stars,
            response.data.offer.evaluations,
          );
        }
      } catch (err) {
        addToast({ title: 'Não foi possivel buscar loja', type: 'error' });
      }
    },
    [addToast, createWhatsAppLink],
  );

  const handleWithoutZap = useCallback(async () => {
    if (!offer || !applicatorLogged.applicator) return;
    try {
      await api.put(`offer/${offer.id}`, {
        hasWhatsApp: false,
      });
      getOffer(applicatorLogged.applicator.id);
      setWhatsLink('');
      setShowButtons(false);
      setAlreadySentToOwner(false);
    } catch (err) {
      addToast({ title: 'Não foi possivel atualizar loja', type: 'error' });
    }
  }, [addToast, offer, applicatorLogged.applicator, getOffer]);

  const handleMessageSent = useCallback(async () => {
    if (!offer || !applicatorLogged.applicator) return;

    try {
      await api.put(`offer/${offer.id}`, {
        lastMessage: new Date(),
      });
      getOffer(applicatorLogged.applicator.id);
      setWhatsLink('');
      setShowButtons(false);
      setAlreadySentToOwner(false);
    } catch (err) {
      addToast({ title: 'Não foi possivel atualizar loja', type: 'error' });
    }
  }, [addToast, offer, applicatorLogged.applicator, getOffer]);

  useEffect(() => {
    if (!applicatorLogged.applicator?.id) return;
    getOffer(applicatorLogged.applicator.id);
  }, [getOffer, applicatorLogged.applicator]);

  const handleDesbug = useCallback(async () => {
    if (applicatorLogged.applicator) {
      await api.put(`offer/desbug/${applicatorLogged.applicator.id}`);
      window.location.reload();
    }
  }, [applicatorLogged]);

  const searchStore = useCallback(
    async (e: string) => {
      if (!e) return;

      if (typeSearch === 0) {
        const response = await api.get(`offer/find/${e}`);
        setStoresFound(response.data);
      } else if (typeSearch === 1) {
        const response = await api.get(`offer/find/phone/${e}`);
        setStoresFound(response.data);
      } else {
        const response = await api.get(`offer/find/cnpjphone/${e}`);
        setStoresFound(response.data);
      }
    },
    [typeSearch],
  );

  return (
    <S.Container>
      {offer && (
        <S.Offer key={offer.id}>
          <S.TypeSearchContainer>
            <S.TypeSearch
              onClick={() => setTypeSearch(0)}
              selected={typeSearch === 0}
            >
              Nome da loja
            </S.TypeSearch>
            <S.TypeSearch
              onClick={() => setTypeSearch(1)}
              selected={typeSearch === 1}
            >
              Telefone
            </S.TypeSearch>
            <S.TypeSearch
              onClick={() => setTypeSearch(2)}
              selected={typeSearch === 2}
            >
              Telefone CNPJ
            </S.TypeSearch>
          </S.TypeSearchContainer>

          <S.InputSearchStore
            placeholder="Pesquisar Loja"
            onChange={e => searchStore(e.target.value)}
          />

          {storesFound.length > 0 && (
            <S.StoresFoundContainer>
              {storesFound.map(s => (
                <S.StoreFound key={s.id}>
                  <img src={s.logo} alt="" />
                  <div>
                    <a href={s.url} target="_blank" rel="noopener noreferrer">
                      {s.name}
                    </a>
                    <span>{`${s.ddd} ${s.phone}`}</span>
                    <span>{s.cnpjPhone}</span>
                  </div>
                </S.StoreFound>
              ))}
            </S.StoresFoundContainer>
          )}
          <span>
            {`
          ${countAll}
          lojas disponíveis`}
          </span>
          <S.DesbugButton onClick={handleDesbug}>Desbugar</S.DesbugButton>
          <S.MessageSent>{`${toMoney(messagesSentCount * 0.5)}`}</S.MessageSent>
          <S.OfferLinkWrapper target="_blank" href={offer.url}>
            <S.OfferImg src={offer.logo} />
          </S.OfferLinkWrapper>
          <S.OfferName>{offer.name}</S.OfferName>

          <S.offerEvaluations>{`${offer.stars} (${offer.evaluations})`}</S.offerEvaluations>

          {whatsLink && !alreadySentToOwner && (
            <S.OfferLinkWppWrapper
              target="_blank"
              href={whatsLink}
              onClick={() => setAlreadySentToOwner(true)}
            >
              <span>Enviar no(a) proprietário(a)</span>
            </S.OfferLinkWppWrapper>
          )}

          {showButtons === true ? (
            <div>
              <S.WithoutZap onClick={handleWithoutZap}>
                Sem WhatsApp
              </S.WithoutZap>
              <S.MessageSent onClick={handleMessageSent}>
                Mensagem enviada
              </S.MessageSent>
            </div>
          ) : (
            <S.OfferLinkWppWrapper
              target="_blank"
              onClick={() => setShowButtons(true)}
              href={`https://api.whatsapp.com/send?phone=55${offer.ddd}${
                offer.phone
              }&text=${
                message[Math.floor(Math.random() * message.length)]
              } Você é o(a) dono(a) da loja ${offer.name} - ${
                offer.stars
              } estrelas com ${
                offer.evaluations
              } avaliações? Da uma olhada na imagem que te enviei, oportunidade pra você fazer dinheiro e aumentar suas avaliações 5 estrelas.`}
            >
              <span>Enviar na loja</span>
            </S.OfferLinkWppWrapper>
          )}
        </S.Offer>
      )}
    </S.Container>
  );
};

export default Marketing;
