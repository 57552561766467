import React, { HTMLAttributes } from 'react';

import { IconType } from 'react-icons/lib';
import * as S from './styles';
import { ToCopy } from '../../../../components/ToCopy';

type CopyRandomAddressProps = HTMLAttributes<HTMLDivElement> & {
  text: string | number;
  label: string | number;
  Icon: IconType;
};

const CopyItem: React.FC<CopyRandomAddressProps> = ({
  Icon,
  text,
  label,
  ...rest
}) => {
  return (
    <ToCopy text={text}>
      <S.Container {...rest}>
        <S.IconContainer>
          <Icon size={17} color="#ddd" />
        </S.IconContainer>

        <S.TextContainer>
          <S.Text>{label}</S.Text>
        </S.TextContainer>
      </S.Container>
    </ToCopy>
  );
};

export default CopyItem;
