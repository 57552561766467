import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FiX } from 'react-icons/fi';
import * as S from './styles';
import Period from '../../../components/Period';
import { useAplication } from '../../../hooks/app';
import InformationBlock from '../../../components/InformationBlock';
import { IRestaurant } from '../../../DTOS/IRestaurant';
import api from '../../../services/api';
import { FinancialCalcsProps } from '..';
import { useToast } from '../../../hooks/toast';
import { toMoney } from '../../../utils/toMoney';
import CheckboxSlider from '../../../components/CheckboxSlider';
import { ceos } from '../../../db/ceos';
import { applicators } from '../../../db/applicators';
import { IOwner } from '../../../DTOS/IOwner';
import whatsImg from '../../../assets/whats.png';
import whatsredImg from '../../../assets/wppred.png';

type IRestaurantWithCalcs = IRestaurant & {
  calcs: FinancialCalcsProps;
};

type IOwnerRestaurants = IOwner & {
  restaurants: IRestaurantWithCalcs[];
};

const Owner: React.FC = () => {
  const { addToast } = useToast();
  const { restaurants, owners, startDate, endDate, periodType } =
    useAplication();
  const [ownerRestaurants, setOwnerRestaurants] = useState<IOwnerRestaurants[]>(
    [],
  );

  const [ownerRestaurantsPendings, setOwnerRestaurantsPendings] = useState<
    IOwnerRestaurants[]
  >([]);

  const [renderRestaurants, setRenderRestaurants] = useState<
    IOwnerRestaurants[]
  >([]);

  const [showRestaurantsPendings, setShowRestaurantsPendings] = useState(true);

  const [totalAmount, setTotalAmount] = useState(0);
  const [receivedAmount, setReceivedAmount] = useState(0);
  const [restaurantSelected, setRestaurantSelected] =
    useState<IRestaurantWithCalcs>();

  const hasEsther = useMemo(() => {
    return localStorage.getItem('7557eb6f-8366-4203-8917-075fbf5f001f');
  }, []);

  useEffect(() => {
    const totalSum = ownerRestaurants
      .map(owner =>
        owner.restaurants.reduce((acc, item) => acc + item.calcs.enterprise, 0),
      )
      .reduce((acc, item) => acc + item, 0);
    const receivedSum = ownerRestaurants
      .map(owner =>
        owner.restaurants.reduce((acc, item) => acc + item.calcs.received, 0),
      )
      .reduce((acc, item) => acc + item, 0);

    setTotalAmount(totalSum);
    setReceivedAmount(receivedSum);
  }, [ownerRestaurants]);

  useMemo(async () => {
    if (!startDate && !endDate) return;

    try {
      const ownerWithRestaurants = await Promise.all(
        owners.map(async o => {
          const restaurantsThisOwner = restaurants.filter(
            r => r.ownerId === o.id,
          );

          const restaurantsWithCalcs = await Promise.all(
            restaurantsThisOwner.map(async r => {
              const calcsData = await api.get<FinancialCalcsProps>(
                `applied/financial/${r.id}/${startDate}/${endDate}`,
              );

              return {
                ...r,
                calcs: calcsData.data,
              };
            }),
          );

          return {
            ...o,
            restaurants: restaurantsWithCalcs,
          };
        }),
      );

      const ownerRestaurantsPaymentPending = ownerWithRestaurants.filter(
        o =>
          o.restaurants.findIndex(r => r.calcs.received < r.calcs.enterprise) >
          -1,
      );

      setOwnerRestaurants(ownerWithRestaurants);
      setOwnerRestaurantsPendings(ownerRestaurantsPaymentPending);
      setRenderRestaurants(ownerRestaurantsPaymentPending);
    } catch (err) {
      addToast({
        title: 'Não foi possivel buscar as aplicações',
      });
    }
  }, [addToast, endDate, owners, restaurants, startDate]);

  const AddMessageIntoOwner = useCallback(async (ownerId: string) => {
    const notes = window.prompt('Escreva a anotação para o restaurante');
    if (!notes) return;
    await api.put(`restaurant/owner/${ownerId}`, {
      notes,
    });

    setRenderRestaurants(old =>
      old.map(owner => (owner.id === ownerId ? { ...owner, notes } : owner)),
    );
  }, []);

  const RemoveMessageIntoOwner = useCallback(async (ownerId: string) => {
    const confirm = window.confirm(
      'Tem certeza que deseja remover a mensagem?',
    );
    if (!confirm) return;
    await api.put(`restaurant/owner/${ownerId}`, {
      notes: null,
    });

    setRenderRestaurants(old =>
      old.map(owner =>
        owner.id === ownerId ? { ...owner, notes: '' } : owner,
      ),
    );
  }, []);

  //   const copyBillingMessage = useCallback((owner: IOwnerRestaurants) => {
  //     const totalCoupons = owner.restaurants.reduce(
  //       (acc, item) => acc + item.calcs.minered,
  //       0,
  //     );

  //     const pendings = owner.restaurants.reduce(
  //       (acc, item) => acc + item.calcs.enterprise - item.calcs.received,
  //       0,
  //     );

  //     const message = `Olá *${owner.name}*,

  // Enviamos ${totalCoupons} cupons no(s) seu(s) restaurante(s).
  // Valor: *${toMoney(pendings)}*
  // Chave Pix: *incentivos.marketing@gmail.com*

  // Você pode conferir os detalhes das aplicações neste link:
  // https://incentivoss.netlify.app/owner/${owner.id}
  //     `;

  //     copy(message);
  //   }, []);

  const handleReceivePayment = useCallback(
    async (restaurantId: string) => {
      if (periodType === 'currentweek') return;
      if (periodType === 'today') return;

      const msg =
        'Tem certeza que deseja alterar o recebimento de todas as aplicações do intervalo selecionado?';

      // eslint-disable-next-line no-alert
      const confirm = window.confirm(msg);

      if (confirm) {
        try {
          await api.put(
            `applied/received/${restaurantId}/${startDate}/${endDate}`,
          );

          setOwnerRestaurants(old =>
            old.map(o => ({
              ...o,
              restaurants: o.restaurants.map(r =>
                r.id === restaurantId
                  ? {
                      ...r,
                      calcs: { ...r.calcs, received: r.calcs.enterprise },
                    }
                  : r,
              ),
            })),
          );

          setOwnerRestaurantsPendings(old =>
            old.map(o => ({
              ...o,
              restaurants: o.restaurants.map(r =>
                r.id === restaurantId
                  ? {
                      ...r,
                      calcs: { ...r.calcs, received: r.calcs.enterprise },
                    }
                  : r,
              ),
            })),
          );
        } catch (err) {
          addToast({
            title: 'Não foi possivel atualizar as aplicações',
          });
        }
      }
    },
    [addToast, endDate, periodType, startDate],
  );

  useEffect(() => {
    setRenderRestaurants(
      showRestaurantsPendings ? ownerRestaurantsPendings : ownerRestaurants,
    );
  }, [ownerRestaurants, ownerRestaurantsPendings, showRestaurantsPendings]);

  const handleChangeStatus = useCallback(
    async (restaurantId: string, status: string) => {
      try {
        await api.put(`restaurant/${restaurantId}`, {
          status,
        });

        setOwnerRestaurants(old =>
          old.map(o => ({
            ...o,
            restaurants: o.restaurants.map(r =>
              r.id === restaurantId
                ? {
                    ...r,
                    status,
                  }
                : r,
            ),
          })),
        );

        setOwnerRestaurantsPendings(old =>
          old.map(o => ({
            ...o,
            restaurants: o.restaurants.map(r =>
              r.id === restaurantId
                ? {
                    ...r,
                    status,
                  }
                : r,
            ),
          })),
        );
      } catch (err) {
        addToast({ title: 'Não foi possivel atualizar status do restaurante' });
      }
    },
    [addToast],
  );

  return (
    <S.Container>
      <Period />

      {!hasEsther && (
        <>
          <InformationBlock
            uptitle="Falta receber"
            status="warning"
            value={toMoney(totalAmount - receivedAmount)}
          />
        </>
      )}
      {!hasEsther && (
        <>
          <InformationBlock
            uptitle="Recebido"
            status="success"
            value={toMoney(receivedAmount)}
          />
        </>
      )}

      <S.ButtonShowRestaurants
        onClick={() => setShowRestaurantsPendings(old => !old)}
        active={showRestaurantsPendings}
      >
        <span>Mostrar somente pendentes</span>
        <CheckboxSlider
          setActive={setShowRestaurantsPendings}
          active={showRestaurantsPendings}
        />
      </S.ButtonShowRestaurants>

      {renderRestaurants
        .filter(o => (hasEsther ? o.ceoId === ceos[1].id : o))
        .map(owner => {
          const enterprise = owner.restaurants.reduce(
            (acc, item) => acc + item.calcs.enterprise,
            0,
          );

          const received = owner.restaurants.reduce(
            (acc, item) => acc + item.calcs.received,
            0,
          );

          return (
            <S.GroupContainer key={owner.id}>
              <S.OwnerHeader>
                <div>
                  <S.Name>{owner.name}</S.Name>
                  {owner.notes && (
                    <S.Description
                      onClick={() => RemoveMessageIntoOwner(owner.id)}
                    >
                      {owner.notes}
                    </S.Description>
                  )}
                </div>
                {enterprise - received > 0 && (
                  <div>
                    {owner.restaurants[0].phone ? (
                      <>
                        <S.WhatsApp
                          target="_blank"
                          href={`https://api.whatsapp.com/send?phone=${
                            owner.restaurants[0].phone
                          }&text=${encodeURIComponent(
                            `Olá *${owner.name}*,\n\nSua loja está com restrição. Para continuar recebendo cupons, faça o pix da nossa parte dos cupons para continuarmos a investir na sua loja.\n\nChave Pix: *incentivos.marketing@gmail.com*\n\nVocê pode conferir os detalhes das aplicações neste link:\nhttps://incentivoss.netlify.app/owner/${owner.id}`,
                          )}`}
                        >
                          <img alt="img" src={whatsredImg} />
                        </S.WhatsApp>
                        <S.WhatsApp
                          target="_blank"
                          href={`https://api.whatsapp.com/send?phone=${
                            owner.restaurants[0].phone
                          }&text=Ol%C3%A1%20*${encodeURIComponent(
                            owner.name,
                          )}*%2C%0A%0AEnviamos%20${owner.restaurants.reduce(
                            (acc, item) => acc + item.calcs.minered,
                            0,
                          )}%20cupons%20no(s)%20seu(s)%20restaurante(s).%0AValor%3A%20*${encodeURIComponent(
                            toMoney(
                              owner.restaurants.reduce(
                                (acc, item) =>
                                  acc +
                                  item.calcs.enterprise -
                                  item.calcs.received,
                                0,
                              ),
                            ),
                          )}*%0AChave%20Pix%3A%20*incentivos.marketing@gmail.com*%0A%0AVoc%C3%AA%20pode%20conferir%20os%20detalhes%20das%20aplica%C3%A7%C3%B5es%20neste%20link%3A%0Ahttps%3A%2F%2Fincentivoss.netlify.app%2Fowner%2F${
                            owner.id
                          }`}
                        >
                          <img alt="img" src={whatsImg} />
                        </S.WhatsApp>
                        <S.ButtonAdd
                          onClick={() => AddMessageIntoOwner(owner.id)}
                        >
                          +
                        </S.ButtonAdd>
                      </>
                    ) : (
                      <div>Sem número de telefone</div>
                    )}
                  </div>
                )}
              </S.OwnerHeader>
              <S.Divider>
                {received === enterprise ? (
                  <InformationBlock
                    uptitle="Recebido"
                    status="success"
                    value={toMoney(received)}
                  />
                ) : (
                  <InformationBlock
                    uptitle="Falta receber"
                    status="warning"
                    value={toMoney(enterprise - received)}
                  />
                )}
              </S.Divider>

              {owner.restaurants.map(r => (
                <button
                  type="button"
                  key={r.id}
                  style={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <S.ApplicatorImg
                    className="applicatorImg"
                    src={
                      applicators.find(a => a.id === r.applicatorIdCheckin)?.img
                    }
                    alt=""
                    onClick={() => setRestaurantSelected(r)}
                  />
                  <InformationBlock
                    onClick={() => handleReceivePayment(r.id)}
                    status={
                      r.calcs.enterprise === r.calcs.received
                        ? 'success'
                        : 'warning'
                    }
                    img={r.img}
                    uptitle={`${r.name} - ${r.status}`}
                    value={toMoney(r.calcs.enterprise - r.calcs.received)}
                  />
                </button>
              ))}
            </S.GroupContainer>
          );
        })}

      {restaurantSelected && (
        <S.RestaurantSelectedOptions>
          <S.RestaurantImg
            style={{ marginRight: 10 }}
            src={restaurantSelected.img}
          />
          <S.StatusOption
            onClick={() => handleChangeStatus(restaurantSelected.id, 'Online')}
            status="Online"
          >
            Online
          </S.StatusOption>
          <S.StatusOption
            onClick={() => handleChangeStatus(restaurantSelected.id, 'Caiu')}
            className="danger"
            status="Caiu"
          >
            Caiu
          </S.StatusOption>
          <S.StatusOption
            onClick={() =>
              handleChangeStatus(restaurantSelected.id, 'Desistiu')
            }
            status="Desistiu"
          >
            Desistiu
          </S.StatusOption>

          <S.StatusOption
            onClick={() => handleChangeStatus(restaurantSelected.id, 'Erro')}
            status="Erro"
          >
            Erro
          </S.StatusOption>

          <S.StatusOption
            onClick={() => handleChangeStatus(restaurantSelected.id, 'Devendo')}
            status="Devendo"
          >
            Devendo
          </S.StatusOption>

          <S.StatusOption
            onClick={() => handleChangeStatus(restaurantSelected.id, 'Atacar')}
            status="Atacar"
          >
            Atacar
          </S.StatusOption>

          <S.CloseButton onClick={() => setRestaurantSelected(undefined)}>
            <FiX style={{ cursor: 'pointer' }} />
          </S.CloseButton>
        </S.RestaurantSelectedOptions>
      )}
    </S.Container>
  );
};

export default Owner;
