import React, { HTMLAttributes, useMemo, useState } from 'react';

import { FiClock, FiDollarSign, FiInbox } from 'react-icons/fi';
import * as S from './styles';
import { useAplication } from '../../hooks/app';
import { IApplicator } from '../../DTOS/IApplicator';
import api from '../../services/api';
import { IApplied } from '../../DTOS/IApplied';
import InformationBlock from '../../components/InformationBlock';
import { IRestaurant } from '../../DTOS/IRestaurant';
import Period from '../../components/Period';

type ApplicatorsProps = HTMLAttributes<HTMLDivElement>;

type ApplicatorWithApplieds = IApplicator & {
  received: number;
  toReceive: number;
  couponsQuantity: number;
  restaurantPaid: number;
};

type RestaurantsWithApplieds = {
  restaurant: IRestaurant | undefined;
  applieds: IApplied[];
};

const Applicators: React.FC<ApplicatorsProps> = ({ ...rest }) => {
  const [applicatorWithApplieds, setApplicatorWithApplieds] =
    useState<ApplicatorWithApplieds>();
  const { startDate, endDate, applicatorLogged, restaurants } = useAplication();
  const [restaurantsApplieds, setRestaurantsApplieds] = useState<
    RestaurantsWithApplieds[]
  >([]);

  useMemo(async () => {
    try {
      if ((!startDate && !endDate) || !applicatorLogged.applicator) return;

      const uri =
        startDate === '2024-04-15'
          ? `applied/pendings/${applicatorLogged.applicator.id}/${startDate}/${endDate}`
          : `applied/${applicatorLogged.applicator.id}/between/${startDate}/${endDate}`;

      const response = await api.get<IApplied[]>(uri);

      const data = {
        ...applicatorLogged.applicator,
        received: response.data
          .filter(a => a.applicatorPaid)
          .reduce((acc, item) => acc + item.calcs.enterprise.aplicador, 0),
        toReceive: response.data
          .filter(a => !a.applicatorPaid)
          .reduce((acc, item) => acc + item.calcs.enterprise.aplicador, 0),
        couponsQuantity: response.data.length,
        restaurantPaid: response.data
          .filter(a => a.received && !a.applicatorPaid)
          .reduce((acc, item) => acc + item.calcs.enterprise.aplicador, 0),
      };

      setApplicatorWithApplieds(data);

      const allRestaurantsIds = response.data.map(a => {
        return a.restaurantId;
      });

      const restaurantsIdsNotRepeat = allRestaurantsIds.filter(
        (item, i) => allRestaurantsIds.indexOf(item) === i,
      );

      const restaurantsAppliedsSearch = restaurantsIdsNotRepeat.map(id => ({
        restaurant: restaurants.find(r => r.id === id),
        applieds: response.data.filter(a => a.restaurantId === id),
      }));

      setRestaurantsApplieds(restaurantsAppliedsSearch);
    } catch (err) {
      alert(err);
    }
  }, [applicatorLogged.applicator, endDate, restaurants, startDate]);

  return (
    <S.Container {...rest}>
      <Period />

      {applicatorWithApplieds && (
        <S.ApplicatorContainer key={applicatorWithApplieds.id}>
          <InformationBlock
            uptitle={applicatorWithApplieds.name}
            img={applicatorWithApplieds.img}
            value={`R$ ${(
              applicatorWithApplieds.received + applicatorWithApplieds.toReceive
            ).toFixed(2)}`}
          />

          {applicatorWithApplieds.received > 0 && (
            <InformationBlock
              Icon={FiInbox}
              uptitle="Recebido"
              value={`R$ ${applicatorWithApplieds.received.toFixed(2)}`}
            />
          )}

          {applicatorWithApplieds.toReceive > 0 && (
            <InformationBlock
              Icon={FiClock}
              status="warning"
              uptitle="A receber"
              value={`R$ ${applicatorWithApplieds.toReceive.toFixed(2)}`}
            />
          )}

          {applicatorWithApplieds.restaurantPaid > 0 && (
            <InformationBlock
              status="success"
              Icon={FiDollarSign}
              uptitle="Liberado para pagamento"
              value={`R$ ${applicatorWithApplieds.restaurantPaid.toFixed(2)}`}
            />
          )}
        </S.ApplicatorContainer>
      )}

      {restaurantsApplieds.map(
        r =>
          r.restaurant && (
            <S.Divider key={r.restaurant.id}>
              <InformationBlock
                status={
                  r.applieds.filter(a => !a.received).length > 0
                    ? 'warning'
                    : 'default'
                }
                img={r.restaurant.img}
                uptitle={r.restaurant.name}
                value={`R$ ${r.applieds
                  .filter(a => !a.applicatorPaid)
                  .reduce(
                    (acc, item) => acc + item.calcs.enterprise.aplicador,
                    0,
                  )
                  .toFixed(2)}`}
              />
            </S.Divider>
          ),
      )}
    </S.Container>
  );
};

export default Applicators;
