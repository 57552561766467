import React from 'react';

import { ToastProvider } from './toast';
import { AplicationProvider } from './app';

const AppProvider: React.FC = ({ children }) => (
  <AplicationProvider>
    <ToastProvider>{children}</ToastProvider>
  </AplicationProvider>
);

export default AppProvider;
