import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  padding: 0 10px 40px;
`;

export const Divider = styled.div`
  display: flex;
  align-items: center;
  flex: 1;

  width: 100%;

  gap: 10px;
`;

export const ApplicatorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  gap: 10px;
  border-radius: 10px;

  width: 100%;
  padding: 10px;

  background-color: #ffffff20;
`;

export const Button = styled.button`
  border: 1px solid var(--primary);
  color: var(--primary);
  background-color: transparent;
  border-radius: 6px;
  height: 40px;
`;
