import React, { useCallback, useMemo, useState } from 'react';
import * as S from './styles';
import { IRestaurant } from '../../../DTOS/IRestaurant';
import { IApplicator } from '../../../DTOS/IApplicator';
import { useAplication } from '../../../hooks/app';
import { useToast } from '../../../hooks/toast';
import api from '../../../services/api';
import CircleProgress from '../../../components/CircleProgress';

type IRestaurantWithTotalOrders = IRestaurant & { totalOrders: number };

type ApplicatorsWithRestaurants = IApplicator & {
  restaurants: IRestaurantWithTotalOrders[];
  ordersByDayWeek: {
    seg: number;
    ter: number;
    qua: number;
    qui: number;
    sex: number;
    sab: number;
    dom: number;
  };
  totalOrders: number;
  applieds: number;
  percentApplieds: number;
};

function getTodayOrderCount(ordersByDayWeek: any): number {
  const daysOfWeekMap = ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sab'];
  const todayIndex = new Date().getDay(); // Retorna um número de 0 (domingo) a 6 (sábado)
  const todayKey = daysOfWeekMap[todayIndex]; // Mapeia o número para a chave correspondente

  return ordersByDayWeek[todayKey];
}

function getTodayKey(): keyof ApplicatorsWithRestaurants['ordersByDayWeek'] {
  const daysOfWeekMap: (keyof ApplicatorsWithRestaurants['ordersByDayWeek'])[] =
    ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sab'];
  const todayIndex = new Date().getDay();
  return daysOfWeekMap[todayIndex];
}

function sortApplicatorsByTodayOrders(
  applicators: ApplicatorsWithRestaurants[],
): ApplicatorsWithRestaurants[] {
  const todayKey = getTodayKey();
  return applicators.sort(
    (a, b) => a.ordersByDayWeek[todayKey] - b.ordersByDayWeek[todayKey],
  );
}

const Applicators: React.FC = () => {
  const [applicatorsWithRestaurants, setApplicatorsWithRestaurants] =
    useState<ApplicatorsWithRestaurants[]>();

  const { periodDates } = useAplication();

  const { addToast } = useToast();

  useMemo(async () => {
    if (!periodDates.today?.start) return;
    try {
      const response = await api.get<ApplicatorsWithRestaurants[]>(
        `applicator/applicationsWeek/${periodDates.today.start}/${periodDates.today.end}`,
      );

      const sortedApplicators = sortApplicatorsByTodayOrders(response.data);

      setApplicatorsWithRestaurants(sortedApplicators);
    } catch (error) {
      addToast({ title: 'Não foi possível buscar aplicadores', type: 'error' });
    }
  }, [addToast, periodDates]);

  const getPercentCount = useCallback((number: number, total: number): any => {
    const percent = (number * 100) / total;

    return percent > 100 ? 100 : percent.toFixed(0);
  }, []);

  return (
    <S.Container>
      {applicatorsWithRestaurants && (
        <S.ContentWrapper>
          <S.Header>
            <h1>Aplicados</h1>
          </S.Header>

          <S.Content>
            {applicatorsWithRestaurants.map(
              a =>
                a.name !== 'Iago Iann' &&
                a.name !== 'Igor Ryan' && (
                  <S.ApplicatorContainer key={a.id}>
                    <img src={a.img} alt="" />
                    <S.NumberInfo>
                      {`${a.applieds} de ${getTodayOrderCount(
                        a.ordersByDayWeek,
                      )}`}

                      <CircleProgress
                        percentage={getPercentCount(
                          a.applieds,
                          getTodayOrderCount(a.ordersByDayWeek),
                        )}
                        radius={7}
                        strokeWidth={2}
                        color="#66d37a"
                        trackColor="transparent"
                      />
                    </S.NumberInfo>
                  </S.ApplicatorContainer>
                ),
            )}
          </S.Content>
        </S.ContentWrapper>
      )}
    </S.Container>
  );
};

export default Applicators;
