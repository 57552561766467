import React, { useCallback, useEffect, useState } from 'react';
import { FiStar } from 'react-icons/fi';
import * as S from './styles';
import api from '../../../services/api';
import { useToast } from '../../../hooks/toast';
import { useAplication } from '../../../hooks/app';

type RatingCountProps = {
  name: string;
  total: number;
  percentRated: number;
  rated: number;
  pendings: number;
  percentPendings: number;
  missPercent: number;
}[];

const RatingsRobot: React.FC = () => {
  const [evaluatorsCount, setEvaluatorsCount] = useState<RatingCountProps>();
  const [robotRatingsStatus, setRobotRatingsStatus] = useState(false);
  const { periodDates } = useAplication();
  const { addToast } = useToast();

  const fetchEvaluationsCount = useCallback(async () => {
    if (!periodDates || Object.keys(periodDates).length === 0) return;

    try {
      const response = await api.post<RatingCountProps>('rating/count', {
        periodDates,
      });
      if (!response.data) return;

      const data = response.data.map(item => ({
        ...item,
        percentRated: (item.rated * 100) / item.total,
        percentPendings: (item.pendings * 100) / item.total,
        missPercent:
          ((item.total - item.rated - item.pendings) * 100) / item.total,
      }));
      setEvaluatorsCount(data);
    } catch (error) {
      addToast({
        title: 'Erro ao buscar contagem de avaliações',
        type: 'error',
      });
    }
  }, [periodDates, addToast]);

  const handleToggleStatusRobot = useCallback(
    async (name: string) => {
      try {
        const newStatus = await api.put(`/robot/${name}/turn`);
        setRobotRatingsStatus(newStatus.data.newStatus);
      } catch (error) {
        addToast({
          title: 'Não foi possível ligar/desligar robô.',
          type: 'error',
        });
      }
    },
    [addToast],
  );

  const fetchStatusRobots = useCallback(async () => {
    try {
      const [robotRatings] = await Promise.all([api.get('/robot/ratings')]);

      setRobotRatingsStatus(robotRatings.data.status);
    } catch (error) {
      addToast({ title: 'Erro ao buscar status dos robôs', type: 'error' });
    }
  }, [addToast]);

  useEffect(() => {
    fetchStatusRobots();
  }, [fetchStatusRobots]);

  useEffect(() => {
    fetchEvaluationsCount();

    const interval = setInterval(() => {
      fetchEvaluationsCount();
    }, 5000);
    return () => clearInterval(interval);
  }, [fetchEvaluationsCount]);

  return (
    <S.Container>
      {evaluatorsCount && (
        <S.ContentWrapper>
          <S.Header>
            <h1>Avaliações</h1>

            <S.OptionsWrapper>
              <S.ButtonOption
                styleButton="success"
                online={robotRatingsStatus}
                onClick={() => handleToggleStatusRobot('ratings')}
              >
                <FiStar size={20} />
              </S.ButtonOption>
            </S.OptionsWrapper>
          </S.Header>

          <S.GraphRating>
            {evaluatorsCount.map(
              item =>
                item.total > 0 && (
                  <S.RatingColumn key={item.name}>
                    <div
                      className="rated"
                      style={{ height: `${item.percentRated}%` }}
                    />
                    <div
                      className="pendings"
                      style={{ height: `${item.percentPendings}%` }}
                    />

                    <span className="name">{`${item.name}`}</span>

                    <div className="informations">
                      <span className="total">{`${item.total}`}</span>

                      {item.pendings > 0 ? (
                        <span className="pendingsNumber">
                          {`${item.percentPendings.toFixed(0)}%`}
                          <br />
                          {`${item.pendings}`}
                        </span>
                      ) : (
                        <span className="ratedNumber">
                          {`${item.percentRated.toFixed(0)}%`}
                          <br />
                          {`${item.rated}`}
                        </span>
                      )}

                      <span className="miss">
                        {`${item.missPercent.toFixed(0)}%`}
                        <br />
                        {`${item.total - item.pendings - item.rated}`}
                      </span>
                    </div>
                  </S.RatingColumn>
                ),
            )}
          </S.GraphRating>
        </S.ContentWrapper>
      )}
    </S.Container>
  );
};

export default RatingsRobot;
