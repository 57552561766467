import React from 'react';

import { Switch } from 'react-router-dom';

import Route from './Route';

import Checkin from '../pages/Checkin';
import Restaurant from '../pages/Restaurant';
import Emails from '../pages/Emails';
import Pack from '../pages/Emails/Pack';
import Coupons from '../pages/Coupons';
import Applieds from '../pages/Applieds';
import Financial from '../pages/Financial';
import Store from '../pages/Financial/Store';
import SignIn from '../pages/SignIn';
import Applicators from '../pages/Financial/Applicators';
import Evaluators from '../pages/Financial/Evaluators';
import Restaurants from '../pages/Financial/Restaurants';
import Applicator from '../pages/Applicator';
import Dashboard from '../pages/Dashboard';
import Owner from '../pages/Financial/Owner';
import Offer from '../pages/Offer';
import Marketing from '../pages/Marketing';
import MenuiFood from '../pages/MenuiFood';
import Buy from '../pages/Buy';
import LV from '../pages/LV';
import AccountsNeverUsed from '../pages/AccountsNeverUsed';

const Routes: React.FC = () => (
  <Switch>
    <Route path="/dashboard" component={Dashboard} />

    <Route path="/signin/:applicatorId" hasHeader={false} component={SignIn} />
    <Route path="/" exact component={Applicator} />

    <Route path="/financial" exact component={Financial} />
    <Route path="/financial/restaurants" component={Restaurants} />
    <Route path="/financial/applicators" component={Applicators} />
    <Route path="/financial/evaluators" component={Evaluators} />

    <Route path="/owner/:ownerId" hasHeader={false} component={Owner} />

    <Route path="/aplicador" exact component={Checkin} />
    <Route path="/restaurant/:restaurantId" component={Restaurant} />
    <Route path="/emails" component={Emails} />
    <Route path="/max/pack/:pack" component={Pack} />
    <Route path="/coupons" component={Coupons} />
    <Route path="/applieds" component={Applieds} />
    <Route path="/checkin" component={Checkin} />
    <Route path="/marketing" component={Marketing} />
    <Route path="/offer/:offerId" hasHeader={false} component={Offer} />

    <Route path="/cardapio" hasHeader={false} component={MenuiFood} />
    <Route path="/buy" hasHeader={false} component={Buy} />
    <Route path="/lv" hasHeader={false} component={LV} />
    <Route path="/never" hasHeader={false} component={AccountsNeverUsed} />

    <Route
      path="/store/:restaurantId/:period"
      hasHeader={false}
      component={Store}
    />
    <Route path="/store/:restaurantId/" hasHeader={false} component={Store} />
  </Switch>
);

export default Routes;
