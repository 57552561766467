import { IApplicator } from '../DTOS/IApplicator';

export const applicators: IApplicator[] = [
  {
    id: 'b024304a-633d-456c-855e-a527b4941fd2',
    name: 'Marcelo',
    img: 'https://i.ibb.co/G9GPp4V/imagem-2022-06-13-224501973.png',
    password: '7466',
    pix: '13618918666', // pago
  },
  {
    id: 'c348b20b-828a-43e5-a80b-e7fca73b882b',
    name: 'Thiago',
    img: 'https://i.ibb.co/3pdjr05/imagem-2022-06-13-225508894.png',
    password: '0983',
    pix: '31996405183', // pago
  },
  {
    id: 'bddd503e-e443-4bb6-8dc7-f3aa2707fb31',
    name: 'JonJon',
    img: 'https://i.ibb.co/4VvH2bd/imagem-2022-06-14-134658914.png',
    password: '8726',
    pix: 'jonatas167@gmail.com',
  },
  {
    id: '9b9043ce-6d09-4338-b6a3-baad2fddb2ce',
    name: 'Joney',
    img: 'https://i.ibb.co/99dk85f/imagem-2022-06-14-135134675.png',
    password: '9832',
    pix: '31998754650', // pago
  },
  {
    id: '57b9700a-06c9-4786-abb1-1951455994e6',
    name: 'Jonathan',
    img: 'https://i.ibb.co/hZMY3Yf/imagem-2022-06-13-230919126.png',
    password: '7651',
    pix: '12426279605',
  },
  {
    id: 'f140f743-5b05-4b41-b729-438d1f13a7f5',
    name: 'Vinicius',
    img: 'https://i.ibb.co/H4DCf9Z/imagem-2022-06-23-115751923.png',
    password: '3221',
    pix: '12371167673',
  },
  {
    id: '7f469284-a32b-4213-8b5d-35ad07eb075e',
    name: 'Guilherme',
    img: 'https://i.ibb.co/hYYbjhJ/imagem-2022-06-29-100849590.png',
    password: '8762',
    pix: 'matoslacerda.gg@gmail.com',
  },
  {
    id: 'd45264c1-65fb-46ac-af58-da06ec744c44',
    name: 'Ruan',
    img: 'https://i.ibb.co/cD735mM/Captura-de-Tela-2024-03-15-a-s-09-33-12.png',
    password: 'tanajura',
    pix: '12241056642',
  },
  {
    id: 'cd193ce2-cd0d-4569-9aa4-cf7fbf3f2a8d',
    name: 'Jomber',
    img: 'https://i.ibb.co/wQfmjww/imagem-2022-06-14-130255472.png',
    password: '0998',
    pix: '13116919607',
  },
  {
    id: '4e602774-9a9f-4c82-b537-a54e2eacb61e',
    name: 'Ana',
    img: 'https://i.ibb.co/d6WBTf5/imagem-2022-06-14-131543062.png',
    password: 'djbruninho',
    pix: '13371744671',
  },
  {
    id: 'c433be61-bdc4-4140-b64e-2c9e115d2a00',
    img: 'https://i.ibb.co/FJ2yBtL/Captura-de-Tela-2023-05-06-a-s-17-18-55.png',
    name: 'Antoine',
    password: '8726',
    pix: '70026574616', // pago
  },
  {
    id: 'a12efb33-adde-40c1-bcad-ef983b61c054',
    img: 'https://www.thepinknews.com/wp-content/uploads/2019/11/tinky-winky.jpg',
    name: 'Lorena',
    password: 'igorlindo',
    pix: '31995534739',
  },
  {
    id: '59e3d1de-41f6-4e6f-a64e-c138550d2a1a',
    img: 'https://i.ibb.co/r7sh3mb/Captura-de-Tela-2024-02-22-a-s-15-10-26.png',
    name: 'Priscila',
    password: '123456',
    pix: '15541933625',
    // 100
  },
  {
    id: '670fe58c-f3de-4bd1-8fda-e6bc1566f43f',
    img: 'https://i.ibb.co/YcNsWPP/Captura-de-tela-2024-04-22-153716.png',
    name: 'Stanley',
    password: '8732',
    pix: '05253525629',
  },
  {
    id: '1ce534fe-a959-4300-ac30-3a58e88982f7',
    img: 'https://i.ibb.co/Vt2XBT6/Captura-de-Tela-2024-04-29-a-s-15-07-18.png',
    name: 'Maria Clara',
    password: '9996',
    pix: 'mariaclararochaferreira@hotmail.com',
  },
  {
    id: '220446c0-4786-4908-b0fe-b106a4829874',
    img: 'https://i.ibb.co/yBBxFWv/Captura-de-Tela-2024-08-03-a-s-22-26-28.png',
    name: 'Igor Ryan',
    password: '1111',
    pix: '11172587698',
  },
  {
    id: 'c5844245-06f5-4ebd-b00c-8db6dafb48a4',
    img: 'https://i.ibb.co/B2Sqqtt/esther.png',
    name: 'Iago Iann',
    password: '1111',
    pix: '11172589631',
  },
  {
    id: '43f1147f-5b68-4d7a-bd03-cf22ec8a5030',
    img: 'https://i.ibb.co/bgL1ZgQ/Captura-de-Tela-2024-08-19-a-s-13-45-50.png',
    name: 'Raquel Brant',
    password: '1111',
    pix: '13728550680', // pago
  },
  {
    id: '30c5ee52-705f-471f-99d3-8aa1a266305c',
    img: 'https://i.ibb.co/4ZMc4XF/Captura-de-Tela-2024-08-27-a-s-11-15-09.png',
    name: 'Manoela Brant',
    password: '1111',
    pix: '31971328924', // pago
  },
];

// {
//   applicatorId:
//     "30c5ee52-705f-471f-99d3-8aa1a266305c",
//   lastMessage: null,
//   hasWhatsApp: true
// }
