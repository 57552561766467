import styled, { css } from 'styled-components';
import { FiTrash } from 'react-icons/fi';
import { IStatusProps } from '../../../DTOS/IEmail';

type IStatus = {
  status: IStatusProps;
};

type IIndexProps = {
  hasCookies: boolean;
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-bottom: 20px;
`;

export const EmailContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  gap: 12px;

  > div {
    display: flex;
    gap: 12px;
    align-items: center;
  }

  background-color: var(--container);
  border-radius: 50px;
  padding: 8px 10px;
`;

export const Index = styled.span<IIndexProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: var(--primary20);

  border-radius: 50%;

  width: 30px;
  height: 30px;

  font-size: 12px;

  position: relative;

  ${props =>
    props.hasCookies &&
    css`
      background-color: #21dab8;
      color: #000;
      cursor: pointer;
    `}
`;

export const Icon = styled(FiTrash)`
  position: absolute;
  right: -20px;

  cursor: pointer;

  color: #21dab840;
`;

export const Email = styled.span`
  font-size: 12px;

  word-break: break-all;
  display: block;

  cursor: pointer;

  padding: 10px 0;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StatusContainer = styled.div<IStatus>`
  display: flex;
  align-items: center;

  padding: 8px 12px 8px 8px;
  border-radius: 50px;

  text-align: center;

  background-color: #30303070;

  min-width: 140px;

  cursor: pointer;

  position: relative;

  ${props =>
    props.status === 'Verificar' &&
    css`
      background-color: #db690020;
      color: #db6900;
    `}

  ${props =>
    props.status === 'Tudo certo' &&
    css`
      background-color: #13b49720;
      color: #21dab8;
    `}

  ${props =>
    props.status === 'Pediu número' &&
    css`
      background-color: #c5a50020;
      color: #c5a500;
    `}


  ${props =>
    (props.status === 'Email perdido' || props.status === 'iFood banido') &&
    css`
      background-color: var(--danger);
    `}
`;

export const StatusOptionsDropDown = styled.div`
  position: absolute;

  bottom: -300px;

  z-index: 1;

  border: 1px solid #ffffff20;

  border-radius: 10px;

  overflow: hidden;

  width: 100%;
`;

export const StatusOption = styled.div`
  font-size: 14px;

  background-color: #151515;
  width: 100%;

  text-align: center;

  padding: 10px;

  border-radius: 6px;

  &:hover {
    background-color: #303030;
  }
`;

export const CouponsOptions = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  background-color: #151515;

  padding: 8px 4px;
`;
export const CouponOption = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #151515;

  border-radius: 4px;
  font-size: 14px;

  &:hover {
    background-color: #21dab8;
    color: #000;
  }
`;

export const LoginTypeImg = styled.img`
  width: 20px;
  height: 20px;
  border-radius: 50%;
`;

export const Status = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
`;

export const ImgApplicator = styled.img`
  width: 20px;
  height: 20px;

  border-radius: 50%;

  position: absolute;

  left: -20px;
`;

export const StatusText = styled.span`
  font-size: 12px;
`;
