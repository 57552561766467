import React, { HTMLAttributes } from 'react';
import * as S from './styles';

type Props = HTMLAttributes<HTMLDivElement> & {
  active: boolean;
  setActive(active: boolean): void;
};

const CheckboxSlider: React.FC<Props> = ({ active, setActive }) => {
  return (
    <S.Container>
      <label className="switch" htmlFor="checkbox">
        <input
          onClick={() => setActive(!active)}
          type="checkbox"
          checked={active}
          onChange={e => setActive(!e.target.checked)}
          id="checkbox"
        />
        <div className="slider round" />
      </label>
    </S.Container>
  );
};

export default CheckboxSlider;
