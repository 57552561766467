import styled from 'styled-components';
import mockImg from '../../assets/mockiphone.png';

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 80px;
  height: 100vh;
  overflow: hidden;
`;

export const Mock = styled.div`
  background-image: url(${mockImg});
  width: 350px;
  min-height: 700px;
  background-size: cover;
  background-position: center;

  padding: 28px 26px 0;

  border-top-right-radius: 10px;
  border-top-left-radius: 10px;

  transform: scale(1.2);
`;

export const ContentContainer = styled.div`
  width: 100%;
  height: 645px;
`;

export const PhoneContent = styled.div`
  width: 100%;
  height: 100%;

  border-radius: 38px;
  overflow-x: scroll; /* Permite rolagem horizontal */
  overflow-y: hidden; /* Remove rolagem vertical */
  background-color: #fff;

  /* Opcional: Remove a barra de rolagem no navegador */
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`;
export const CoverContent = styled.div`
  height: 124px;
`;

export const Cover = styled.img`
  width: 100%;
  height: 124px;
  object-fit: cover;
`;

export const Section = styled.div`
  height: 100%;
  padding: 0 10px;
  position: relative;
`;
