import styled, { css } from 'styled-components';

type IProgress = {
  progress: number;
};

type IActive = {
  active?: boolean;
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--container);

  border-radius: 10px;
  overflow: hidden;

  position: relative;
`;

export const CEO = styled.img`
  position: absolute;
  top: 20px;
  right: 20px;

  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;

  z-index: 1;
`;

export const Cover = styled.img`
  width: 100%;
  height: 100%;

  position: absolute;

  object-fit: cover;

  filter: blur(120px);
`;

export const Logo = styled.img`
  width: 80px;
  height: 80px;

  border-radius: 14px;

  object-fit: cover;

  margin-bottom: 24px;
  margin-top: 20px;

  z-index: 1;
`;

export const Name = styled.h3`
  font-weight: 700;
  font-size: 20px;

  text-align: center;

  padding: 0 20px;

  transition: 0.2s ease;

  &:hover {
    transform: scale(1.1);
  }
`;

export const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  gap: 10px;
`;

export const WhatsApp = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 1;

  bottom: 26px;
  right: 20px;

  cursor: pointer;

  img {
    cursor: pointer;

    transition: 0.2s ease;

    &:hover {
      transform: scale(1.1);
    }

    width: 20px;
    height: 20px;
  }
`;

export const Description = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  vertical-align: center;

  cursor: pointer;

  font-size: 14px;
  padding: 0 40px;
  text-align: center;

  color: #ffffff;

  z-index: 1;

  margin-top: 24px;
`;

export const Relatory = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 20px;

  color: #ffffff80;

  span {
    margin-left: 4px;
  }
`;

export const Informations = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  flex-wrap: wrap;

  margin-top: 24px;

  gap: 10px;
`;

export const Information = styled.div<IActive>`
  font-size: 14px;

  cursor: pointer;

  background-color: #15151550;

  max-width: max-content;

  padding: 2px 8px;

  border-radius: 6px;
  z-index: 1;

  ${props =>
    props.active &&
    css`
      background-color: var(--primary);
    `}
`;

export const Title = styled.div`
  text-align: center;
  font-weight: 700;
  font-size: 12px;
`;

export const Value = styled.div`
  text-align: center;
`;

export const InputChangeOrderQuantity = styled.input`
  background-color: var(--background);
  color: #fff;

  border-radius: 8px;

  text-align: center;

  margin-top: 10px;
  z-index: 1;
`;

export const InputChangeOrderButton = styled.button`
  background-color: var(--background);
  color: #fff;

  border-radius: 8px;

  padding: 10px 30px;

  text-align: center;

  margin-top: 10px;
  z-index: 1;
`;

export const ProgressContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 4px;
  width: 100%;
  margin-top: 40px;
`;

export const ProgressBar = styled.div`
  width: 100%;
  height: 10px;
  background-color: #ffffff10;
`;

export const Progress = styled.div<IProgress>`
  width: 0%;
  height: 10px;

  border-radius: 0 50px 50px 0;
  position: relative;

  transition: 1s ease;

  ${props =>
    props.progress > 0 &&
    css`
      width: ${props.progress > 100 ? 100 : props.progress}%;
      background-color: ${props.progress < 50 && '#E1B000'};
      background-color: ${props.progress >= 50 &&
      props.progress <= 100 &&
      '#00dfb7'};
      background-color: ${props.progress > 100 && '#E1001B'};
    `}
`;

export const PercentText = styled.span<IProgress>`
  color: #00dfb7;
  font-size: 14px;

  position: absolute;

  top: -24px;
  right: 0;

  margin-right: 6px;

  ${props =>
    props.progress > 0 &&
    css`
      color: ${props.progress < 50 && '#E1B000'};
      color: ${props.progress >= 50 && props.progress <= 100 && '#00dfb7'};
      color: ${props.progress > 100 && '#E1001B'};
    `}
`;

export const SubText = styled.span`
  position: absolute;
  bottom: -24px;
  right: 0;

  font-size: 12px;
  color: #ffffff30;

  margin-right: 6px;
`;

export const ApplicationsInfo = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;

  cursor: pointer;
`;

export const Total = styled.span<IProgress>`
  padding: 4px 10px;
  font-size: 14px;
  background-color: var(--content);
  border-radius: 8px;

  ${props =>
    props.progress > 0 &&
    css`
      color: ${props.progress < 50 && '#E1B000'};
      color: ${props.progress >= 50 && props.progress <= 100 && '#00dfb7'};
      color: ${props.progress > 100 && '#E1001B'};
    `}
`;
