import React, { useCallback, useEffect, useState } from 'react';
import * as S from './styles';
import EmailCountPanel, {
  CountProps,
} from '../../../components/EmailCountPanel';
import api from '../../../services/api';
import { useToast } from '../../../hooks/toast';

const emailTypes = [
  { name: 'Tudo', color: '#c0c0c0' },
  { name: 'Outlook', color: '#00bdc9' },
  { name: 'Maxlife', color: '#E52222' },
  { name: 'NP', color: '#E5C722' },
];

const EmailsStatus: React.FC = () => {
  const [count, setCount] = useState<CountProps>();
  const [typeActive, setTypeActive] = useState(emailTypes[0].name);
  const { addToast } = useToast();

  const fetchEmailCount = useCallback(async () => {
    try {
      const response = await api.get<CountProps>(`email/count/${typeActive}`);
      setCount(response.data);
    } catch (error) {
      addToast({ title: 'Erro ao buscar contagem de emails', type: 'error' });
    }
  }, [typeActive, addToast]);

  useEffect(() => {
    fetchEmailCount();
  }, [fetchEmailCount]);

  return (
    <S.Container>
      {count && (
        <S.ContentWrapper>
          <S.Header>
            <h1>Emails</h1>
          </S.Header>

          <S.EmailTypeContainer>
            {emailTypes.map(e => (
              <S.EmailType
                onClick={() => setTypeActive(e.name)}
                key={e.color}
                active={typeActive === e.name}
                color={e.color}
              >
                {e.name}
              </S.EmailType>
            ))}
          </S.EmailTypeContainer>
          <EmailCountPanel count={count} />
        </S.ContentWrapper>
      )}
    </S.Container>
  );
};

export default EmailsStatus;
