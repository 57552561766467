import styled, { css } from 'styled-components';

type IEmailProps = {
  selected?: boolean;
};

type RefreshProps = {
  refresh: boolean;
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 200px;

  gap: 8px;

  margin-right: 10px;

  width: 100%;

  position: relative;
`;

export const PanelOfCoupons = styled.div`
  background-color: var(--container);
  border-radius: 6px;
  flex: 1;
  min-width: 100px;

  position: relative;

  cursor: pointer;

  img {
    width: 100%;
    height: 100px;

    object-fit: cover;

    border-radius: 6px;
  }
`;

export const Received = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;

  padding-bottom: 2px;

  gap: 10px;

  div {
    display: flex;
    flex-direction: column;
    align-items: center;

    span {
      font-size: 12px;
      margin-top: 4px;
    }

    &:nth-of-type(1) {
      color: #b0b0b0;
    }

    &:nth-of-type(2) {
      color: #d3cb0a;
    }

    &:nth-of-type(3) {
      color: #00d930;
    }
  }
`;

export const TotalApplications = styled.span`
  position: absolute;
  background-color: var(--content);

  padding: 4px 10px;

  border-radius: 4px;

  font-size: 12px;

  bottom: 48px;
  left: 4px;

  color: var(--primary);
`;

export const Money = styled.span`
  position: absolute;
  background-color: var(--content);

  padding: 4px 10px;

  border-radius: 4px;

  font-size: 12px;

  bottom: 48px;
  right: 4px;

  color: var(--primary);
`;

export const ApplicatorContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

export const PanelInformation = styled.div`
  .title {
  }

  .value {
  }
`;

export const Applicators = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

export const CouponsQuantity = styled.div<RefreshProps>`
  width: 100%;
  height: 50px;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #2f98c650;
  border: 1px solid #2f98c6;

  transition: 0.2s ease;

  ${props =>
    props.refresh &&
    css`
      background-color: var(--alert);
    `}

  border-radius: 12px;

  font-weight: 700;

  margin-bottom: 10px;
`;

export const SelectionButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;

  width: 100%;

  gap: 10px;

  margin-bottom: 10px;

  > button {
    border-radius: 100px;

    border: 1px solid #2f98c6;

    color: #2f98c6;
    background-color: transparent;

    width: 100%;

    font-size: 14px;

    padding: 12px 0%;

    transition: 0.2s ease;

    &:hover {
      background-color: #0a7360;
      color: white;
    }
  }
`;

export const Hour = styled.div`
  font-size: 12px;
  color: #ffffff70;
`;

export const EmailContainer = styled.div<IEmailProps>`
  display: flex;
  align-items: center;

  cursor: pointer;

  padding: 8px 12px;

  border: 1px solid transparent;
  gap: 18px;

  width: 100%;

  ${props =>
    props.selected &&
    css`
      border: 1px solid var(--primary);
    `}

  background-color: var(--container);
  border-radius: 10px;

  > div {
    display: flex;
    flex-direction: column;

    width: 100%;
  }
`;

export const Checkbox = styled.button<IEmailProps>`
  background-color: transparent;
  border: 1px solid white;
  outline: none;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  min-width: 15px;
  min-height: 15px;

  border-radius: 50%;

  ${props =>
    props.selected &&
    css`
      border: 1px solid var(--primary);
      background-color: var(--primary);
    `}
`;

export const Index = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #ffffff10;

  border-radius: 12px;

  height: 30px;
  width: 140px;

  font-size: 12px;

  color: #ffffff80;
`;

export const Email = styled.span`
  font-size: 12px;

  word-break: break-all;
  display: block;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Status = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
`;

export const MoneyValue = styled.span`
  font-size: 14px;
  font-weight: 600;
  width: 40px;
`;

export const CouponsTotal = styled.div`
  position: absolute;
  top: 5px;
  left: 10px;

  width: max-content;
  text-align: center;

  border-radius: 6px;

  background-color: var(--container);

  span {
    font-size: 12px;
  }
`;
