import { IApplicator } from '../DTOS/IApplicator';
// import Marcia from '../assets/evaluators/marcia.png';
// import Bruno from '../assets/evaluators/bruno.png';
// import Witer from '../assets/evaluators/witer.png';
import Igor from '../assets/igor.png';

export const evaluators: IApplicator[] = [
  {
    id: 'Robot',
    name: 'Igor',
    img: Igor,
    password: '123456',
    pix: '11172587698',
  },
  // {
  //   id: '03259931-357a-414d-aafb-5209e96cbfe0',
  //   name: 'Maria Clara',
  //   img: 'https://i.ibb.co/Vt2XBT6/Captura-de-Tela-2024-04-29-a-s-15-07-18.png',
  //   password: '123456',
  //   pix: 'mariaclararochaferreira@hotmail.com',
  // },
  // {
  //   id: '33e46834-0820-4840-a923-c9e31921102a',
  //   name: 'Witer',
  //   img: Witer,
  //   password: '123456',
  //   pix: '31999585625',
  // },
  // {
  //   id: 'f69a94b6-d7fa-46ad-bcb3-735e206c1c1b',
  //   name: 'Bruno',
  //   img: Bruno,
  //   password: '123456',
  //   pix: '12280506602',
  // },
  // {
  //   id: 'b024304a-633d-456c-855e-a527b49421232',
  //   name: 'Priscila',
  //   img: 'https://i.ibb.co/r7sh3mb/Captura-de-Tela-2024-02-22-a-s-15-10-26.png',
  //   password: '123456',
  //   pix: '15541933625',
  // },
  // {
  //   id: '8c3cbec0-5b79-40b1-91bc-9eed427f15ec',
  //   name: 'Marcia',
  //   img: Marcia,
  //   password: '123456',
  //   pix: '31988372834',
  // },
  // {
  //   id: 'eb3226f6-f3bb-4f02-b4c8-37785d72c54c',
  //   name: 'Jomber',
  //   img: 'https://i.ibb.co/wQfmjww/imagem-2022-06-14-130255472.png',
  //   password: '123456',
  //   pix: 'jombervictor2013@gmail.com',
  // },
  // {
  //   id: '4bfc29c9-9c10-4dbf-993c-35fad9c986df',
  //   name: 'Manoela',
  //   img: 'https://i.ibb.co/mJr63J4/Captura-de-Tela-2024-05-13-a-s-14-12-47.png',
  //   password: '123456',
  //   pix: '31971328924',
  // },
  // {
  //   id: 'bf5253f8-546e-432e-a10b-d480be66e875',
  //   name: 'Jociara',
  //   img: 'https://i.ibb.co/88CVYq5/Captura-de-Tela-2024-05-13-a-s-14-15-02.png',
  //   password: '123456',
  //   pix: '31998230058',
  // },
  // {
  //   id: 'bf5253f8-546e-432e-a10b-d480be6321sa',
  //   name: 'Bruna',
  //   img: 'https://i.ibb.co/JmYdwbK/Captura-de-Tela-2024-05-24-a-s-20-45-53.png',
  //   password: '123456',
  //   pix: '31997086439',
  // },
];
