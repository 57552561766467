import styled from 'styled-components';
import { FiCopy } from 'react-icons/fi';

export const Container = styled.div`
  cursor: pointer;
  width: 100%;
`;

export const Icon = styled(FiCopy)`
  transition: 0.2s;
`;
