import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as S from './styles';
import Movie from '../../assets/oferta.mp4';
import api from '../../services/api';

type IOfferParams = {
  offerId: string;
};

type IOffer = {
  id: string;
  viewed: boolean;
};

const Offer: React.FC = () => {
  const { offerId } = useParams<IOfferParams>();
  const [viewed, setViewed] = useState(true);

  const auth = useCallback(async () => {
    const response = await api.get<IOffer>(`offer/${offerId}`);

    if (response.data.viewed === true) {
      setViewed(true);
    } else {
      setViewed(false);
    }

    await api.put(`offer/${offerId}`);
  }, [offerId]);

  useEffect(() => {
    auth();
  }, [auth]);

  return (
    <S.Container>
      {!viewed ? (
        <>
          <div>
            <video controls width="100%">
              <source src={Movie} type="video/mp4" />
              Seu navegador não suporta o elemento de vídeo.
            </video>
          </div>
        </>
      ) : (
        <div style={{ marginBottom: 10 }}>Video assistido!</div>
      )}

      <S.Button href="https://api.whatsapp.com/send?phone=5531989551995">
        Chamar no WhatsApp
      </S.Button>
    </S.Container>
  );
};

export default Offer;
