import React, { useRef, useCallback, useState, useEffect } from 'react';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';

// Images & icons
import { FiLock } from 'react-icons/fi';
import { useHistory, useParams } from 'react-router-dom';
import * as S from './styles';
import logoImg from '../../assets/logo.png';

// Hooks
import { useToast } from '../../hooks/toast';

// Components & styles
import { Container } from './styles';
import Input from '../../components/Input';
import Button from '../../components/Button';

// Services
import getValidationErrors from '../../utils/getValidationErrors';
import { IApplicator } from '../../DTOS/IApplicator';
import { applicators } from '../../db/applicators';
import { useAplication } from '../../hooks/app';

// Interfaces
interface SignInFormData {
  password: string;
}

interface IParams {
  applicatorId: string;
}

const SignIn: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { applicatorLogged } = useAplication();
  const { addToast } = useToast();
  const { push } = useHistory();
  const [applicatorSelected, setApplicatorSelected] = useState<string>('');
  const [hasPermission, setHasPermission] = useState(false);
  const [permissionTimes, setPermissionTimes] = useState(0);
  const [applicator, setApplicator] = useState<IApplicator>();
  const { applicatorId } = useParams<IParams>();

  useEffect(() => {
    setApplicator(applicators.find(app => app.id === applicatorId));
  }, [applicatorId]);

  useEffect(() => {
    const applicatorFound = applicators.find(a => a.id === applicatorId);

    if (!applicatorFound) return;
    setApplicator(applicatorFound);
    setApplicatorSelected(applicatorFound.id);
  }, [applicatorId]);

  const addPermissionTimes = useCallback(() => {
    setPermissionTimes(oldState => oldState + 1);
  }, []);

  const changePermission = useCallback(() => {
    setHasPermission(true);
  }, []);

  useEffect(() => {
    if (permissionTimes > 2) {
      changePermission();
    }
  }, [changePermission, permissionTimes]);

  const handleSubmit = useCallback(
    async (data: SignInFormData) => {
      try {
        if (data.password === 'malfeitofeito') {
          addToast({
            type: 'success',
            title: 'Bem vindo!',
          });

          localStorage.setItem(
            `7557eb6f-8366-4203-8917-075fbf5f001f`,
            JSON.stringify(true),
          );
          push('/dashboard');
          return;
        }

        if (data.password === 'imryan') {
          addToast({
            type: 'success',
            title: 'Bem vindo!',
          });

          localStorage.setItem(
            `a76e8fff-d247-4e7b-8143-2f61a9bf4e7d`,
            JSON.stringify(true),
          );
          push('/dashboard');
          return;
        }

        if (data.password === 'ok') {
          localStorage.setItem(
            `71c66b70-3f7d-4236-ad00-76510ba0edb7`,
            applicatorSelected,
          );

          addToast({
            type: 'success',
            title: 'Bem vindo!',
          });
          push(`/`);

          return;
        }

        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          password: Yup.string().required('Senha obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        if (!applicatorSelected) {
          addToast({
            type: 'error',
            title: 'Selecione um aplicador',
          });
          return;
        }

        if (data.password !== applicator?.password) {
          addToast({
            type: 'error',
            title: 'Senha inválida',
            description:
              'Ocorreu um erro ao fazer login, cheque as credenciais',
          });

          return;
        }

        localStorage.setItem(
          `71c66b70-3f7d-4236-ad00-76510ba0edb7`,
          applicatorSelected,
        );

        applicatorLogged.setApplicatorLogged(applicator);
        push(`/`);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro na autenticação',
          description: 'Ocorreu um erro ao fazer login, cheque as credenciais',
        });
      }
    },
    [addToast, applicator, applicatorLogged, applicatorSelected, push],
  );

  return (
    <Container
      style={{
        backgroundImage: `url(https://images.unsplash.com/photo-1605296867304-46d5465a13f1?q=80&w=1170&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)`,
      }}
    >
      {hasPermission ? (
        <>
          <img src={applicator?.img ? applicator.img : logoImg} alt="iFood" />

          <Form ref={formRef} onSubmit={handleSubmit} action="">
            <Input
              style={{ color: '#13b497' }}
              name="password"
              icon={FiLock}
              type="password"
              placeholder="Senha"
            />
            <Button type="submit">Entrar</Button>
          </Form>
        </>
      ) : (
        <S.Background>
          <h1>Comece a Transformar Sua Vida Hoje</h1>

          <p className="description">
            O treino físico pode regular sua vida. Descubra os inúmeros
            benefícios para sua{' '}
            <span onClick={() => addPermissionTimes()}>saúde</span> física e
            mental ao aderir a um programa de treinos.
          </p>

          <a href="https://www.lifefitness.com/pt-br" className="donate-button">
            Solicitar plano de treino
          </a>
        </S.Background>
      )}
    </Container>
  );
};

export default SignIn;
