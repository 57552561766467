import React from 'react';

interface CircleProgressProps {
  percentage: number;
  radius?: number;
  strokeWidth?: number;
  color?: string;
  trackColor?: string;
}

const CircleProgress: React.FC<CircleProgressProps> = ({
  percentage,
  radius = 50,
  strokeWidth = 10,
  color = 'tomato',
  trackColor = 'lightgray',
}) => {
  const normalizedRadius = radius - strokeWidth / 2;
  const circumference = 2 * Math.PI * normalizedRadius;
  const strokeDashoffset = circumference * (1 - percentage / 100);

  return (
    <svg height={radius * 2} width={radius * 2}>
      {/* Círculo de fundo (track) */}
      <circle
        stroke={trackColor}
        fill="transparent"
        strokeWidth={strokeWidth}
        r={normalizedRadius}
        cx={radius}
        cy={radius}
      />
      {/* Círculo de progresso */}
      <circle
        stroke={color}
        fill="transparent"
        strokeWidth={strokeWidth}
        strokeDasharray={circumference}
        strokeDashoffset={strokeDashoffset || 0}
        r={normalizedRadius}
        cx={radius}
        cy={radius}
        style={{
          transition: 'stroke-dashoffset 0.5s ease',
          transformOrigin: 'center',
          transform: 'rotate(-90deg)',
        }}
      />
      {/* Texto de percentual */}
      {/* <text
        x="50%"
        y="50%"
        dominantBaseline="middle"
        fill="#ffffff"
        textAnchor="middle"
        fontSize="8px"
      >
        {`${percentage}%`}
      </text> */}
    </svg>
  );
};

export default CircleProgress;
