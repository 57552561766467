import React, {
  HTMLAttributes,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useParams } from 'react-router-dom';
import { FiKey, FiMap } from 'react-icons/fi';
import * as S from './styles';
import api from '../../services/api';
import { useToast } from '../../hooks/toast';
import { IRestaurant } from '../../DTOS/IRestaurant';
import Banner from './components/Banner';
import CopyItem from './components/CopyItem';
import Coupons from './components/Coupons';

type RestaurantProps = HTMLAttributes<HTMLDivElement>;

type ParamsProps = {
  restaurantId: string;
};

const Restaurant: React.FC<RestaurantProps> = ({ ...rest }) => {
  const { restaurantId } = useParams<ParamsProps>();
  const { addToast } = useToast();

  const [restaurant, setRestaurant] = useState<IRestaurant>();

  const [address, setAddress] = useState('');
  const [addresses, setAddresses] = useState<string[]>([]);

  const getAddresses = useCallback(
    async (addressString: string) => {
      if (!restaurant) return;

      console.log(restaurant.id);

      if (restaurant.addresses && restaurant.addresses.length > 100) {
        setAddresses(restaurant.addresses);
        return;
      }

      try {
        const addressesFound = await api.get<{ addresses: string[] }>(
          `applicator/address/restaurant/${restaurant.id}/address/${addressString}/`,
        );

        setAddresses(addressesFound.data.addresses);
      } catch (err) {
        addToast({
          title: 'Erro ao buscar restaurante',
        });
      }
    },
    [addToast, restaurant],
  );

  useEffect(() => {
    if (!restaurant?.address) return;

    getAddresses(restaurant.address);
  }, [getAddresses, restaurant]);

  useMemo(async () => {
    try {
      const restaurantFound = await api.get<IRestaurant>(
        `restaurant/${restaurantId}`,
      );

      if (!restaurantFound) {
        return;
      }

      setRestaurant(restaurantFound.data);
    } catch (err) {
      addToast({
        title: 'Erro ao buscar restaurante',
      });
    }
  }, [addToast, restaurantId]);

  return (
    <S.Container {...rest}>
      {restaurant && restaurant.status === 'Online' && (
        <>
          <Banner restaurant={restaurant} />

          <S.CopyButtons>
            <CopyItem
              onClick={() =>
                setAddress(
                  addresses[Math.floor(Math.random() * addresses.length)],
                )
              }
              Icon={FiMap}
              label="Endereço"
              text={address}
            />

            <CopyItem Icon={FiKey} label="Key" text={restaurant.key} />
          </S.CopyButtons>

          <Coupons restaurantId={restaurant.id} />
        </>
      )}

      {restaurant && restaurant.status === 'Atacar' && (
        <>
          <Banner style={{ backgroundColor: 'red' }} restaurant={restaurant} />

          <S.CopyButtons>
            <CopyItem
              style={{ backgroundColor: 'red' }}
              onClick={() =>
                setAddress(
                  addresses[Math.floor(Math.random() * addresses.length)],
                )
              }
              Icon={FiMap}
              label="Endereço"
              text={address}
            />

            <CopyItem
              style={{ backgroundColor: 'red' }}
              Icon={FiKey}
              label="Key"
              text={restaurant.key}
            />
          </S.CopyButtons>

          <Coupons restaurantId={restaurant.id} />
        </>
      )}

      {restaurant && restaurant.status === 'Devendo' && (
        <>
          <S.Message>Cliente desativado por pendências financeiras.</S.Message>

          <Banner restaurant={restaurant} />
        </>
      )}
    </S.Container>
  );
};

export default Restaurant;
