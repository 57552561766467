import styled, { css } from 'styled-components';

type EmailProps = {
  color: string;
  active?: boolean;
};

export const Container = styled.div``;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  height: max-content;

  gap: 18px;

  padding: 14px 20px;
  padding-bottom: 22px;

  background-color: #141414;
  border-radius: 20px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  h1 {
    font-size: 18px;
  }
`;

export const EmailTypeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  gap: 10px;

  border-radius: 10px;
  margin: 10px 0;
`;

export const EmailType = styled.div<EmailProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  width: 100%;
  height: 60px;

  border-radius: 10px;
  opacity: 0.3;
  font-weight: 400;
  font-size: 14px;

  color: var(--primary);
  border: 1px solid var(--primary);

  ${props =>
    props.active &&
    css`
      background-color: var(--primary20);
      border: 1px solid var(--primary);
      opacity: 1;

      color: var(--primary);
    `}
`;
