import React, { HTMLAttributes, useCallback, useMemo, useState } from 'react';

import { FiClock, FiDollarSign, FiInbox } from 'react-icons/fi';
import copy from 'copy-to-clipboard';
import * as S from './styles';
import InformationBlock from '../../../components/InformationBlock';
import { applicators } from '../../../db/applicators';
import api from '../../../services/api';
import { IApplied } from '../../../DTOS/IApplied';
import { IApplicator } from '../../../DTOS/IApplicator';
import { useAplication } from '../../../hooks/app';
import { useToast } from '../../../hooks/toast';
import Period from '../../../components/Period';
import { toMoney } from '../../../utils/toMoney';

type ApplicatorsProps = HTMLAttributes<HTMLDivElement>;

type ApplicatorWithApplieds = IApplicator & {
  received: number;
  toReceive: number;
  couponsQuantity: number;
  restaurantPaid: number;
  applieds: IApplied[];
};

export type IGetApplicatorCalcs = {
  total: number;
  restaurantPaid: number;
  applicatorPaid: number;
};

export type IApplicatorCalcs = IApplicator & IGetApplicatorCalcs;

const Applicators: React.FC<ApplicatorsProps> = ({ ...rest }) => {
  const { startDate, endDate } = useAplication();
  const { addToast } = useToast();
  const [applicatorsCalcs, setApplicatorsCalcs] = useState<IApplicatorCalcs[]>(
    [],
  );
  const [payments, setPayments] = useState('');

  useMemo(async () => {
    if (!startDate || !endDate) return;

    const data = await Promise.all(
      applicators.map(async applicator => {
        const calcs = await api.get<IGetApplicatorCalcs>(
          `applicator/financial/${applicator.id}/${startDate}/${endDate}`,
        );

        return {
          ...applicator,
          ...calcs.data,
        };
      }),
    );

    let paymentString = `Faça os pix:
    `;
    data.forEach(a => {
      if (a.restaurantPaid - a.applicatorPaid > 0) {
        paymentString = `${paymentString}
Valor: ${toMoney(a.restaurantPaid - a.applicatorPaid)} para a chave pix: ${
          a.pix
        }`;
      }
    });

    setApplicatorsCalcs(data);
    setPayments(paymentString);
  }, [endDate, startDate]);

  const handleApplicatorReceived = useCallback(
    async (applicatorId: string) => {
      const msg =
        'Tem certeza que deseja confirmar o pagamento de todas as aplicações do intervalo selecionado?';

      // eslint-disable-next-line no-alert
      const confirm = window.confirm(msg);

      if (confirm) {
        try {
          await api.put(
            `applicator/paid/${applicatorId}/${startDate}/${endDate}`,
          );

          setApplicatorsCalcs(old =>
            old.map(applicator =>
              applicator.id === applicatorId
                ? {
                    ...applicator,
                    applicatorPaid: applicator.restaurantPaid,
                  }
                : { ...applicator },
            ),
          );
        } catch (err) {
          addToast({
            title: 'Não foi possivel atualizar as aplicações',
          });
        }
      }
    },
    [addToast, endDate, startDate],
  );

  return (
    <S.Container {...rest}>
      <Period />

      {payments.length > 20 && (
        <S.Button onClick={() => copy(payments)}>
          Copiar texto de pagamento
        </S.Button>
      )}

      {applicatorsCalcs.map(applicator => (
        <S.ApplicatorContainer key={applicator.id}>
          <InformationBlock
            uptitle={applicator.name}
            img={applicator.img}
            value={toMoney(applicator.total)}
          />

          <InformationBlock
            // onClick={() => handleApplicatorReceived(applicator, false)}
            Icon={FiInbox}
            uptitle="Recebido"
            value={toMoney(applicator.applicatorPaid)}
          />

          <InformationBlock
            Icon={FiClock}
            status="warning"
            uptitle="A receber"
            value={toMoney(applicator.total - applicator.applicatorPaid)}
          />

          {applicator.restaurantPaid - applicator.applicatorPaid > 0 && (
            <InformationBlock
              onClick={() => handleApplicatorReceived(applicator.id)}
              Icon={FiDollarSign}
              uptitle="Liberado para pagamento"
              value={toMoney(
                applicator.restaurantPaid - applicator.applicatorPaid,
              )}
              status={applicator.restaurantPaid > 0 ? 'success' : 'default'}
            />
          )}
        </S.ApplicatorContainer>
      ))}
    </S.Container>
  );
};

export default Applicators;
