import React, { useCallback, useEffect, useState } from 'react';

import copy from 'copy-to-clipboard';
import { FiCheck, FiCopy } from 'react-icons/fi';
import * as S from './styles';
import api from '../../services/api';
import { IEmail } from '../../DTOS/IEmail';
import { useToast } from '../../hooks/toast';

const AccountsNeverUsed: React.FC = () => {
  const { addToast } = useToast();

  const [emails, setEmails] = useState<IEmail[]>([]);

  const getCouponsQuantity = useCallback(async () => {
    try {
      const response = await api.get<IEmail[]>(`email/list/coupons/neverused`);

      if (response.data) setEmails(response.data);
    } catch (err) {
      addToast({
        title: 'Não foi possivel buscar cupons',
      });
    }
  }, [addToast]);

  useEffect(() => {
    getCouponsQuantity();
  }, [getCouponsQuantity]);

  const handleCopyCookie = useCallback((cookie: any) => {
    const script = `
const cookies = ${cookie}

function setCookie(name, value, domain, path, expiry, secure, sameSite) {
    let cookieString = \`\${name}=\${value}; path=\${path}; domain=\${domain};\`;
    if (expiry) {
        const expiryDate = new Date(expiry * 1000);
        cookieString += \` expires=\${expiryDate.toUTCString()};\`;
    }
    if (secure) {
        cookieString += ' secure;';
    }
    if (sameSite) {
        cookieString += \` samesite=\${sameSite};\`;
    }
    document.cookie = cookieString;
}

cookies.forEach(cookie => {
    setCookie(
        cookie.name,
        cookie.value,
        cookie.domain,
        cookie.path,
        cookie.expiry,
        cookie.secure,
        cookie.sameSite
    );
});

console.log('Todos os dados inseridos, reiniciando...')

setTimeout(() => {
  window.location.reload()
}, 400)
`;

    copy(script);
  }, []);

  const handleUpdateEmail = useCallback(
    async (emailId: string) => {
      try {
        await api.put(`email/${emailId}`, {
          lastUse: new Date(),
        });

        setEmails(old => old.filter(o => o.id !== emailId));
      } catch (err) {
        addToast({
          title: 'Não foi possivel atualizar conta',
        });
      }
    },
    [addToast],
  );

  return (
    <S.Container>
      <S.CouponsQuantity>{`${emails.length} contas`}</S.CouponsQuantity>
      <S.EmailsContainer>
        {emails.length > 0 &&
          emails.map((email, i) => (
            <S.Email key={email.id}>
              <S.ButtonUsed onClick={() => handleUpdateEmail(email.id)}>
                <FiCheck />
              </S.ButtonUsed>

              <div>{email.email}</div>

              {email.cookies_json && (
                <S.ButtonCopy
                  onClick={() => handleCopyCookie(email.cookies_json)}
                >
                  <FiCopy />
                </S.ButtonCopy>
              )}
            </S.Email>
          ))}
      </S.EmailsContainer>
    </S.Container>
  );
};

export default AccountsNeverUsed;
