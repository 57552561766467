import React, { HTMLAttributes, useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import copy from 'copy-to-clipboard';
import { uuid } from 'uuidv4';
import * as S from './styles';

import api from '../../../services/api';
import { IEmail, IStatusProps } from '../../../DTOS/IEmail';

import NPImg from '../../../assets/np.png';
import MaxlifeImg from '../../../assets/maxlife.png';
import OutlookImg from '../../../assets/outlook.png';
import { useAplication } from '../../../hooks/app';
import { applicators } from '../../../db/applicators';

type PackProps = HTMLAttributes<HTMLDivElement>;

type PackParams = {
  pack: string;
};

export const statusList = [
  'Não verificado',
  'Verificar',
  'Tudo certo',
  'Email perdido',
  'iFood banido',
  'Pediu número',
  'Coletar cookies',
] as IStatusProps[];

export const couponsList = [12, 13, 14, 15, 16, 18, 20, 22, 24, 28];

const Pack: React.FC<PackProps> = ({ ...rest }) => {
  const [emails, setEmails] = useState<IEmail[]>([]);

  const { applicatorLogged } = useAplication();

  const { pack } = useParams<PackParams>();

  const [dropDownIsOpenIndex, setDropDownIsOpenIndex] = useState('');

  useMemo(async () => {
    if (pack) {
      const response = await api.get(`email/max/pack/${pack}`);
      setEmails(response.data);
    }
  }, [pack]);

  const updateStatusEmail = useCallback(
    async (email: IEmail, status: IStatusProps) => {
      await api.put(`email/${email.id}`, {
        status,
        applicatorVerifiedId: applicatorLogged.applicator?.id || '',
      });

      await api.post('log/', {
        id: uuid(),
        email: `${email.email} - ${email.pack.split('@npvendas.com')}`,
        status,
        applicatorId: applicatorLogged.applicator?.id || '',
      });

      setEmails(oldEmails =>
        oldEmails.map(e =>
          e.id === email.id
            ? {
                ...e,
                status,
                applicatorVerifiedId: applicatorLogged.applicator?.id || '',
              }
            : e,
        ),
      );

      setDropDownIsOpenIndex('');
    },
    [applicatorLogged.applicator],
  );

  const updateLoginType = useCallback(
    async (email: IEmail, loginType: 'Outlook' | 'Maxlife') => {
      const confirm = window.confirm(
        `Você tem certeza que deseja alterar o metodo de login para ${loginType}?`,
      );

      if (!confirm) return;

      await api.put(`email/${email.id}`, {
        loginType,
        applicatorVerifiedId: applicatorLogged.applicator?.id || '',
      });

      setEmails(oldEmails =>
        oldEmails.map(e =>
          e.id === email.id
            ? {
                ...e,
                loginType,
                applicatorVerifiedId: applicatorLogged.applicator?.id || '',
              }
            : e,
        ),
      );

      setDropDownIsOpenIndex('');
    },
    [applicatorLogged.applicator],
  );

  const updateCouponValue = useCallback(
    async (email: IEmail, coupon: number) => {
      await api.put(`email/${email.id}`, {
        coupon,
        status: 'Tudo certo',
        applicatorId: applicatorLogged.applicator?.id || '',
        applicatorVerifiedId: applicatorLogged.applicator?.id || '',
      });

      setEmails(oldEmails =>
        oldEmails.map(e =>
          e.id === email.id
            ? {
                ...e,
                coupon,
                applicatorVerifiedId: applicatorLogged.applicator?.id || '',
              }
            : e,
        ),
      );

      setDropDownIsOpenIndex('');
    },
    [applicatorLogged.applicator],
  );

  // const removeCookies = useCallback(
  //   async (email: IEmail) => {
  //     const confirm = window.confirm(
  //       'Você tem certeza que deseja remover os cookies dessa conta?',
  //     );

  //     if (!confirm) return;

  //     await api.put(`email/${email.id}`, {
  //       cookies_json: null,
  //       applicatorVerifiedId: applicatorLogged.applicator?.id || '',
  //     });

  //     setEmails(oldEmails =>
  //       oldEmails.map(e =>
  //         e.id === email.id
  //           ? {
  //               ...e,
  //               cookies_json: null,
  //               applicatorVerifiedId: applicatorLogged.applicator?.id || '',
  //             }
  //           : e,
  //       ),
  //     );

  //     setDropDownIsOpenIndex('');
  //   },
  //   [applicatorLogged.applicator],
  // );

  // const handleLogin = useCallback((email: IEmail) => {
  //   if (email.cookies_json) {
  //     const cookies = email.cookies_json;

  //     const script = `
  // const cookies = ${cookies}

  // function setCookie(name, value, domain, path, expiry, secure, sameSite) {
  //   let cookieString = \`\${name}=\${value}; path=\${path}; domain=\${domain};\`;
  //   if (expiry) {
  //       const expiryDate = new Date(expiry * 1000);
  //       cookieString += \` expires=\${expiryDate.toUTCString()};\`;
  //   }
  //   if (secure) {
  //       cookieString += ' secure;';
  //   }
  //   if (sameSite) {
  //       cookieString += \` samesite=\${sameSite};\`;
  //   }
  //   document.cookie = cookieString;
  // }

  // cookies.forEach(cookie => {
  //   setCookie(
  //       cookie.name,
  //       cookie.value,
  //       cookie.domain,
  //       cookie.path,
  //       cookie.expiry,
  //       cookie.secure,
  //       cookie.sameSite
  //   );
  // });

  // console.log('Todos os dados inseridos, reiniciando...')

  // setTimeout(() => {
  // window.location.reload()
  // }, 400)
  // `;

  //     copy(script);
  //   }
  // }, []);

  return (
    <S.Container {...rest}>
      {emails.map(e => (
        <S.EmailContainer key={e.id}>
          <S.Index hasCookies={!!e.cookies_json}>
            {e.index + 1}

            {e.cookies_json && (
              <S.Icon
                // onClick={() => removeCookies(e)}
                size={16}
                color="#fffff"
              />
            )}
          </S.Index>

          <S.Email onClick={() => copy(e.email)}>
            {e.email.split('@outlook.com')}
          </S.Email>

          <S.StatusContainer status={e.status}>
            {e.loginType === 'Maxlife' && (
              <S.LoginTypeImg
                onClick={() => updateLoginType(e, 'Outlook')}
                src={MaxlifeImg}
              />
            )}
            {e.loginType === 'Outlook' && (
              <S.LoginTypeImg
                onClick={() => updateLoginType(e, 'Maxlife')}
                src={OutlookImg}
              />
            )}
            {e.loginType === 'NP' && (
              <S.LoginTypeImg onClick={() => copy(e.pack)} src={NPImg} />
            )}
            <S.Status>
              {e.applicatorVerifiedId && (
                <S.ImgApplicator
                  src={
                    applicators.find(a => a.id === e.applicatorVerifiedId)?.img
                  }
                />
              )}

              <S.StatusText
                onClick={() =>
                  setDropDownIsOpenIndex(old =>
                    old === e.email ? '' : e.email,
                  )
                }
              >
                {e.status}
              </S.StatusText>
            </S.Status>
            {dropDownIsOpenIndex === e.email && (
              <S.StatusOptionsDropDown>
                {statusList.map(s => (
                  <S.StatusOption
                    key={s}
                    onClick={() => updateStatusEmail(e, s)}
                  >
                    {s}
                  </S.StatusOption>
                ))}

                <S.CouponsOptions>
                  {couponsList.map(coupon => (
                    <S.CouponOption
                      onClick={() => updateCouponValue(e, coupon)}
                    >
                      {coupon}
                    </S.CouponOption>
                  ))}
                </S.CouponsOptions>
              </S.StatusOptionsDropDown>
            )}
          </S.StatusContainer>
        </S.EmailContainer>
      ))}
    </S.Container>
  );
};

export default Pack;
