import React, { HTMLAttributes } from 'react';

import { IconType } from 'react-icons/lib';
import * as S from './styles';

export type InformationBlockStatus =
  | 'success'
  | 'alert'
  | 'warning'
  | 'default';

type InformationBlockProps = HTMLAttributes<HTMLDivElement> & {
  Icon?: IconType;
  img?: string;
  uptitle: string;
  value: string;
  status?: InformationBlockStatus;
  progress?: number;
};

const InformationBlock: React.FC<InformationBlockProps> = ({
  Icon,
  uptitle,
  img,
  value,
  status = 'default',
  progress = 0,
  ...rest
}) => {
  return (
    <S.Container status={status} {...rest}>
      {progress > 0 && <S.Progress progress={progress} />}

      {Icon && (
        <S.IconContainer status={status}>
          <Icon />
        </S.IconContainer>
      )}

      {img && (
        <S.IconContainer status={status}>
          <S.Img src={img} />
        </S.IconContainer>
      )}

      <S.ValueContainer>
        <S.UpTitle status={status}>{uptitle}</S.UpTitle>
        <S.Value>{value}</S.Value>
      </S.ValueContainer>
    </S.Container>
  );
};

export default InformationBlock;
