import { FiAlertCircle, FiX } from 'react-icons/fi';
import styled, { css } from 'styled-components';

type IActive = {
  active?: boolean;
};

type IStatus = {
  status?: string;
};

type PowerProps = {
  online?: boolean;
  styleButton: 'success' | 'alert' | 'danger';
};

export const Container = styled.div`
  max-width: 100%;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  height: max-content;

  gap: 18px;

  padding: 14px 20px;
  padding-bottom: 22px;

  background-color: #141414;
  border-radius: 20px;

  width: 100%;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  h1 {
    font-size: 18px;
  }
`;

export const OptionsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  gap: 6px;
`;

export const ButtonOption = styled.button<PowerProps>`
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  border: none;
  outline: none;
  background-color: transparent;

  color: #ffffff30;
  filter: drop-shadow(0px 0px 5px #ffffff30);

  transition: 0.2s ease;

  ${props =>
    props.online &&
    props.styleButton === 'success' &&
    css`
      color: #30d996;
      filter: drop-shadow(0px 0px 5px #30d996);
    `}

  ${props =>
    props.styleButton === 'alert' &&
    css`
      &:hover {
        color: #d9cb30;
        filter: drop-shadow(0px 0px 2px #d9cb30);
      }
    `}

    ${props =>
    props.styleButton === 'danger' &&
    css`
      &:hover {
        color: #d93030;
        filter: drop-shadow(0px 0px 2px #d93030);
      }
    `}
`;

export const RestaurantsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Restaurant = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 10px 10px;

  border-radius: 8px;

  &:nth-child(even) {
    background-color: #ffffff10;
  }

  > div {
    display: flex;
    align-items: center;
    gap: 10px;
  }
`;

export const RestaurantImg = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 6px;
`;

export const ApplicatorImg = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 50%;
`;

export const RestaurantInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
  a {
    text-decoration: none;
    color: #ffffff;

    font-size: 12px;
  }

  > div {
    display: flex;
    align-items: center;
    gap: 8px;
  }
`;

export const Status = styled.div<IStatus>`
  font-size: 10px;

  padding: 2px 6px;

  background-color: #ffffff30;

  color: #fff;

  border-radius: 10px;

  cursor: pointer;

  ${props =>
    props.status === 'Online' &&
    css`
      background-color: var(--primary);
      color: #000;
    `}

  ${props =>
    props.status === 'Atacar' &&
    css`
      background-color: var(--danger);
      color: #fff;
    `}

    ${props =>
    props.status === 'Devendo' &&
    css`
      background-color: var(--alert);
      color: #fff;
    `}
`;

export const RestaurantSelectedOptions = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  z-index: 10;

  padding: 20px;
  bottom: 0;

  background-color: #151515;

  width: 100%;
  left: 0;
`;

export const StatusOption = styled.div<IStatus>`
  padding: 2px 6px;

  color: #fff;

  border-radius: 10px;

  background-color: #ffffff30;
  color: #fff;

  cursor: pointer;

  ${props =>
    props.status === 'Online' &&
    css`
      color: var(--primary);
      border: 1px solid var(--primary);
      border-radius: 100px;
      background-color: transparent;
    `}

  ${props =>
    props.status === 'Caiu' &&
    css`
      color: var(--primary20);
      border: 1px solid var(--primary20);
      border-radius: 100px;
      background-color: transparent;
    `}

    ${props =>
    props.status === 'Desistiu' &&
    css`
      border-radius: 100px;
      border: 1px solid #fff;

      background-color: transparent;
    `}

    ${props =>
    props.status === 'Erro' &&
    css`
      border-radius: 100px;
      border: 1px solid #fff;

      background-color: transparent;
    `}

    ${props =>
    props.status === 'Devendo' &&
    css`
      border-radius: 100px;
      border: 1px solid #ffb800;
      color: #ffb800;

      background-color: transparent;
    `}

    ${props =>
    props.status === 'Atacar' &&
    css`
      border-radius: 100px;
      border: 1px solid var(--danger);
      color: var(--danger);

      background-color: transparent;
    `}
`;

export const CloseButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 40px;
  height: 40px;
  cursor: pointer;
`;

export const NoteIcon = styled(FiAlertCircle)`
  cursor: pointer;
  color: #ffffff30;
`;

export const NotesDescription = styled.div`
  display: flex;
  align-items: center;

  padding: 2px 10px;

  color: #fff;
  background-color: #ffffff30;
  border-radius: 10px;

  span {
    font-size: 10px;
    margin-right: 5px;
  }
`;

export const InformationsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr; /* Primeira coluna: 4 partes, Segunda coluna: 3 partes */

  gap: 6px;
`;

export const InfoBlock = styled.div<IActive>`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 6px 10px;
  cursor: pointer;

  text-align: center;

  border-radius: 8px;

  background-color: #00000060;

  border: 1px solid transparent;

  .title {
    font-size: 12px;
    color: #ffffff80;
  }

  .value {
  }

  ${props =>
    props.active &&
    css`
      .title {
        color: #ffffff;
      }

      border: 1px solid #fff;
      color: #fff;
    `}

  &.success {
    ${props =>
      props.active &&
      css`
        .title {
          color: var(--primary);
        }

        border: 1px solid var(--primary);
        color: var(--primary);
      `}
  }

  &.danger {
    ${props =>
      props.active &&
      css`
        .title {
          font-size: 14px;
          color: var(--danger);
        }

        border: 1px solid var(--danger);
        color: var(--danger);
      `}
  }
`;

export const RestaurantRegisterAside = styled.form`
  position: fixed;

  display: flex;
  flex-direction: column;

  overflow-y: scroll;
  gap: 10px;

  top: 0;
  right: 0;
  height: 100%;

  z-index: 100;

  background-color: var(--container);

  border-left: 1px solid var(--primary40);

  width: 300px;

  padding: 20px;
`;

export const Title = styled.h3`
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const IconClose = styled(FiX)`
  cursor: pointer;
`;

export const Input = styled.input`
  padding: 8px 14px;
  border-radius: 6px;
  font-size: 14px;

  border: 1px solid #ffffff20;
  color: #fff;
  outline: none;

  background-color: transparent;

  :focus {
    border: 1px solid #ffffff;
  }
`;

export const ButtonAdd = styled.button`
  height: 46px;
  border-radius: 10px;
  outline: none;
  background-color: transparent;

  color: #fff;
`;
