import igorImg from '../assets/igor.png';
import estherImg from '../assets/esther.png';

export type ICeo = {
  id: string;
  name: string;
  img: string;
};

export const ceos: ICeo[] = [
  {
    id: 'b024304a-633d-456c-855e-a527b4941dss',
    name: 'Igor',
    img: igorImg,
  },
  {
    id: 'a527b40a-633d-as6c-855e-a527b494da32',
    name: 'Esther',
    img: estherImg,
  },
];
