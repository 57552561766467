import { FiLogOut } from 'react-icons/fi';
import styled, { css } from 'styled-components';

type IButtonProps = {
  active?: boolean;
};

type IStoreImgProps = {
  selected?: boolean;
};

type IProgressProps = {
  progress: number;
};

export const Container = styled.header`
  position: fixed;
  height: 100vh;
  width: 80px;
  top: 0;

  left: 0;
  padding: 20px 10px;
`;

export const SectionContainer = styled.div``;

export const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;

  padding: 12px 0;

  height: 100%;
  width: 100%;

  background-color: var(--container);

  border-radius: 100px;

  z-index: 1;
`;

export const RoutesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

export const ButtonIcon = styled.button<IButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: transparent;

  width: 40px;
  height: 40px;

  border: none;
  outline: none;

  border-radius: 50%;

  color: var(--greyTextContainer);

  ${props =>
    props.active &&
    css`
      color: var(--black);
      background-color: var(--primary);
    `}
`;

export const Applicators = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const ApplicatorContent = styled.div`
  height: max-content;
`;

export const Animation = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  height: 60px;
`;

export const MoneyContainer = styled.div`
  font-size: 10px;
  text-align: center;
  font-weight: 600;
`;

export const StoresListContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  margin: 0px 0 20px;
  gap: 8px;

  padding: 0 10px;
`;

export const SelectStoreDropDown = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  cursor: pointer;

  padding: 8px 10px;

  border-radius: 8px;

  background-color: #202020;

  gap: 10px;

  > div {
    display: flex;
    align-items: center;
    gap: 10px;
  }
`;

export const Store = styled.div<IStoreImgProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  background-color: #202020;

  border-radius: 10px;
  padding: 8px 10px;

  width: 100%;
  font-size: 12px;

  cursor: pointer;

  gap: 10px;

  > div > div.percent {
    background-color: #ffffff40;

    min-width: 100px;
    height: 3px;
  }

  > div {
    display: flex;
    align-items: center;
    gap: 10px;
    z-index: 1;
  }

  span {
    font-size: 14px;
  }

  ${props =>
    props.selected &&
    css`
      background-color: #0b6050;
    `}
`;

export const Percent = styled.div<IProgressProps>`
  height: 3px;

  border-radius: 8px;

  ${props =>
    props.progress &&
    css`
      width: ${props.progress > 100 ? 100 : props.progress}%;
    `}

  ${props =>
    props.progress > 0 &&
    css`
      background-color: ${props.progress > 0 &&
      props.progress < 50 &&
      '#E1001B'};
      background-color: ${props.progress >= 50 &&
      props.progress < 99 &&
      '#E1B000'};
      background-color: ${props.progress === 100 && '#13B497'};
      background-color: ${props.progress > 100 && '#E1001B'};
    `}
`;

export const PercentText = styled.div<IProgressProps>`
  border-radius: 8px;
  font-size: 14px;

  ${props =>
    props.progress > 0 &&
    css`
      color: ${props.progress > 0 && props.progress < 50 && '#E1001B'};
      color: ${props.progress >= 50 && props.progress < 99 && '#E1B000'};
      color: ${props.progress === 100 && '#13B497'};
      color: ${props.progress > 100 && '#E1001B'};
    `}
`;

export const StoreImg = styled.img`
  width: 40px;
  height: 40px;

  border-radius: 50%;
`;

export const TotalApplication = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  position: relative;

  background-color: var(--background);

  width: 40px;
  padding: 10px 0;
  border-radius: 10px;

  overflow: hidden;
`;

export const TotalApplicationTitle = styled.span`
  font-size: 10px;
  font-weight: 500;
  z-index: 1;
`;

export const TotalApplicationValue = styled.span`
  font-weight: 500;
  z-index: 1;
`;

export const PercentageBar = styled.div<IProgressProps>`
  width: 100%;

  position: absolute;

  background-color: var(--primary);
  bottom: 0;

  ${props =>
    props.progress &&
    css`
      height: ${props.progress > 100 ? 100 : props.progress}%;
    `}

  ${props =>
    props.progress > 0 &&
    css`
      background-color: ${props.progress > 0 &&
      props.progress < 50 &&
      '#E1001B'};
      background-color: ${props.progress >= 50 &&
      props.progress < 99 &&
      '#E1B000'};
      background-color: ${props.progress >= 100 && '#0b6050'};
    `}
`;

export const ApplicatorLogged = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  flex-direction: column;

  gap: 14px;

  position: relative;
`;

export const ApplicatorLoggedImg = styled.img`
  cursor: pointer;

  width: 40px;
  height: 40px;

  border-radius: 50%;
  object-fit: cover;

  margin-top: 10px;
`;

export const IconLogout = styled(FiLogOut)`
  display: flex;

  margin: 10px 0px 20px;
  align-items: center;

  justify-content: center;
  color: #fff;
  cursor: pointer;
`;
