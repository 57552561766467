import React, {
  HTMLAttributes,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  FiCornerDownLeft,
  FiDollarSign,
  FiFrown,
  FiLock,
  FiX,
} from 'react-icons/fi';
import { format, subHours } from 'date-fns';
import { RiPushpinLine } from 'react-icons/ri';
import { uuid } from 'uuidv4';
import copy from 'copy-to-clipboard';
import * as S from './styles';
import { useAplication } from '../../../../hooks/app';
import { useToast } from '../../../../hooks/toast';
import api from '../../../../services/api';
import { IEmail } from '../../../../DTOS/IEmail';
import { ToCopy } from '../../../../components/ToCopy';
import Tooltip from '../../../../components/Tooltip';
import { IApplied } from '../../../../DTOS/IApplied';
import applicationSong from '../../../../assets/songs/coin.mp3';

import outlookImg from '../../../../assets/outlook.png';
import maxlifeImg from '../../../../assets/maxlife.png';
import npImg from '../../../../assets/np.png';

type CouponsProps = HTMLAttributes<HTMLDivElement> & {
  restaurantId: string;
};

const Coupons: React.FC<CouponsProps> = ({ restaurantId, ...rest }) => {
  const { addToast } = useToast();
  const { applicatorLogged, setAnimationPorkCoinValue, restaurants } =
    useAplication();
  const music = new Audio(applicationSong);
  const [emailsSelectedIds, setEmailsSelectedIds] = useState<string[]>([]);

  const [fixedCoupons, setFixedCoupons] = useState<IEmail[]>([]);
  const [coupons, setCoupons] = useState<IEmail[]>([]);

  const [couponsQuantity, setCouponsQuantity] = useState(0);

  const getCouponsQuantity = useCallback(async () => {
    try {
      const response = await api.get(`email/coupons/quantity`);

      if (response.data) setCouponsQuantity(response.data.quantity);
    } catch (err) {
      addToast({
        title: 'Não foi possivel buscar quantidade de cupons na bag',
      });
    }
  }, [addToast]);

  useMemo(() => {
    const fixed: IEmail[] = emailsSelectedIds.map(emailId => {
      const emailFound = applicatorLogged.coupons.find(c => c.id === emailId);
      return emailFound || ({} as IEmail);
    });

    getCouponsQuantity();

    setFixedCoupons(fixed);

    setCoupons(
      applicatorLogged.coupons.filter(
        a => emailsSelectedIds.findIndex(id => a.id === id) === -1,
      ),
    );
  }, [applicatorLogged.coupons, emailsSelectedIds, getCouponsQuantity]);

  const handleRemoveSelectedEmail = useCallback(emailId => {
    const ids = localStorage.getItem('@couponsSelected');

    if (ids) {
      const idsObject: string[] = JSON.parse(ids);

      const exists = idsObject.findIndex(id => id === emailId);

      if (exists !== -1) {
        const newIds = idsObject.filter(id => id !== emailId);
        setEmailsSelectedIds(newIds);
        localStorage.setItem('@couponsSelected', JSON.stringify(newIds));
      }
    }
  }, []);

  const handleGiveBack = useCallback(
    async (email: IEmail) => {
      try {
        const response = await api.put(`email/${email.id}`, {
          applicatorId: null,
          coupon: email.coupon,
        });

        if (response.data)
          applicatorLogged.setCoupons(oldState =>
            oldState.filter(state => state.id !== email.id),
          );

        handleRemoveSelectedEmail(email.id);
      } catch (err) {
        addToast({
          title: 'Não foi possivel buscar emails do aplicador',
        });
      }
    },
    [addToast, applicatorLogged, handleRemoveSelectedEmail],
  );

  const handleApplied = useCallback(
    async (email: IEmail) => {
      try {
        if (!applicatorLogged.applicator) return;

        if (!email.coupon) {
          addToast({
            title: 'Não foi possivel aplicar a conta',
            description: 'É preciso informar o valor do cupom aplicado!',
            type: 'error',
          });

          return;
        }

        if (!email.orderNumber) {
          addToast({
            title: 'Não foi possivel aplicar a conta',
            description: 'É preciso informar o número do pedido!',
            type: 'error',
          });

          return;
        }

        const restaurant = restaurants.find(r => r.id === restaurantId);

        if (!restaurant) return;

        const { wannaEvaluation } = restaurant;

        const appliedData = {
          id: uuid(),
          applicatorId: applicatorLogged.applicator.id,
          restaurantId,
          orderNumber: email.orderNumber,
          email: email.email,
          password: email.password,
          pack: email.pack,
          alternative: email.alternative,
          date: subHours(new Date(), 3),
          coupon: email.coupon,
          received: false,
          applicatorPaid: false,
          evaluationPaid: !wannaEvaluation,
        } as unknown as IApplied;

        const response = await api.post<IApplied>(`applied`, appliedData);

        await api.put(`email/${email.id}`, {
          lastUse: subHours(new Date(), 3),
          coupon: null,
          applicatorId: null,
          status: 'Tudo certo',
        });

        if (response.data)
          applicatorLogged.setCoupons(oldState =>
            oldState.filter(state => state.id !== email.id),
          );

        applicatorLogged.setTodayApplications(old => [
          ...old,
          { ...response.data },
        ]);

        music.play();
        setTimeout(() => {
          setAnimationPorkCoinValue(old => !old);
          addToast({
            type: 'success',
            title: `🪙 R$ ${response.data.calcs.enterprise.aplicador.toFixed(
              2,
            )}`,
            description: 'Adicionado ao cofre! ',
          });
        }, 500);

        handleRemoveSelectedEmail(email.id);
      } catch (err) {
        addToast({
          title: 'Não foi possivel registrar aplicacao',
        });
      }
    },
    [
      addToast,
      applicatorLogged,
      handleRemoveSelectedEmail,
      music,
      restaurantId,
      restaurants,
      setAnimationPorkCoinValue,
    ],
  );

  const handleChangeCouponByInput = useCallback(
    (newValue, emailId) => {
      applicatorLogged.setCoupons(old =>
        old.map(email =>
          email.id === emailId ? { ...email, coupon: newValue } : email,
        ),
      );
    },
    [applicatorLogged],
  );

  const handleChangeOrderInput = useCallback(
    (newValue, emailId) => {
      applicatorLogged.setCoupons(old =>
        old.map(email =>
          email.id === emailId ? { ...email, orderNumber: newValue } : email,
        ),
      );
    },
    [applicatorLogged],
  );

  const handleWithoutCoupon = useCallback(
    async (email: IEmail) => {
      try {
        const response = await api.put(`email/${email.id}`, {
          applicatorId: null,
          coupon: null,
          status: 'Tudo certo',
        });

        if (response.data)
          applicatorLogged.setCoupons(oldState =>
            oldState.filter(state => state.id !== email.id),
          );

        handleRemoveSelectedEmail(email.id);
      } catch (err) {
        addToast({
          title: 'Não foi possivel buscar emails do aplicador',
        });
      }
    },
    [addToast, applicatorLogged, handleRemoveSelectedEmail],
  );

  const handlePaymentOnline = useCallback(
    async (email: IEmail) => {
      const confirm = window.confirm(
        'Tem certeza que deseja separar essa conta?',
      );

      if (!confirm) return;

      try {
        const response = await api.put(`email/${email.id}`, {
          applicatorId: null,
          coupon: null,
          status: 'Só pagamento online',
        });

        if (response.data)
          applicatorLogged.setCoupons(oldState =>
            oldState.filter(state => state.id !== email.id),
          );

        handleRemoveSelectedEmail(email.id);
      } catch (err) {
        addToast({
          title: 'Não foi possivel buscar emails do aplicador',
        });
      }
    },
    [addToast, applicatorLogged, handleRemoveSelectedEmail],
  );

  const handleBanAccount = useCallback(
    async (email: IEmail) => {
      const confirm = window.confirm(
        'Tem certeza que deseja apagar os cookies dessa conta?',
      );

      if (!confirm) return;

      try {
        const response = await api.put(`email/${email.id}`, {
          applicatorId: null,
          coupon: null,
          status: 'iFood banido',
          cookies_json: null,
        });

        if (response.data)
          applicatorLogged.setCoupons(oldState =>
            oldState.filter(state => state.id !== email.id),
          );

        handleRemoveSelectedEmail(email.id);
      } catch (err) {
        addToast({
          title: 'Não foi possivel apagar cookies da conta',
        });
      }
    },
    [addToast, applicatorLogged, handleRemoveSelectedEmail],
  );

  useEffect(() => {
    const ids = localStorage.getItem('@couponsSelected');

    if (ids) {
      const idsObject: string[] = JSON.parse(ids);
      if (ids.length > 0) setEmailsSelectedIds(idsObject);
    }
  }, [getCouponsQuantity]);

  const handleSelectedEmail = useCallback(async (email: IEmail) => {
    const ids = localStorage.getItem('@couponsSelected');

    if (ids) {
      const idsObject: string[] = JSON.parse(ids);

      const exists = idsObject.findIndex(id => id === email.id);

      const newIds =
        exists !== -1
          ? idsObject.filter(id => id !== email.id)
          : [...idsObject, email.id];

      setEmailsSelectedIds(newIds);
      localStorage.setItem('@couponsSelected', JSON.stringify(newIds));
    } else {
      setEmailsSelectedIds([email.id]);
      localStorage.setItem('@couponsSelected', JSON.stringify([email.id]));
    }
  }, []);

  const handleGetCoupon = useCallback(
    async (quantity: number) => {
      if (applicatorLogged.coupons.length > 20) {
        addToast({
          title: 'Só é permitido ter 20 cupons',
        });
        return;
      }

      try {
        await api.get<IEmail[]>(
          `email/applicator/${applicatorLogged.applicator?.id}/getcoupons/${quantity}`,
        );

        const newEmails = await api.get(
          `email/coupons/${applicatorLogged.applicator?.id}`,
        );

        if (newEmails.data) applicatorLogged.setCoupons(newEmails.data);

        getCouponsQuantity();
      } catch (err) {
        alert(err);
        addToast({
          title: 'Não foi possivel pegar cupons',
        });
      }
    },
    [addToast, applicatorLogged, getCouponsQuantity],
  );

  // const handleGetWithoutCoupon = useCallback(
  //   async (quantity: number) => {
  //     if (applicatorLogged.coupons.length > 20) {
  //       addToast({
  //         title: 'Só é permitido ter 20 contas',
  //       });
  //       return;
  //     }

  //     try {
  //       await api.get<IEmail[]>(
  //         `email/applicator/${applicatorLogged.applicator?.id}/without/coupons/${quantity}`,
  //       );

  //       const newEmails = await api.get(
  //         `email/coupons/${applicatorLogged.applicator?.id}`,
  //       );

  //       if (newEmails.data) applicatorLogged.setCoupons(newEmails.data);
  //     } catch (err) {
  //       alert(err);
  //       addToast({
  //         title: 'Não foi possivel pegar sem cupons',
  //       });
  //     }
  //   },
  //   [addToast, applicatorLogged],
  // );

  const handleLogin = useCallback((email: IEmail) => {
    if (email.cookies_json) {
      const cookies = email.cookies_json;

      const script = `
const cookies = ${cookies}

function setCookie(name, value, domain, path, expiry, secure, sameSite) {
    let cookieString = \`\${name}=\${value}; path=\${path}; domain=\${domain};\`;
    if (expiry) {
        const expiryDate = new Date(expiry * 1000);
        cookieString += \` expires=\${expiryDate.toUTCString()};\`;
    }
    if (secure) {
        cookieString += ' secure;';
    }
    if (sameSite) {
        cookieString += \` samesite=\${sameSite};\`;
    }
    document.cookie = cookieString;
}

cookies.forEach(cookie => {
    setCookie(
        cookie.name,
        cookie.value,
        cookie.domain,
        cookie.path,
        cookie.expiry,
        cookie.secure,
        cookie.sameSite
    );
});

console.log('Todos os dados inseridos, reiniciando...')

setTimeout(() => {
  window.location.reload()
}, 400)
`;

      copy(script);
    }
  }, []);

  return (
    <S.Container {...rest}>
      <S.CouponsQuantity>{`${couponsQuantity} cupons na bag`}</S.CouponsQuantity>

      <S.PanelOfCoupons>
        <S.PanelInformation>
          <span className="title">Recebidos</span>
          <span className="value">
            {applicatorLogged.todayApplications.length +
              applicatorLogged.coupons.length}
          </span>
        </S.PanelInformation>
        <S.PanelInformation>
          <span className="title">Disponíveis</span>
          <span className="value">{applicatorLogged.coupons.length}</span>
        </S.PanelInformation>
        <S.PanelInformation>
          <span className="title">Aplicados</span>
          <span className="value">
            {applicatorLogged.todayApplications.length}
          </span>
        </S.PanelInformation>
      </S.PanelOfCoupons>

      {applicatorLogged.applicator && (
        <S.ButtonGetCoupon>
          {applicatorLogged.coupons.length <= 19 && (
            <S.GetCouponButton onClick={() => handleGetCoupon(1)}>
              + 1 cupom
            </S.GetCouponButton>
          )}
          {20 - applicatorLogged.coupons.length > 1 &&
            20 - applicatorLogged.coupons.length <= 20 && (
              <S.GetCouponButton
                onClick={() =>
                  handleGetCoupon(20 - applicatorLogged.coupons.length)
                }
              >
                {`+ ${20 - applicatorLogged.coupons.length} cupons`}
              </S.GetCouponButton>
            )}
        </S.ButtonGetCoupon>
      )}

      {/* {applicatorLogged.applicator && (
        <S.ButtonGetCoupon>
          {applicatorLogged.coupons.length <= 19 && (
            <S.GetCouponButton onClick={() => handleGetWithoutCoupon(1)}>
              + 1 sem cupom
            </S.GetCouponButton>
          )}
          {20 - applicatorLogged.coupons.length > 1 &&
            20 - applicatorLogged.coupons.length <= 20 && (
              <S.GetCouponButton
                onClick={() =>
                  handleGetWithoutCoupon(20 - applicatorLogged.coupons.length)
                }
              >
                {`+ ${20 - applicatorLogged.coupons.length} sem cupons`}
              </S.GetCouponButton>
            )}
        </S.ButtonGetCoupon>
      )} */}

      {fixedCoupons.map(
        (e, i) =>
          e && (
            <S.CouponContainer
              copied={emailsSelectedIds.findIndex(id => e.id === id) !== -1}
              key={e.email}
            >
              <div>
                <ToCopy text={e.email}>
                  <S.Email>
                    <RiPushpinLine
                      onClick={() => handleSelectedEmail(e)}
                      color="#13b497"
                      style={{ cursor: 'pointer' }}
                    />

                    <span className="mail">
                      {e.email && e.email.split('@outlook.com')}
                    </span>
                  </S.Email>
                </ToCopy>
                {e.enterprise === 'max' && <S.Pack>{e.pack}</S.Pack>}

                <S.OrderNumberInput
                  onChange={event =>
                    handleChangeOrderInput(event.target.value, e.id)
                  }
                  placeholder={String(i + 1)}
                  type="text"
                />
              </div>

              <div>
                <S.ButtonsContainer>
                  <Tooltip upside title="Devolver">
                    <button
                      className="giveback"
                      type="button"
                      onClick={() => handleGiveBack(e)}
                    >
                      <FiCornerDownLeft />
                    </button>
                  </Tooltip>

                  <Tooltip upside title="Sem cupom">
                    <button
                      onClick={() => handleWithoutCoupon(e)}
                      className="without"
                      type="button"
                    >
                      <FiFrown />
                    </button>
                  </Tooltip>

                  <Tooltip upside title="Banir conta">
                    <button
                      onClick={() => handleWithoutCoupon(e)}
                      className="without"
                      type="button"
                    >
                      <FiX />
                    </button>
                  </Tooltip>

                  <Tooltip upside title="Só pagamento online">
                    <button
                      onClick={() => handlePaymentOnline(e)}
                      className="without"
                      type="button"
                    >
                      <FiLock />
                    </button>
                  </Tooltip>

                  {e.cookies_json && (
                    <S.CookieButtonLogin onClick={() => handleLogin(e)}>
                      C
                    </S.CookieButtonLogin>
                  )}

                  {e.loginType === 'Maxlife' && (
                    <Tooltip upside title="Maxlife">
                      <S.Img src={maxlifeImg} />
                    </Tooltip>
                  )}

                  {e.loginType === 'Outlook' && (
                    <Tooltip upside title="Outlook">
                      <S.Img src={outlookImg} />
                    </Tooltip>
                  )}

                  {e.loginType === 'NP' && (
                    <ToCopy text={e.pack}>
                      <S.Img src={npImg} />
                    </ToCopy>
                  )}

                  {e.lastVerified && (
                    <S.Hour>{format(new Date(e.lastVerified), 'HH:mm')}</S.Hour>
                  )}
                </S.ButtonsContainer>

                <S.ValueContainer>
                  <S.CouponValueInput
                    type="number"
                    onChange={event =>
                      handleChangeCouponByInput(
                        Number(event.target.value),
                        e.id,
                      )
                    }
                    defaultValue={e.coupon?.toString()}
                  />
                  <button
                    onClick={() => handleApplied(e)}
                    className="applied"
                    type="button"
                  >
                    <FiDollarSign />
                  </button>
                </S.ValueContainer>
              </div>
            </S.CouponContainer>
          ),
      )}

      {coupons.map(e => (
        <S.CouponContainer
          copied={emailsSelectedIds.findIndex(id => e.id === id) !== -1}
          key={e.email}
        >
          <div>
            <ToCopy text={e.email}>
              <S.Email>
                <RiPushpinLine
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleSelectedEmail(e)}
                />

                <span className="mail">{e.email.split('@outlook.com')}</span>
              </S.Email>
            </ToCopy>

            {e.enterprise === 'max' && <S.Pack>{e.pack}</S.Pack>}

            <S.OrderNumberInput
              onChange={event =>
                handleChangeOrderInput(event.target.value, e.id)
              }
              placeholder="Pedido"
              type="text"
            />
          </div>

          <div>
            <S.ButtonsContainer>
              <Tooltip upside title="Devolver">
                <button
                  className="giveback"
                  type="button"
                  onClick={() => handleGiveBack(e)}
                >
                  <FiCornerDownLeft />
                </button>
              </Tooltip>

              <Tooltip upside title="Sem cupom">
                <button
                  onClick={() => handleWithoutCoupon(e)}
                  className="without"
                  type="button"
                >
                  <FiFrown />
                </button>
              </Tooltip>

              <Tooltip upside title="Banir conta">
                <button
                  onClick={() => handleBanAccount(e)}
                  className="without"
                  type="button"
                >
                  <FiX />
                </button>
              </Tooltip>

              <Tooltip upside title="Só pagamento online">
                <button
                  onClick={() => handlePaymentOnline(e)}
                  className="without"
                  type="button"
                >
                  <FiLock />
                </button>
              </Tooltip>

              {e.cookies_json && (
                <S.CookieButtonLogin onClick={() => handleLogin(e)}>
                  C
                </S.CookieButtonLogin>
              )}

              {e.loginType === 'Maxlife' && (
                <Tooltip upside title="Maxlife">
                  <S.Img src={maxlifeImg} />
                </Tooltip>
              )}

              {e.loginType === 'Outlook' && (
                <Tooltip upside title="Outlook">
                  <S.Img src={outlookImg} />
                </Tooltip>
              )}

              {e.loginType === 'NP' && (
                <ToCopy text={e.pack}>
                  <S.Img src={npImg} />
                </ToCopy>
              )}

              {e.lastVerified && (
                <S.Hour>{format(new Date(e.lastVerified), 'HH:mm')}</S.Hour>
              )}
            </S.ButtonsContainer>
            <S.ValueContainer>
              <S.CouponValueInput
                type="number"
                onChange={event =>
                  handleChangeCouponByInput(Number(event.target.value), e.id)
                }
                defaultValue={e.coupon?.toString()}
              />
              <button
                onClick={() => handleApplied(e)}
                className="applied"
                type="button"
              >
                <FiDollarSign />
              </button>
            </S.ValueContainer>
          </div>
        </S.CouponContainer>
      ))}
    </S.Container>
  );
};

export default Coupons;
