import styled from 'styled-components';

export const Container = styled.button`
  background: linear-gradient(to right, #13b497, #13b49760);

  border-radius: 10px;
  border: 0;
  padding: 1.5rem 16px;
  line-height: 0;
  color: #fff;
  width: 100%;
  font-weight: 500;
  margin-top: 16px;
  transition: 0.2s ease;

  &:hover {
    opacity: 0.9;
  }
`;
