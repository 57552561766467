import styled, { css } from 'styled-components';

type IButtonProps = {
  buttonType: 'checkin' | 'checkout';
};

type IProgress = {
  progress: number;
};

export const Container = styled.div`
  min-width: 250px;
  flex: 1;
  justify-content: space-between;
  gap: 8px;
  border-radius: 8px;

  padding: 0px 10px 16px;

  background-color: var(--container);
  color: #fff;

  overflow: hidden;

  position: relative;
`;

export const ProgressContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 4px;
  width: 100%;
`;

export const ProgressBar = styled.div`
  width: 100%;
  height: 4px;

  border-radius: 100px;
  background-color: #ffffff50;
`;

export const Progress = styled.div<IProgress>`
  width: 0%;
  height: 4px;
  border-radius: 100px;

  position: relative;

  transition: 1s ease;

  ${props =>
    props.progress > 0 &&
    css`
      width: ${props.progress > 100 ? 100 : props.progress}%;
      background-color: ${props.progress < 50 && '#E1B000'};
      background-color: ${props.progress >= 50 &&
      props.progress <= 100 &&
      '#00dfb7'};
      background-color: ${props.progress > 100 && '#E1001B'};
    `}
`;

export const PercentText = styled.span<IProgress>`
  color: #00dfb7;
  font-size: 10px;

  position: absolute;
  margin-top: 6px;

  right: 0;

  margin-right: 6px;

  ${props =>
    props.progress > 0 &&
    css`
      color: ${props.progress < 50 && '#E1B000'};
      color: ${props.progress >= 50 && props.progress <= 100 && '#00dfb7'};
      color: ${props.progress > 100 && '#E1001B'};
    `}
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  a,
  div {
    display: flex;
    flex-direction: column;
  }

  div {
    margin-left: 10px;
  }
`;

export const Image = styled.img`
  width: 40px;
  height: 40px;
  object-fit: cover;

  border-radius: 10px;

  background-color: #fff;
`;

export const Title = styled.h4`
  text-align: center;
  font-size: 12px;

  margin: 14px 0 8px;

  max-width: 200px;
`;

export const OwnerName = styled.span`
  font-size: 12px;
  display: block;
  color: var(--greyTextContainer);
`;

export const Schedule = styled.span`
  font-size: 12px;
  color: #13b497;
`;

export const ContentContainer = styled.div`
  display: flex;
  align-items: center;

  margin: 20px 10px;
`;

export const Description = styled.p`
  font-weight: 400;

  margin-top: 10px;

  text-align: center;
  font-size: 12px;

  max-width: 200px;
  word-break: break;

  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
`;

export const ImageRestaurantContainer = styled.div`
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ApplicatorImg = styled.img`
  position: absolute;

  right: -30px;

  width: 25px;
  height: 25px;

  border-radius: 50%;
  border: none;
  font-weight: 600;
  color: var(--danger);
  background-color: var(--danger20);
`;

export const Button = styled.button<IButtonProps>`
  position: absolute;

  right: -30px;

  width: 25px;
  height: 25px;

  border-radius: 50%;
  border: none;

  color: var(--danger);
  background-color: var(--danger20);

  ${props =>
    props.buttonType === 'checkin' &&
    css`
      color: var(--primary);
      background-color: var(--primary20);
    `}
`;

export const Status = styled.div<{ status: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;

  margin-top: 8px;

  ${props =>
    props.status === 'Devendo' &&
    css`
      background-color: #ffb80040;
      color: #fff;
    `}

  ${props =>
    props.status === 'Online' &&
    css`
      background-color: #66d37a40;
      color: #fff;
    `}

    ${props =>
    props.status === 'Atacar' &&
    css`
      background-color: #ff181840;
      color: #fff;
    `}


  div {
    width: 4px;
    height: 4px;
    border-radius: 50%;
  }
  span {
    text-align: center;
    font-size: 12px;
  }
`;
