import React, { useCallback, useState } from 'react';
import * as S from './styles';
import api from '../../services/api';

const coupons = [
  {
    value: 12,
    min: 27,
  },
  {
    value: 13,
    min: 28,
  },
  {
    value: 14,
    min: 29,
  },
  {
    value: 24,
    min: 39,
  },
];

const Buy: React.FC = () => {
  const [transactionAmount, setTransactionAmount] = useState('10.00');
  const payer = {
    email: 'iagoiann2@gmail.com',
    first_name: 'Iago',
    last_name: 'Iann',
    identification_number: '11172589631', // CPF do cliente
  };

  const [qrCodeBase64, setQrCodeBase64] = useState<string>('');
  const [paymentStatus, setPaymentStatus] = useState<string>('');
  const [paymentId, setPaymentId] = useState('');

  const generatePixPayment = useCallback(async () => {
    try {
      const response = await api.post('/generate-pix', {
        transactionAmount: parseFloat(transactionAmount),
        description: 'Pagamento de Teste PIX',
        payer,
      });
      setPaymentId(response.data.id);

      setQrCodeBase64(response.data.qr_code_base64);
    } catch (error) {
      console.error('Erro ao gerar pagamento PIX', error);
    }
  }, [payer, transactionAmount]);

  const checkPaymentStatus = useCallback(async () => {
    try {
      const response = await api.get(`/check-payment-status/${paymentId}`);
      setPaymentStatus(response.data.status);
    } catch (error) {
      console.error('Erro ao verificar status do pagamento', error);
    }
  }, [paymentId]);

  return (
    <S.Container>
      {coupons.map(c => (
        <div key={c.value}>
          <div>{`Cupom de R$ ${c.value}`}</div>
          <div>{`Compre por R$ ${((40 * c.value) / 100).toFixed(2)}`}</div>
          <button type="button">Comprar</button>
        </div>
      ))}

      <div style={{ textAlign: 'center' }}>
        <h1>Pagamento via PIX com Mercado Pago</h1>
        <input
          type="number"
          step="0.01"
          value={transactionAmount}
          onChange={e => setTransactionAmount(e.target.value)}
          placeholder="Valor do pagamento"
        />
        <button type="button" onClick={generatePixPayment}>
          Gerar QR Code
        </button>
        {qrCodeBase64 && (
          <div>
            <h2>QR Code para Pagamento PIX</h2>
            <img
              src={`data:image/png;base64,${qrCodeBase64}`}
              alt="QR Code PIX"
            />
          </div>
        )}
        <button type="button" onClick={checkPaymentStatus}>
          Verificar Status
        </button>
        {paymentStatus && <p>Status do pagamento:{paymentStatus}</p>}
      </div>
    </S.Container>
  );
};

export default Buy;
