import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: 100vh;

  background-color: #151515;

  background-size: cover;
  background-position: center;

  animation: ${appearFromLeft} 1s;

  position: relative;

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    filter: blur(1.5rem);
  }

  img {
    border-radius: 50%;
    width: 250px;
    height: 250px;
    z-index: 1;
  }

  form {
    margin: 40px 0;
    width: 340px;
    text-align: center;
    z-index: 1;

    h1 {
      margin-bottom: 24px;
    }

    a {
      color: #f4ede8;
      display: block;
      margin-top: 24px;
      text-decoration: none;
      transition: color 0.2s;

      &:hover {
        color: ${shade(0.2, '#f4ede8')};
      }
    }
  }

  > a {
    color: #8743ff;
    display: block;
    margin-top: 24px;
    text-decoration: none;
    transition: color 0.2s;

    display: flex;
    align-items: center;

    svg {
      margin-right: 16px;
    }

    &:hover {
      color: ${shade(0.2, '#8743FF')};
    }
  }
`;

export const Background = styled.div`
  padding: 40px;

  display: flex;
  flex-direction: column;

  z-index: 1;
  width: 100%;

  gap: 20px;

  p {
    max-width: 500px;
    color: #ffffff80;
  }

  a {
    padding: 10px;
    text-align: center;
    text-decoration: none;

    margin-top: 10px;

    border-radius: 8px;

    border: 1px solid #fff;

    background-color: transparent;

    color: #fff;

    max-width: 300px;

    transition: 0.2s ease;

    &:hover {
      background-color: #fff;
      color: #151515;
    }
  }
`;
