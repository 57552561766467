import React, {
  createContext,
  useContext,
  useCallback,
  useState,
  useMemo,
  useEffect,
} from 'react';

import { addDays, endOfWeek, format, startOfWeek, sub } from 'date-fns';
import { IRestaurant } from '../DTOS/IRestaurant';
import { IApplicator } from '../DTOS/IApplicator';
import api from '../services/api';
import { useToast } from './toast';
import { IEmail } from '../DTOS/IEmail';
import { IApplied } from '../DTOS/IApplied';
import { IOwner } from '../DTOS/IOwner';

type PeriodProps = 'today' | 'pendings' | 'currentweek' | 'lastweek';

type ApplicatorProps = IApplicator & {
  count: number;
};

type PeriodDatesProps = {
  today: {
    start: string;
    end: string;
  };
  currentWeek: {
    start: string;
    end: string;
  };
  lastWeek: {
    start: string;
    end: string;
  };
  pendings: {
    start: string;
    end: string;
  };
};

interface AplicationContextData {
  restaurants: IRestaurant[];
  owners: IOwner[];
  animationPorkCoinValue: boolean;
  setAnimationPorkCoinValue: React.Dispatch<React.SetStateAction<boolean>>;
  applicators: ApplicatorProps[];
  periodDates: PeriodDatesProps;
  startDate: string;
  endDate: string;
  periodType: PeriodProps;
  setPeriodType(period: PeriodProps): void;
  applicatorLogged: {
    setApplicatorLogged(applicator: IApplicator | undefined): void;
    applicator: IApplicator | undefined;
    checkin(restaurantId: string): Promise<boolean>;
    checkout(restaurantId: string): Promise<boolean>;
    coupons: IEmail[];
    setCoupons: React.Dispatch<React.SetStateAction<IEmail[]>>;
    todayApplications: IApplied[];
    setTodayApplications: React.Dispatch<React.SetStateAction<IApplied[]>>;
  };
}

const AplicationContext = createContext<AplicationContextData>(
  {} as AplicationContextData,
);

const AplicationProvider: React.FC = ({ children }) => {
  const { addToast } = useToast();
  const [applicatorLogged, setApplicatorLogged] = useState<
    IApplicator | undefined
  >(undefined);
  const [restaurants, setRestaurants] = useState<IRestaurant[]>([]);
  const [owners, setOwners] = useState<IOwner[]>([]);
  const [coupons, setCoupons] = useState<IEmail[]>([]);
  const [todayApplications, setTodayApplications] = useState<IApplied[]>([]);
  const [animationPorkCoinValue, setAnimationPorkCoinValue] = useState(false);
  const [applicators, setApplicators] = useState<ApplicatorProps[]>([]);
  const [periodType, setPeriodType] = useState<PeriodProps>('currentweek');

  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');

  const [periodDates, setPeriodDates] = useState<PeriodDatesProps>(
    {} as PeriodDatesProps,
  );

  useEffect(() => {
    const date = new Date();
    const startOfWeekDate = startOfWeek(date, { weekStartsOn: 1 });
    const endOfWeekDate = endOfWeek(date, { weekStartsOn: 1 });

    const lastWeekStartDate = startOfWeek(
      sub(startOfWeekDate, {
        days: 7,
      }),
      { weekStartsOn: 1 },
    );

    const lastWeekEndDate = endOfWeek(
      sub(endOfWeekDate, {
        days: 7,
      }),
      { weekStartsOn: 1 },
    );

    const pendingsStartDate = '2024-04-15';

    const todayDate = new Date();

    const rangeDates = {
      today: {
        start: format(todayDate, 'yyyy-MM-dd'),
        end: format(new Date(addDays(todayDate, 1)), 'yyyy-MM-dd'),
      },
      currentWeek: {
        start: format(new Date(startOfWeekDate), 'yyyy-MM-dd'),
        end: format(new Date(addDays(endOfWeekDate, 1)), 'yyyy-MM-dd'),
      },
      lastWeek: {
        start: format(new Date(lastWeekStartDate), 'yyyy-MM-dd'),
        end: format(new Date(addDays(lastWeekEndDate, 1)), 'yyyy-MM-dd'),
      },
      pendings: {
        start: pendingsStartDate,
        end: format(new Date(lastWeekStartDate), 'yyyy-MM-dd'),
      },
    };

    setPeriodDates(rangeDates);

    if (periodType === 'currentweek') {
      setStartDate(rangeDates.currentWeek.start);
      setEndDate(rangeDates.currentWeek.end);
    }

    if (periodType === 'pendings') {
      setStartDate(rangeDates.pendings.start);
      setEndDate(rangeDates.pendings.end);
    }

    if (periodType === 'today') {
      setStartDate(rangeDates.today.start);
      setEndDate(rangeDates.today.end);
    }

    if (periodType === 'lastweek') {
      setStartDate(rangeDates.lastWeek.start);
      setEndDate(rangeDates.lastWeek.end);
    }
  }, [periodType]);

  useMemo(async () => {
    try {
      const response = await api.get<ApplicatorProps[]>(`applicator`);
      if (response.data) setApplicators(response.data);
    } catch (err) {
      alert(err);
    }
  }, []);

  useMemo(async () => {
    if (!applicatorLogged) {
      return;
    }

    try {
      const response = await api.get(`email/coupons/${applicatorLogged.id}`);
      if (response.data) setCoupons(response.data);
    } catch (err) {
      addToast({
        title: 'Não foi possivel buscar emails do aplicador',
      });
    }
  }, [addToast, applicatorLogged]);

  useMemo(async () => {
    if (!applicatorLogged) {
      return;
    }

    try {
      const response = await api.get<IApplied[]>(
        `applied/${applicatorLogged.id}/between/${format(
          new Date(),
          'yyyy-MM-dd',
        )}/${format(addDays(new Date(), 1), 'yyyy-MM-dd')}`,
      );

      if (response.data) {
        setTodayApplications(response.data);
      }
    } catch (err) {
      addToast({
        title: 'Não foi possivel buscar aplicados do aplicador',
      });
    }
  }, [addToast, applicatorLogged]);

  useMemo(() => {
    const applicatorId = localStorage.getItem(
      '71c66b70-3f7d-4236-ad00-76510ba0edb7',
    );
    const applicator = applicators.find(a => a.id === applicatorId);
    if (applicator) setApplicatorLogged(applicator);
  }, [applicators]);

  useMemo(async () => {
    try {
      const response = await api.get<IRestaurant[]>('restaurant');

      if (response.data) {
        setRestaurants(response.data);
      }
    } catch (err) {
      addToast({
        title: 'Erro ao tentar buscar restaurantes',
      });
    }
  }, [addToast]);

  useMemo(async () => {
    try {
      const response = await api.get<IOwner[]>('restaurant/owners/all');

      if (response.data) {
        setOwners(response.data);
      }
    } catch (err) {
      addToast({
        title: 'Erro ao tentar buscar owners',
      });
    }
  }, [addToast]);

  const updateRestaurant = useCallback(
    (restaurant: IRestaurant) => {
      setRestaurants(oldRestaurants =>
        oldRestaurants.map(r => (r.id === restaurant.id ? restaurant : r)),
      );
    },
    [setRestaurants],
  );

  const checkin = useCallback(
    async (restaurantId: string) => {
      try {
        const restaurant = restaurants.find(r => r.id === restaurantId);

        if (!restaurant) {
          addToast({
            title: 'ID do Restaurant Invalido',
          });
          return false;
        }

        if (!applicatorLogged) {
          addToast({
            title: 'Nenhum aplicador logado',
          });
          return false;
        }

        await api.put(`restaurant/${restaurantId}`, {
          applicatorIdCheckin: applicatorLogged.id,
        });

        updateRestaurant({
          ...restaurant,
          applicatorIdCheckin: applicatorLogged.id,
        });

        return true;
      } catch (err) {
        addToast({
          title: 'Erro ao tentar fazer checkin',
          description: 'Cheque o console',
        });

        // eslint-disable-next-line no-console
        console.log(err);
        return false;
      }
    },
    [restaurants, applicatorLogged, updateRestaurant, addToast],
  );

  const checkout = useCallback(
    async (restaurantId: string) => {
      try {
        const restaurant = restaurants.find(r => r.id === restaurantId);

        if (!restaurant) {
          return false;
        }

        await api.put(`restaurant/${restaurantId}`, {
          applicatorIdCheckin: '',
        });

        updateRestaurant({
          ...restaurant,
          applicatorIdCheckin: '',
        });

        return true;
      } catch (err) {
        addToast({
          title: 'Erro ao tentar fazer checkout',
          description: 'Cheque o console',
        });
        // eslint-disable-next-line no-console
        console.log(err);
        return false;
      }
    },
    [addToast, restaurants, updateRestaurant],
  );

  return (
    <AplicationContext.Provider
      value={{
        restaurants,
        owners,
        applicators,
        animationPorkCoinValue,
        setAnimationPorkCoinValue,
        periodDates,
        startDate,
        endDate,
        periodType,
        setPeriodType,
        applicatorLogged: {
          setApplicatorLogged,
          applicator: applicatorLogged,
          todayApplications,
          setTodayApplications,
          checkin,
          checkout,
          coupons,
          setCoupons,
        },
      }}
    >
      {children}
    </AplicationContext.Provider>
  );
};

function useAplication(): AplicationContextData {
  const context = useContext(AplicationContext);

  if (!context) {
    throw new Error('useApp must be used within a AplicationProvider');
  }

  return context;
}

export { AplicationProvider, useAplication };
