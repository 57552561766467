import React, { HTMLAttributes } from 'react';

import { Container } from './styles';

interface TooltipProps extends HTMLAttributes<HTMLDivElement> {
  title: string;
  className?: string;
  upside: boolean;
}

const Tooltip: React.FC<TooltipProps> = ({
  title,
  className,
  upside,
  children,
  ...rest
}) => {
  return (
    <Container {...rest} upside={upside} className={className}>
      <span className="title">{title}</span>
      {children}
    </Container>
  );
};

export default Tooltip;
