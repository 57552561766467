import styled, { css } from 'styled-components';
import { InformationBlockStatus } from '.';

type Props = {
  status: InformationBlockStatus;
};

type ProgressProps = {
  progress: number;
};

export const Container = styled.div<Props>`
  background-color: var(--container);
  border-radius: 10px;

  position: relative;

  width: 100%;

  display: flex;
  align-items: center;

  gap: 12px;

  padding: 12px;

  ${props =>
    props.status === 'warning' &&
    css`
      background-color: #c23b3b20;
    `}

  ${props =>
    props.status === 'success' &&
    css`
      background-color: #74c08520;
    `}
`;

export const Progress = styled.div<ProgressProps>`
  position: absolute;

  border-radius: 10px;

  left: 0;

  height: 100%;
  width: 0%;

  background-color: #3bc25920;

  transition: width 0.2s ease;

  ${props =>
    props.progress &&
    css`
      width: ${props.progress}%;
    `}
`;

export const Img = styled.img`
  width: 45px;
  height: 45px;

  z-index: 1;
  object-fit: cover;
  border-radius: 8px;
`;

export const IconContainer = styled.div<Props>`
  width: 45px;
  height: 45px;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 8px;

  background-color: #222222;

  ${props =>
    props.status === 'warning' &&
    css`
      color: #c23b3b;
      background-color: #f0959530;
    `}

  ${props =>
    props.status === 'success' &&
    css`
      color: #74c085;
      background-color: #95f0a930;
    `}
`;

export const ValueContainer = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 1;
`;

export const UpTitle = styled.span<Props>`
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 700;
  color: #5b5b5b;
  z-index: 1;

  ${props =>
    props.status === 'warning' &&
    css`
      color: #c23b3b;
    `}

  ${props =>
    props.status === 'success' &&
    css`
      color: #74c085;
    `}
`;

export const Value = styled.span`
  font-size: 18px;
  z-index: 1;
`;

export const GroupContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  gap: 10px;
  border-radius: 10px;

  width: 100%;
  padding: 10px;

  background-color: #ffffff20;
`;
