import React, { HTMLAttributes, useCallback, useMemo, useState } from 'react';
import { format, subHours } from 'date-fns';
import { IRestaurant } from '../../../../DTOS/IRestaurant';

import * as S from './styles';
import { applicators } from '../../../../db/applicators';
import { useToast } from '../../../../hooks/toast';
import { useAplication } from '../../../../hooks/app';
import api from '../../../../services/api';
import { IApplied } from '../../../../DTOS/IApplied';

interface RestaurantProps extends HTMLAttributes<HTMLDivElement> {
  restaurant: IRestaurant;
}

const Restaurant: React.FC<RestaurantProps> = ({ restaurant, ...rest }) => {
  const { addToast } = useToast();
  const { applicatorLogged } = useAplication();
  const [appliedsToday, setAppliedsToday] = useState<IApplied[]>([]);

  useMemo(async () => {
    const date = format(subHours(new Date(), 3), 'yyyy-MM-dd');

    try {
      const applications = await api.get(
        `applied/restaurantdate/${restaurant.id}/${date}`,
      );

      setAppliedsToday(applications.data);
    } catch (err) {
      addToast({
        title: 'Nao foi possivel buscar aplicacoes deste restaurante',
      });
    }
  }, [addToast, restaurant.id]);

  const progress = useMemo(() => {
    return (appliedsToday.length * 100) / restaurant.ordersByDay;
  }, [appliedsToday.length, restaurant.ordersByDay]);

  const handleCheckin = useCallback(async () => {
    if (!applicatorLogged.checkin(restaurant.id))
      addToast({
        title: 'Erro ao tentar fazer checkin (console)',
      });
  }, [applicatorLogged, restaurant.id, addToast]);

  const handleCheckout = useCallback(async () => {
    if (!applicatorLogged) {
      addToast({
        title: 'Nenhum aplicador logado para fazer checkout',
      });

      return;
    }

    if (restaurant.applicatorIdCheckin !== applicatorLogged.applicator?.id) {
      addToast({
        title: 'Somente o aplicador responsavel pode fazer checkout',
      });

      return;
    }

    if (!applicatorLogged.checkout(restaurant.id))
      addToast({
        title: 'Erro ao tentar fazer checkout (console)',
      });
  }, [
    applicatorLogged,
    restaurant.applicatorIdCheckin,
    restaurant.id,
    addToast,
  ]);

  return (
    <S.Container {...rest}>
      <S.ContentContainer style={{ marginLeft: progress < 20 ? 20 : 10 }}>
        <S.ProgressContainer>
          <S.ProgressBar>
            <S.Progress progress={progress}>
              {progress > 0 && (
                <S.PercentText progress={progress}>
                  {`${progress.toFixed()}%`}
                </S.PercentText>
              )}
            </S.Progress>
          </S.ProgressBar>
        </S.ProgressContainer>
      </S.ContentContainer>
      <S.InfoContainer>
        <S.ImageRestaurantContainer>
          <S.Image src={restaurant.img} alt="logorestaurant" />

          {restaurant.applicatorIdCheckin &&
            restaurant.applicatorIdCheckin !==
              applicatorLogged.applicator?.id && (
              <S.ApplicatorImg
                src={
                  applicators.find(a => a.id === restaurant.applicatorIdCheckin)
                    ?.img
                }
              />
            )}

          {restaurant.applicatorIdCheckin &&
            restaurant.applicatorIdCheckin ===
              applicatorLogged.applicator?.id && (
              <S.Button buttonType="checkout" onClick={handleCheckout}>
                -
              </S.Button>
            )}

          {!restaurant.applicatorIdCheckin && (
            <S.Button buttonType="checkin" onClick={handleCheckin}>
              +
            </S.Button>
          )}
        </S.ImageRestaurantContainer>

        <S.Title>{restaurant.name}</S.Title>
        <S.OwnerName>
          {`${restaurant.ordersByDay} cupons | ${
            restaurant.promotion ? 'Promoção' : 'Onde quiser'
          }`}
        </S.OwnerName>
        {restaurant.scheduling ? (
          <S.Schedule>✦ Agendamento</S.Schedule>
        ) : (
          <S.OwnerName>Sem agendamento</S.OwnerName>
        )}
      </S.InfoContainer>

      <S.Status status={restaurant.status}>
        <span>{`${restaurant.status}`}</span>
      </S.Status>
      {restaurant.notes && (
        <S.Status status={restaurant.status}>
          <span>{`${restaurant.notes}`}</span>
        </S.Status>
      )}
    </S.Container>
  );
};

export default Restaurant;
