import styled, { css } from 'styled-components';

type ProgressProps = {
  percent: number;
  color?: string;
};

type PowerProps = {
  online?: boolean;
  styleButton: 'success' | 'alert' | 'danger';
};

type IActiveProps = {
  active: boolean;
};

export const Container = styled.div``;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  height: max-content;

  gap: 18px;

  padding: 14px 20px;
  padding-bottom: 22px;

  background-color: #141414;
  border-radius: 20px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  h1 {
    font-size: 18px;
  }
`;

export const OptionsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  gap: 10px;
`;

export const ButtonOption = styled.button<PowerProps>`
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  border: none;
  outline: none;
  background-color: transparent;

  color: #ffffff30;
  filter: drop-shadow(0px 0px 5px #ffffff30);

  transition: 0.2s ease;

  ${props =>
    props.online &&
    props.styleButton === 'success' &&
    css`
      color: #30d996;
      filter: drop-shadow(0px 0px 5px #30d996);
    `}

  ${props =>
    props.styleButton === 'alert' &&
    css`
      &:hover {
        color: #d9cb30;
        filter: drop-shadow(0px 0px 2px #d9cb30);
      }
    `}

    ${props =>
    props.styleButton === 'danger' &&
    css`
      &:hover {
        color: #d93030;
        filter: drop-shadow(0px 0px 2px #d93030);
      }
    `}
`;

export const Bar = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;

  margin-top: 10px;

  background-color: #ffffff10;

  border-radius: 10px;
  position: relative;

  span {
    position: absolute;
    color: #fff;
    right: 20px;
    font-size: 18px;
  }
`;

export const Progress = styled.div<ProgressProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;

  height: 70px;

  width: 0%;

  background: linear-gradient(90deg, var(--primary), #96e4b5);

  transition: 2s ease;

  ${props =>
    props.percent > 0 &&
    css`
      width: ${props.percent}%;
    `}

  ${props =>
    props.color &&
    css`
      background-color: ${props.color};
    `}
`;

export const VerifiedToTotalLabel = styled.div`
  color: #ffffff80;
  text-align: end;
  font-size: 12px;
  margin-top: -10px;
  margin-right: 10px;
`;

export const ColumnsGraph = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  padding-bottom: 44px;

  gap: 8px;
`;

export const Column = styled.div<ProgressProps>`
  width: 100%;
  position: relative;
  background: linear-gradient(90deg, var(--primary), #96e4b5);

  border-radius: 4px;
  transition: 2s ease;

  span {
    width: 100%;
    text-align: center;
    font-size: 12px;
  }

  .percent {
    text-align: center;
    position: absolute;

    color: #ffffff80;

    top: -20px;
  }

  .coupon {
    position: absolute;
    bottom: -24px;
    color: var(--primary);

    background-color: #000000;

    border-radius: 4px;
  }

  .quantity {
    position: absolute;
    bottom: -44px;

    color: #ffffff80;

    border-radius: 4px;
  }

  min-height: 1px;

  ${props =>
    props.percent &&
    css`
      height: ${props.percent}%;
    `}
`;

export const LineOfCouponsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const CouponPoint = styled.div<IActiveProps>`
  display: flex;
  justify-content: center;

  cursor: pointer;

  flex-direction: column;

  width: 100%;

  margin-top: 10px;

  &:hover {
    .point {
      transform: scale(1.4);
    }
  }

  .line {
    width: 100%;
    height: 1px;
    background-color: var(--primary);
  }
  .point {
    transition: 0.2s ease;

    width: 10px;
    height: 10px;
    border-radius: 50%;

    margin-top: -5.5px;

    background-color: var(--primary);
  }

  .value {
    font-size: 12px;
    color: var(--primary);
  }

  ${props =>
    !props.active &&
    css`
      .line,
      .point {
        background-color: #5b5b5b;
      }

      .value {
        color: #5b5b5b;
      }
    `}
`;

export const InformationsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
`;

export const InfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 6px 10px;

  text-align: center;

  border-radius: 8px;

  background-color: #00000060;

  .title {
    font-size: 14px;
    color: #ffffff80;
  }

  .value {
    font-size: 18px;
  }
`;
