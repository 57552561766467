import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 0 10px;

  padding-bottom: 40px;
`;

export const GroupContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  gap: 10px;
  border-radius: 10px;

  width: 100%;
  padding: 10px;

  background-color: #ffffff20;
`;

export const Divider = styled.div`
  display: flex;
  align-items: center;
  flex: 1;

  width: 100%;

  gap: 10px;
`;

export const IntervalContainer = styled.div`
  display: flex;
  gap: 8px;
`;

export const SearchBetweenDatesButton = styled.button`
  background-color: transparent;
  color: var(--primary);
  padding: 0 12px;

  border-radius: 10px;

  border: 1px solid var(--primary);

  transition: 0.2s ease;

  &:hover {
    background-color: #0a7360;
    color: white;
  }
`;

export const InputDate = styled.input`
  background-color: transparent;
  color: #fff;

  border: 1px solid #5b5b5b80;
  font-size: 14px;

  padding: 8px 12px;

  border-radius: 10px;

  width: 100%;
`;
