import React, { useCallback, useEffect, useMemo, useState } from 'react';

import {
  FiAtSign,
  FiCheckSquare,
  FiChevronDown,
  FiDatabase,
  FiDollarSign,
  FiHome,
  FiPhoneCall,
  FiPlusSquare,
  FiUsers,
} from 'react-icons/fi';

import { useHistory, useLocation, useParams } from 'react-router-dom';
import { IconType } from 'react-icons/lib';
import Lottie from 'react-lottie';
import { RiStoreLine, RiUserStarLine } from 'react-icons/ri';

import { addDays, format, sub } from 'date-fns';
import * as S from './styles';
import { useAplication } from '../../hooks/app';
import animationData2 from '../../assets/lotties/coin.json';
import animationData from '../../assets/lotties/pork2.json';
import cashLottie from '../../assets/lotties/cash.json';
import Tooltip from '../Tooltip';
import Section from '../Section';
import api from '../../services/api';
import { IApplied } from '../../DTOS/IApplied';
import { IRestaurant } from '../../DTOS/IRestaurant';

type OptionProps = {
  name: string;
  Icon: IconType;
  route: string;
};

const optionsIgor = [
  {
    name: 'dashboard',
    Icon: FiHome,
    route: '/dashboard',
  },
  {
    name: 'financial',
    Icon: FiDollarSign,
    route: '/financial',
  },
  {
    name: 'applicators',
    Icon: FiUsers,
    route: '/financial/applicators',
  },
  {
    name: 'evaluators',
    Icon: RiUserStarLine,
    route: '/financial/evaluators',
  },
  {
    name: 'coupons',
    Icon: FiAtSign,
    route: '/coupons',
  },
  {
    name: 'emails',
    Icon: FiDatabase,
    route: '/emails',
  },
  {
    name: 'stores',
    Icon: RiStoreLine,
    route: '/financial/restaurants',
  },
  {
    name: 'checkin',
    Icon: FiPlusSquare,
    route: '/checkin',
  },
];

const optionsEsther = [
  {
    name: 'dashboard',
    Icon: FiHome,
    route: '/dashboard',
  },
  {
    name: 'stores',
    Icon: RiStoreLine,
    route: '/financial/restaurants',
  },
  {
    name: 'financial',
    Icon: FiDollarSign,
    route: '/financial',
  },
  {
    name: 'coupons',
    Icon: FiAtSign,
    route: '/coupons',
  },
  {
    name: 'emails',
    Icon: FiDatabase,
    route: '/emails',
  },
  {
    name: 'checkin',
    Icon: FiPlusSquare,
    route: '/checkin',
  },
];

const optionsApplicator = [
  {
    name: 'home',
    Icon: FiHome,
    route: '/',
  },
  {
    name: 'applieds',
    Icon: FiCheckSquare,
    route: '/applieds',
  },
  {
    name: 'checkin',
    Icon: FiPlusSquare,
    route: '/checkin',
  },
  {
    name: 'marketing',
    Icon: FiPhoneCall,
    route: '/marketing',
  },
  {
    name: 'emails',
    Icon: FiDatabase,
    route: '/emails',
  },
];

type Params = {
  restaurantId: string;
};

const Header: React.FC = ({ children, ...rest }) => {
  const { push } = useHistory();
  const { restaurantId } = useParams<Params>();
  const { pathname } = useLocation();
  const [activeOption, setActiveOption] = useState('home');
  const { applicatorLogged, animationPorkCoinValue, restaurants } =
    useAplication();
  const [restaurantSelected, setRestaurantSelected] = useState<
    IRestaurant | undefined
  >();

  useEffect(() => {
    if (restaurantId) {
      setRestaurantSelected(restaurants.find(r => r.id === restaurantId));
    }
  }, [restaurantId, restaurants]);

  const [dropDownIsOpen, setDropDownIsOpen] = useState(false);

  const [isStopped, setIsStopped] = useState(false);

  const [options, setOptions] = useState<OptionProps[]>([]);

  const [totalApplieds, setTotalAppliedds] = useState(0);

  useMemo(() => {
    const imperioIgor = localStorage.getItem(
      `a76e8fff-d247-4e7b-8143-2f61a9bf4e7d`,
    );
    const imperioApplicator = localStorage.getItem(
      `71c66b70-3f7d-4236-ad00-76510ba0edb7`,
    );
    const imperioEsther = localStorage.getItem(
      `7557eb6f-8366-4203-8917-075fbf5f001f`,
    );

    if (imperioIgor) {
      setOptions(optionsIgor);
      return;
    }

    if (imperioEsther) {
      setOptions(optionsEsther);
      return;
    }

    if (imperioApplicator) {
      setOptions(optionsApplicator);
      return;
    }

    push(`/signin/${imperioApplicator || '1'}`);
  }, [push]);

  const handleLogout = useCallback(() => {
    const imperioApplicator = localStorage.getItem(
      `71c66b70-3f7d-4236-ad00-76510ba0edb7`,
    );

    localStorage.removeItem(`71c66b70-3f7d-4236-ad00-76510ba0edb7`);
    localStorage.removeItem(`a76e8fff-d247-4e7b-8143-2f61a9bf4e7d`);
    localStorage.removeItem(`7557eb6f-8366-4203-8917-075fbf5f001f`);
    applicatorLogged.setApplicatorLogged(undefined);

    push(`/signin/${imperioApplicator || '1'}`);
  }, [applicatorLogged, push]);

  useMemo(() => {
    const hasOptionActive = options.find(o => o.route === pathname);
    setActiveOption(hasOptionActive ? hasOptionActive.name : '');
  }, [options, pathname]);

  const handleNavigate = useCallback(
    ({ name, route }: OptionProps) => {
      setActiveOption(name);
      setRestaurantSelected(undefined);
      push(route);
    },
    [push],
  );

  useEffect(() => {
    setIsStopped(false);
    setTimeout(() => setIsStopped(true), 2000);
  }, [animationPorkCoinValue]);

  const restaurantsThisApplicator = useMemo(() => {
    return restaurants
      .filter(r => r.applicatorIdCheckin === applicatorLogged.applicator?.id)
      .sort((a, b) => {
        if (a.ownerId < b.ownerId) {
          return -1;
        }
        if (a.ownerId > b.ownerId) {
          return 1;
        }
        return 0;
      });
  }, [restaurants, applicatorLogged]);

  const applicatorMoney = useMemo(() => {
    if (applicatorLogged.todayApplications.length <= 0) return '0';
    const value = applicatorLogged.todayApplications.reduce(
      (acc, item) => acc + item.calcs.enterprise.aplicador,
      0,
    );

    return value.toFixed(2);
  }, [applicatorLogged.todayApplications]);

  const handleNavigateToRestaurant = useCallback(
    (restaurant: IRestaurant) => {
      setDropDownIsOpen(false);
      push(`/restaurant/${restaurant.id}`);
      setRestaurantSelected(restaurant);
      setActiveOption('');
    },
    [push],
  );

  const applicationsTodayActiveRestaurants = useMemo(() => {
    const applications = applicatorLogged.todayApplications.filter(
      a => !!restaurantsThisApplicator.find(r => r.id === a.restaurantId),
    );

    return applications.length;
  }, [applicatorLogged.todayApplications, restaurantsThisApplicator]);

  const totalApplications = useMemo(() => {
    return restaurantsThisApplicator.reduce(
      (acc, item) => acc + item.ordersByDay,
      0,
    );
  }, [restaurantsThisApplicator]);

  const percentageApplied = useMemo(() => {
    return (applicationsTodayActiveRestaurants * 100) / totalApplications;
  }, [applicationsTodayActiveRestaurants, totalApplications]);

  const restaurantsAndAppliedsToday = useMemo(() => {
    return restaurantsThisApplicator.map(r => ({
      ...r,
      totalApplied: applicatorLogged.todayApplications.filter(
        a => a.restaurantId === r.id,
      ),
    }));
  }, [applicatorLogged.todayApplications, restaurantsThisApplicator]);

  useMemo(async () => {
    if (!applicatorLogged.applicator?.id) return;

    const startDate = format(
      new Date(
        sub(new Date().setHours(3), {
          days: new Date().getDay() - 1,
        }),
      ),
      'yyyy-MM-dd',
    );

    const endDate = format(
      addDays(
        new Date(
          sub(new Date().setHours(3), {
            days: new Date().getDay() - 7,
          }),
        ),
        1,
      ),
      'yyyy-MM-dd',
    );

    try {
      const applieds = await api.get<IApplied[]>(
        `applied/${applicatorLogged.applicator.id}/between/${startDate}/${endDate}`,
      );

      setTotalAppliedds(
        applieds.data.reduce(
          (acc, item) => acc + item.calcs.enterprise.aplicador,
          0,
        ),
      );
    } catch (err) {
      alert(err);
    }
  }, [applicatorLogged.applicator]);

  return (
    <Section>
      <S.Container {...rest}>
        <S.HeaderContent>
          <S.RoutesContainer>
            {options.map(option => (
              <S.ButtonIcon
                key={option.name}
                onClick={() => handleNavigate(option)}
                active={activeOption === option.name}
              >
                <option.Icon size={20} />
              </S.ButtonIcon>
            ))}
          </S.RoutesContainer>

          {applicatorLogged.applicator && (
            <S.ApplicatorContent>
              {restaurantSelected && (
                <S.StoreImg
                  style={{ width: 40, height: 40, marginBottom: 10 }}
                  src={restaurantSelected.img}
                />
              )}
              {totalApplications !== 0 && (
                <Tooltip upside title={`${percentageApplied.toFixed(0)}%`}>
                  <S.TotalApplication>
                    <S.PercentageBar progress={percentageApplied} />
                    <S.TotalApplicationValue>
                      {applicationsTodayActiveRestaurants}
                    </S.TotalApplicationValue>
                    <S.TotalApplicationTitle className="title">
                      de
                    </S.TotalApplicationTitle>
                    <S.TotalApplicationValue>
                      {totalApplications}
                    </S.TotalApplicationValue>
                  </S.TotalApplication>
                </Tooltip>
              )}

              <Tooltip title={`R$ ${applicatorMoney}`} upside>
                <S.Animation>
                  <Lottie
                    isStopped={isStopped}
                    options={{
                      autoplay: true,
                      loop: true,
                      animationData: animationData2,
                      rendererSettings: {
                        preserveAspectRatio: 'xMidYMid slice',
                      },
                    }}
                    style={{ position: 'absolute', bottom: 10 }}
                    width={70}
                    height={70}
                  />
                  <Lottie
                    options={{
                      autoplay: false,
                      loop: false,
                      animationData,
                      rendererSettings: {
                        preserveAspectRatio: 'xMidYMid slice',
                      },
                    }}
                    style={{ position: 'absolute', top: 0 }}
                    width={80}
                    height={70}
                  />
                </S.Animation>
              </Tooltip>

              <Tooltip title={`R$ ${totalApplieds.toFixed(2)}`} upside>
                <S.Animation>
                  <Lottie
                    options={{
                      autoplay: false,
                      loop: false,
                      animationData: cashLottie,
                      rendererSettings: {
                        preserveAspectRatio: 'xMidYMid slice',
                      },
                    }}
                    style={{ position: 'absolute', top: 0 }}
                    width={60}
                    height={60}
                  />
                </S.Animation>
              </Tooltip>

              <S.ApplicatorLogged>
                <S.ApplicatorLoggedImg
                  onClick={() =>
                    push(`applicator/${applicatorLogged.applicator?.id}`)
                  }
                  src={applicatorLogged.applicator.img}
                />
                <S.IconLogout onClick={handleLogout} />
              </S.ApplicatorLogged>
            </S.ApplicatorContent>
          )}
          {!applicatorLogged.applicator && (
            <S.IconLogout onClick={handleLogout} />
          )}
        </S.HeaderContent>
      </S.Container>

      <S.SectionContainer>
        {applicatorLogged.applicator && (
          <S.StoresListContainer>
            <S.SelectStoreDropDown
              onClick={() => setDropDownIsOpen(!dropDownIsOpen)}
            >
              <div>
                {restaurantSelected && (
                  <S.StoreImg
                    src={restaurantSelected.img}
                    alt="restaurant logo"
                  />
                )}

                <span>
                  {restaurantSelected
                    ? restaurantSelected.name
                    : 'Selecionar loja'}
                </span>
              </div>

              <FiChevronDown />
            </S.SelectStoreDropDown>
            {dropDownIsOpen &&
              restaurantsAndAppliedsToday.map(r => (
                <S.Store
                  selected={
                    restaurantSelected && restaurantSelected.id === r.id
                  }
                  onClick={() => handleNavigateToRestaurant(r)}
                  key={r.id}
                >
                  <div>
                    <S.StoreImg key={r.id} src={r.img} alt="restaurant logo" />
                    <span>{r.name}</span>
                  </div>

                  <div>
                    <S.PercentText
                      style={{ textAlign: 'start' }}
                      progress={(r.totalApplied.length * 100) / r.ordersByDay}
                    >
                      {`${r.totalApplied.length}/${r.ordersByDay}`}
                    </S.PercentText>

                    <div className="percent">
                      <S.Percent
                        progress={(r.totalApplied.length * 100) / r.ordersByDay}
                      />
                    </div>

                    <S.PercentText
                      progress={(r.totalApplied.length * 100) / r.ordersByDay}
                    >
                      {`${(
                        (r.totalApplied.length * 100) /
                        r.ordersByDay
                      ).toFixed()}%`}
                    </S.PercentText>
                  </div>
                </S.Store>
              ))}
          </S.StoresListContainer>
        )}

        {children}
      </S.SectionContainer>
    </Section>
  );
};

export default Header;
