/* eslint-disable no-underscore-dangle */
import React, { HTMLAttributes } from 'react';
import * as S from './styles';

import CouponsRobot from './CouponsRobot';
import RatingsRobot from './RatingsRobot';
import EmailsStatus from './EmailsStatus';
import CookiesRobot from './CookiesRobot';
import Applicators from './Applicators';
import Restaurants from './Restaurants';

type DashboardProps = HTMLAttributes<HTMLDivElement>;

const Dashboard: React.FC<DashboardProps> = ({ ...rest }) => {
  return (
    <S.Container {...rest}>
      <div>
        <Applicators />
        <Restaurants />
      </div>
      <div>
        <CouponsRobot />
        <RatingsRobot />
      </div>
      <div>
        <EmailsStatus />
        <CookiesRobot />
      </div>
    </S.Container>
  );
};

export default Dashboard;
