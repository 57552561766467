import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  > div {
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex: 1;
  }

  @media (min-width: 1000px) {
    flex-direction: row;
  }
`;
